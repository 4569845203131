import { Col, Form, Input, Modal, notification, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import ModelViewer from 'components/model-viewer';
import SelectAttribute from 'components/select-attributes';
import { FILE_TYPE, LIMIT, NFT_ATTRIBUTES, NFT_STATUS, UPLOAD_TYPE } from 'constants/common';
import { useCallback, useEffect, useState } from 'react';
import nftService from 'service/nft-service';
import { CHECK_FILE, REQUIRED_MINT, REQUIRED_TITLE_MINT } from 'utils/rule-form';
import socket from 'utils/socket';
import styles from '../style.module.scss';
import CreateLoading from './create-loading';
import Loading from 'components/loading';
import SelectAllWeapon from './select-all-weapon';

interface IProps {
  id?: number | string;
  handleMintPopUp: (id: number | string, status: boolean, isMint?: boolean) => void;
  handelCloseUpdatePoup: () => void;
  edit?: boolean;
  view?: boolean;
  updateNft?: boolean;
  clearFilter: () => void;
  title: string;
}

const Update1stModal = ({
  id,
  handleMintPopUp,
  edit,
  handelCloseUpdatePoup,
  view,
  clearFilter,
  updateNft,
  title,
}: IProps) => {
  const [form] = Form.useForm();
  const characterWatch = Form.useWatch('characterId', form);
  const typeWatch = Form.useWatch('type', form);

  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(true);
  const [loadingPresign, setLoadingPresign] = useState<boolean>(true);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(true);
  const [loadingSaveDb, setLoadingSaveDb] = useState<boolean>(true);

  const [nftCreated, setNftCreated] = useState<any>();

  const [uploadPreviewStatus, setUploadPreviewStatus] = useState<any>(false);
  const [uploadModalStatus, setUploadModalStatus] = useState<any>(false);
  const [upload2DStatus, setUpload2DStatus] = useState<any>(false);

  const [dataPreview, setDataPreview] = useState<any>(null);
  const [preview2DId, setPreview2DId] = useState<any>();
  const [previewId, setPreviewId] = useState<any>();
  const [modalId, setModalId] = useState<any>();

  const [showPosition, setShowPosition] = useState<boolean>(false);
  const [showWeapon, setShowWeapon] = useState<boolean>(false);

  const [isCreateStatus, setIsCreateStatus] = useState<boolean>(false);
  const [isMintAction, setIsMintAction] = useState<boolean>(false);

  const resetState = () => {
    setLoadingCreate(true);
    setLoadingPresign(true);
    setLoadingUpload(true);
    setLoadingSaveDb(true);
    // setNftCreated(null);
    setLoadingModal(false);
  };

  const resetLoading = () => {
    setLoadingCreate(true);
    setLoadingPresign(true);
    setLoadingUpload(true);
    setLoadingSaveDb(true);
    setUploadPreviewStatus(false);
    setUpload2DStatus(false);
    setUploadModalStatus(false);
  };

  const errorNoti = (content: string) => {
    notification.error({
      message: 'Error',
      description: content,
      duration: 4,
    });
    resetState();
  };

  const handleUploadS3 = async (
    preview2D: any,
    preview: any,
    modal: any,
    filePreview2D: any,
    filePreview: any,
    fileModal: any
  ) => {
    setPreview2DId(preview2D?.data?.imageId);
    setPreviewId(preview?.data?.imageId);
    setModalId(modal?.data?.imageId);
    Promise.allSettled([
      filePreview2D &&
        !(typeof filePreview2D === 'string') &&
        nftService.uploadFileS3(preview2D?.data?.url, filePreview2D, preview2D?.data?.headers),
      filePreview &&
        !(typeof filePreview === 'string') &&
        nftService.uploadFileS3(preview?.data?.url, filePreview, preview?.data?.headers),
      fileModal &&
        !(typeof fileModal === 'string') &&
        nftService.uploadFileS3(modal?.data?.url, fileModal, modal?.data?.headers),
    ]).then(function (valuesS3: any[]) {
      const itemReject = valuesS3?.findIndex((item: any) => item.status === 'rejected');
      if (itemReject !== -1) {
        errorNoti('Upload S3 error, please try again');
        console.error(`Upload S3 error update 1st nft`);
      } else {
        const valuePreview2D = valuesS3[0].value;
        const valuePreview = valuesS3[1].value;
        const valueModal = valuesS3[2].value;
        !valuePreview && setUploadPreviewStatus(true);
        !valueModal && setUploadModalStatus(true);
        !valuePreview2D && setUpload2DStatus(true);
        setLoadingUpload(false);
      }
    });
  };

  const handlePresign = async (value: any, result: any) => {
    const filePreview2D = value.preview2dImage?.file;
    const filePreview = value.previewImage?.file;
    const fileModal = value.modalData?.file;
    Promise.allSettled([
      filePreview2D &&
        !(typeof filePreview2D === 'string') &&
        nftService.createPresign({
          type: 'preview2D',
          name: value.preview2dImage?.fileKey || '',
          nftId: result.nftId,
        }),
      filePreview &&
        !(typeof filePreview === 'string') &&
        nftService.createPresign({
          type: 'preview',
          name: value.previewImage?.fileKey || '',
          nftId: result.nftId,
        }),
      fileModal &&
        !(typeof fileModal === 'string') &&
        nftService.createPresign({
          type: 'model',
          name: value.modalData?.fileKey || '',
          nftId: result.nftId,
        }),
    ]).then(function (values: any[]) {
      const itemReject = values?.findIndex((item: any) => item.status === 'rejected');
      if (itemReject !== -1) {
        errorNoti('Presign error, please try again');
        console.error(`Presign error update 1st nft`);
      } else {
        setLoadingPresign(false);
        const preview2D = values?.[0]?.value;
        const preview = values?.[1]?.value;
        const modal = values?.[2]?.value;
        handleUploadS3(preview2D, preview, modal, filePreview2D, filePreview, fileModal);
      }
    });
  };

  const handleCreate1stNft = async (value: any, status: string, update?: boolean) => {
    setIsCreateStatus(status === '1' ? true : false);
    setLoadingModal(true);
    const { characterId, type, title, description, positionId, listWeapons } = value;
    const weapons = listWeapons?.filter((item: any) => !!item?.weapon?.id);
    const weaponsString = weapons
      ?.map((item: any) => {
        return item?.weapon?.id;
      })
      .toString();
    const params = {
      ...(characterId && { characterId }),
      ...(type && { type }),
      ...(title && { title }),
      ...(description && { description }),
      ...(status && { status }),
      ...(positionId && { positionId }),
      ...(weapons?.length > 0 && { weapons: weaponsString }),
    };

    const [result, error]: any = update
      ? await nftService.updateNft(nftCreated?.id, params)
      : await nftService.createNft(params);
    if (result) {
      const nftDetail = update ? { ...nftCreated, ...{ params } } : result;
      setNftCreated(nftDetail);
      setLoadingCreate(false);
      handlePresign(value, nftDetail);
    } else {
      errorNoti(error?.message);
      console.error(`Handle create 1st nft`, JSON.stringify(error));
    }
  };

  const getDetail = useCallback(
    async (id: string | number) => {
      setLoadingDetail(true);
      const result: any = await nftService.getDetail1stNft(id);
      if (result) {
        setNftCreated(result);
        const dataPreviewResult = result?.images.find(
          (item: any) => item.type === UPLOAD_TYPE.PREVIEW
        );
        setDataPreview({
          ...result,
          name: dataPreviewResult?.name,
          file: dataPreviewResult?.objectKey,
        });
        if (result?.type === 'preset') setShowWeapon(true);
        let previewImage: any = null;
        let modalImage: any = null;
        let preview2dImage: any = null;
        result?.images?.forEach((item: any, index: number) => {
          if (item?.type === FILE_TYPE.PREVIEW) previewImage = item;
          if (item?.type === FILE_TYPE.MODEL) modalImage = item;
          if (item?.type === FILE_TYPE.PREVIEW2D) preview2dImage = item;
        });
        result &&
          form.setFieldsValue({
            ...result,
            modalData: { file: modalImage?.objectKey, fileKey: modalImage?.name },
            previewImage: { file: previewImage?.objectKey, fileKey: previewImage?.name },
            preview2dImage: { file: preview2dImage?.objectKey, fileKey: preview2dImage?.name },
          });
      }
      setLoadingDetail(false);
    },
    [form]
  );

  const handleUpdateNft = () => {
    setIsMintAction(false);
    resetLoading();
    if (nftCreated?.status === NFT_STATUS.CREATED) {
      form
        .validateFields()
        .then((value) => {
          handleCreate1stNft(value, '1', true);
        })
        .catch();
    } else {
      const data = form.getFieldsValue();
      handleCreate1stNft(data, '0', true);
    }
  };
  const handleResetWeapon = () => {
    form.setFieldValue('listWeapons', undefined);
  };

  const renderNotifiSuccess = () => {
    if (updateNft) {
      return '一次NFTが変更されました。';
    } else if (nftCreated?.status === NFT_STATUS.CREATED) {
      return '一次NFTの作成に成功しました。';
    } else {
      return '下書き保存が完了しました。';
    } 
  };

  const handleSaveDraft = () => {
    setIsMintAction(false);
    const listFields: string[] = [
      'characterId',
      'preview2dImage',
      'previewImage',
      'modalData',
      'type',

      'title',
      'description',
      'positionId',
      'listWeapons',
    ];
    form.setFields(
      listFields.map((item: string) => {
        return {
          name: item,
          errors: [],
        };
      })
    );
    form.validateFields(showWeapon ? ['title', 'listWeapons'] : ['title']).then(() => {
      const value = form.getFieldsValue();
      handleCreate1stNft(value, '0');
    });
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [form, id, getDetail]);

  useEffect((): any => {
    socket.on('UPLOAD_MODEL_3D_SUCCESS', (message: any) => {
      if (message.nftId === nftCreated?.nftId && message.imageId === modalId) {
        setUploadModalStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_MODEL_3D_SUCCESS');
  }, [nftCreated, modalId]);

  useEffect((): any => {
    socket.on('UPLOAD_PREVIEW_SUCCESS', (message: any) => {
      if (message?.nftId === nftCreated?.nftId && message.imageId === previewId) {
        setUploadPreviewStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_PREVIEW_SUCCESS');
  }, [nftCreated, previewId]);

  useEffect((): any => {
    socket.on('UPLOAD_PREVIEW2D_SUCCESS', (message: any) => {
      if (message?.nftId === nftCreated?.nftId && message.imageId === preview2DId) {
        setUpload2DStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_PREVIEW_SUCCESS');
  }, [nftCreated, preview2DId]);

  useEffect(() => {
    if (uploadPreviewStatus && uploadModalStatus && upload2DStatus) {
      setLoadingSaveDb(false);
      setLoadingModal(false);
      clearFilter();
      handleMintPopUp(nftCreated?.id, isCreateStatus, isMintAction);
      notification.success({
        message: 'Success',
        description: renderNotifiSuccess(),
        duration: 4,
      });
    }
  }, [uploadPreviewStatus, uploadModalStatus, upload2DStatus, nftCreated, updateNft, isMintAction]);

  useEffect(() => {
    if (typeWatch === 'weapon') {
      setShowPosition(true);
    } else {
      form.setFieldValue('positionId', undefined);
      setShowPosition(false);
    }
    if (typeWatch === 'preset') {
      setShowWeapon(true);
    } else {
      setShowWeapon(false);
    }
  }, [typeWatch]);

  return (
    <Modal
      forceRender
      title={title}
      open={true}
      footer={false}
      closable={false}
      width={900}
      style={{ top: 20 }}
    >
      {loadingDetail ? (
        <Loading />
      ) : (
        <Form form={form}>
          <Row gutter={30} className="custom-form update-modal-form">
            <Col span={12}>
              <Form.Item name="characterId" label="キャラクター" rules={[REQUIRED_MINT]}>
                <SelectAttribute
                  name={NFT_ATTRIBUTES.CHARACTER}
                  limit={LIMIT}
                  disabled={view}
                  onChange={handleResetWeapon}
                />
              </Form.Item>
              <Form.Item
                className={styles.imageUpload}
                name="preview2dImage"
                label="プレビュー画像 (2D)"
                rules={[CHECK_FILE()]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.IMAGE}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={true}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item
                name="previewImage"
                label="プレビュー画像 (3D)"
                rules={[CHECK_FILE('プレビュー画像 (3D)')]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.PREVIEW}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={false}
                  disabled={view}
                  onChange={(data: any) => {
                    setDataPreview({
                      ...dataPreview,
                      name: data.fileKey,
                      file: URL.createObjectURL(data.file),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="modalData"
                label="3D モデルデータ"
                rules={[CHECK_FILE('3D モデルデータ')]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.MODEL}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={false}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item label="タイプ" name="type" rules={[REQUIRED_MINT]}>
                <SelectAttribute
                  name={NFT_ATTRIBUTES.TYPE}
                  limit={LIMIT}
                  disabled={view}
                  placeholder={'Select'}
                  onChange={handleResetWeapon}
                />
              </Form.Item>
              {showPosition && (
                <Form.Item label="位置" name="positionId" rules={[REQUIRED_MINT]}>
                  <SelectAttribute
                    name={NFT_ATTRIBUTES.POSITION}
                    limit={LIMIT}
                    disabled={view}
                    placeholder={'Select'}
                  />
                </Form.Item>
              )}
              {showWeapon && (
                <Form.Item
                  label="武器"
                  name="listWeapons"
                  validateFirst={true}
                  rules={[
                    { required: true, message: '武器には少なくとも 1 つのアイテムが必要です' },
                    {
                      message: '武器には少なくとも 1 つのアイテムが必要です',
                      validator: (_, weapon) => {
                        const newWeapon = weapon.filter((item: any) => !!item?.weapon?.id);
                        if (newWeapon?.length >= 1) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('武器には少なくとも 1 つのアイテムが必要です');
                        }
                      },
                    },
                  ]}
                >
                  <SelectAllWeapon
                    disabled={view}
                    additionalParams={{ character: characterWatch ? Number(characterWatch) : 0 }}
                  />
                </Form.Item>
              )}
              <Form.Item label="タイトル" name="title" rules={[REQUIRED_TITLE_MINT]}>
                <Input
                  maxLength={150}
                  placeholder="タイトルを入力"
                  onChange={(e) => setDataPreview({ ...dataPreview, title: e?.target?.value })}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item label="説明文" name="description">
                <TextArea
                  maxLength={750}
                  placeholder="説明を入力"
                  rows={3}
                  onChange={(e) =>
                    setDataPreview({ ...dataPreview, description: e?.target?.value })
                  }
                  disabled={view}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div className={styles.nftPreview}>
                <p className="text-700 mb-0">NFT プレビュー</p>
                <div className={styles.nftPreviewDetail}>
                  {dataPreview?.file ? (
                    <ModelViewer
                      src={dataPreview?.file}
                      width={360}
                      height={350}
                      background="#F3F4F4"
                    />
                  ) : (
                    <div className={styles.nftPreviewTextDefaut}>3d プレビュー画像表示</div>
                  )}
                  {dataPreview?.title && <p className={styles.title}>{dataPreview?.title}</p>}
                  {dataPreview?.description && (
                    <p className={styles.description}>{dataPreview?.description}</p>
                  )}
                  {[NFT_STATUS.MINTED, NFT_STATUS.SELLING].includes(nftCreated?.status) && (
                    <p className="mb-5 text-400">数量: {dataPreview?.quantity}</p>
                  )}
                  {[NFT_STATUS.SELLING].includes(nftCreated?.status) && (
                    <p className="mb-5 text-400">価格: {dataPreview?.price}</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className={styles.buttonSubmit}>
            {!view && !updateNft && (
              <CustomButton
                onClick={handleSaveDraft}
                text="下書保存"
                bgColor="third-green"
                color="#18C3AF"
                fontWeight="700"
              />
            )}
            {!view && ([NFT_STATUS.DRAFT].includes(nftCreated?.status) || !nftCreated) && (
              <CustomButton
                onClick={() =>
                  form
                    .validateFields()
                    .then((value) => {
                      const isUpdate = nftCreated?.status === NFT_STATUS.DRAFT;
                      setIsMintAction(true);
                      handleCreate1stNft(value, '1', isUpdate);
                    })
                    .catch()
                }
                text="保存＆ミントへ"
                bgColor="primary-green"
                color="white"
                fontWeight="700"
              />
            )}
            {!view && updateNft && (
              <CustomButton
                onClick={handleUpdateNft}
                text="変更"
                bgColor="primary-green"
                color="white"
                fontWeight="700"
              />
            )}
            <CustomButton
              onClick={handelCloseUpdatePoup}
              text="キャンセル"
              bgColor="primary-grey"
              color="black"
              fontWeight="700"
            />
          </div>

          <Modal
            title="一次NFT作成（3D）"
            open={loadingModal}
            closable={false}
            footer={false}
            width={300}
            style={{ marginTop: 200 }}
          >
            <CreateLoading
              loadingCreate={loadingCreate}
              loadingPresign={loadingPresign}
              loadingUpload={loadingUpload}
              loadingSaveDb={loadingSaveDb}
            />
          </Modal>
        </Form>
      )}
    </Modal>
  );
};
export default Update1stModal;
