import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const Loading = () => {
  return (
    <div className="loading-grey">
      <Spin />
    </div>
  );
};

export default Loading;
