import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { disconnect } from '@wagmi/core';
import { useWeb3React } from '@web3-react/core';
import { removeAuth } from 'redux/auth/reducers';
import UserService from 'service/user-service';
import { StorageKey, StorageUtils } from 'utils/local-storage';
import { useAppDisPatch } from '../../../utils/common';
import styles from './styles.module.scss';
import * as Sentry from '@sentry/react';

const Profile = () => {
  const dispatch = useAppDisPatch();
  const { deactivate } = useWeb3React();
  const session = StorageUtils.getObject(StorageKey.SESSION);
  // const [open, setOpen] = useState(false);

  const handleLogout = async () => {
    try {
      StorageUtils.clear();
      Sentry.setUser(null);
      dispatch(removeAuth());
      deactivate();
      await UserService.logOut(session?.refreshToken);
      disconnect();
    } catch (error) {
      // Dont need error logout here
    }
  };
  // const handleChangePassword = () => {
  //   setOpen(true);
  // };

  const MENU_ITEM = [
    { label: 'ログアウト', onClick: handleLogout, icon: faArrowRightFromBracket },
    // {
    //   label: 'Change Password',
    //   onClick: handleChangePassword,
    //   icon: faKey,
    // },
  ];

  return (
    <div className={styles.profile}>
      {MENU_ITEM.map((item: any, index: number) => (
        <div key={index} className={styles.profileMenu} onClick={item.onClick}>
          <FontAwesomeIcon icon={item.icon} size="lg" color={'white'} style={{ marginRight: 10 }} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Profile;
