import { Form, Input, Modal, notification, Space } from 'antd';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { UPLOAD_TYPE } from 'constants/common';
import { useEffect, useState } from 'react';
import nftService from 'service/nft-service';
import NotificationService from 'service/notifications';
import { NotificationDataRes } from 'types/notifications';
import { CHECK_FILE } from 'utils/rule-form';
import socket from 'utils/socket';
import styles from '../styles.module.scss';
interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  handelOpenViewNotiModal: (IdItem: number | string | null) => void;
  valueNotiSelected: NotificationDataRes | null;
}

enum Action {
  Save = 'Save',
  SaveDraff = 'SaveDraff',
}

export interface DataForm {
  id?: string;
  title: string;
  description: string;
  file?: { file: File | string };
}

const ModalCreateEditNoti = ({
  isOpen,
  valueNotiSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
  handelOpenViewNotiModal,
}: Props) => {
  const [form] = Form.useForm();
  const watchDescription = Form.useWatch('description', form);
  const watchImage = Form.useWatch('file', form);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(Action.SaveDraff);
  const [dataPresign, setDataPresign] = useState<any>();
  const [idNotiUpdated, setIdNotiUpdated] = useState<any>(null);

  useEffect(() => {
    if (watchImage?.file) {
      form.setFields([{ name: 'description', errors: [] }]);
    }
  }, [watchImage?.file, form]);

  useEffect(() => {
    if (watchDescription) {
      form.setFields([{ name: 'file', errors: [] }]);
    }
  }, [watchDescription, form]);

  const onFinish = async (values: DataForm) => {
    setLoading(true);
    const dataUpdate = {
      ...values,
      status: 'DRAFT',
    };
    delete dataUpdate.file;
    if (valueNotiSelected?.id) {
      dataUpdate.id = valueNotiSelected?.id;
    }

    const response = await NotificationService.updateNotification(dataUpdate);

    setIdNotiUpdated(response?.id);
    if (response?.id && !values?.file?.file) {
      setLoading(false);
      notification.success({
        message: 'Success',
        description: '下書きで保存されました。',
        duration: 4,
      });
      if (action === Action.Save) {
        handelOpenViewNotiModal(response?.id);
      } else {
        handleCancelCreateEditModal();
      }
      onChangeStatusUpdateData();
    }

    if (
      response?.id &&
      values?.file?.file &&
      valueNotiSelected?.bannerObjectKey === values?.file?.file
    ) {
      setLoading(false);
      notification.success({
        message: 'Success',
        description: '通知が変更されました。',
        duration: 4,
      });
      if (action === Action.Save) {
        handelOpenViewNotiModal(response?.id);
      } else {
        handleCancelCreateEditModal();
      }
      onChangeStatusUpdateData();
    }

    if (
      response?.id &&
      values?.file?.file &&
      valueNotiSelected?.bannerObjectKey !== values?.file?.file
    ) {
      await handlePresign(values, response);
    }
  };

  const handleSaveNotiModal = () => {
    setAction(Action.Save);
    form.submit();
  };

  useEffect((): any => {
    socket.on('NOTIFICATION_UPLOAD_BANNER_SUCCESS', (message: any) => {
      if (message.notificationId === dataPresign?.data?.notificationId) {
        setLoading(false);

        if (action === Action.Save) {
          notification.success({
            message: 'Success',
            description: valueNotiSelected?.id
              ? '通知が変更されました。'
              : '下書きで保存されました。',
            duration: 4,
          });
          handelOpenViewNotiModal(idNotiUpdated);
        } else {
          notification.success({
            message: 'Success',
            description: '下書きで保存されました。',
            duration: 4,
          });
          handleCancelCreateEditModal();
        }
        onChangeStatusUpdateData();
      }
    });

    if (socket) return () => socket.off('NOTIFICATION_UPLOAD_BANNER_SUCCESS');
  }, [
    dataPresign,
    action,
    handelOpenViewNotiModal,
    handleCancelCreateEditModal,
    idNotiUpdated,
    onChangeStatusUpdateData,
    valueNotiSelected?.id,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      title: valueNotiSelected?.title || '',
      description: valueNotiSelected?.description || '',
      file: { file: valueNotiSelected?.bannerObjectKey },
    });
  }, [form, valueNotiSelected]);

  const handlePresign = async (values: any, resCreateNoti: any) => {
    try {
      const resPresign = await NotificationService.createPresign({
        type: 'banner',
        name: values?.file?.fileKey || '',
        notificationId: Number(resCreateNoti?.id),
      });
      setDataPresign(resPresign);
      await handleUploadS3(resPresign, values?.file?.file, resCreateNoti);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Upload image failed', duration: 4 });
      console.error(`Error handle presign`, JSON.stringify(error));
      setLoading(false);
      if (action === Action.Save) {
        handelOpenViewNotiModal(Number(resCreateNoti?.id));
      } else {
        handleCancelCreateEditModal();
      }
      onChangeStatusUpdateData();
    }
  };

  const handleUploadS3 = async (resPresign: any, fileImagePresign: any, resCreateNoti: any) => {
    try {
      await nftService.uploadFileS3(
        resPresign?.data?.url,
        fileImagePresign,
        resPresign?.data?.headers
      );
    } catch (error) {
      notification.error({ message: 'Error', description: 'Upload image failed', duration: 4 });
      console.error(`Error upload s3`, JSON.stringify(error));
      if (action === Action.Save) {
        handelOpenViewNotiModal(Number(resCreateNoti?.id));
      } else {
        handleCancelCreateEditModal();
      }
      onChangeStatusUpdateData();
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueNotiSelected?.id ? '通知の更新' : 'お知らせ作成'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="title">
            タイトル
          </label>
          <Form.Item
            name="title"
            rules={[
              { required: true, message: 'タイトルは必須項目です。' },
              {
                max: 55,
                message: 'タイトルフィールドには55文字以下ご入力ください。',
              },
            ]}
            validateFirst={true}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="通知のタイトルを入力ください" size="large" />
          </Form.Item>
          <label className={styles.labelInput} htmlFor="description">
            説明文
          </label>
          <Form.Item
            name="description"
            rules={[
              { required: !watchImage?.file ? true : false, message: '説明文は必須項目です。' },
              { max: 1000, message: '説明文フィールドには1000文字以下ご入力ください。' },
            ]}
            validateFirst={true}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea placeholder="説明文" rows={5} size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="file">
            添付画像
          </label>
          <Form.Item
            name="file"
            wrapperCol={{ span: 24 }}
            rules={[CHECK_FILE('添付画像', !!watchDescription)]}
          >
            <CustomizedUpload
              type={UPLOAD_TYPE.IMAGE}
              maxFileSize={1}
              edit={!!valueNotiSelected?.bannerObjectKey}
            />
          </Form.Item>
          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                loading={loading}
                text="下書きで保存"
                htmlType="submit"
              />
              <CustomButton
                bgColor="primary-green"
                loading={loading}
                onClick={handleSaveNotiModal}
                text="確認画面へ行く"
                htmlType="button"
              />
              <CustomButton
                text="キャンセル"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
                loading={loading}
                fontWeight="700"
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEditNoti;
