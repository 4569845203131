import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/configure-store';
import App from './App';
import ErrorBoundary from 'pages/error-boundary';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from '@sentry/react';

function getLibrary(provider, connector) {
  // depend on web3 or ethers
  return new Web3Provider(provider);
}
const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/be\.stg\.dta-nft\.jp/, /^https:\/\/be\.dta-nft\.jp/],
    }),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              <App />
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider >
  </React.StrictMode >,
  document.getElementById('root')
);
