import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { API } from 'redux/api-route';
import { DataRequestList } from 'types/admin';
import { DataResponseList } from 'types/common';
import { ContactDataRes } from 'types/contact';
import AxiosUtils from 'utils/axios-utils';

const ContactService = {
  getListMessage: async (dataRequest: DataRequestList) => {
    try {
      const res: AxiosResponse<DataResponseList<ContactDataRes>> = await AxiosUtils.get(
        API.inquiry,
        dataRequest
      );
      return res.data;
    } catch (error) {
        notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  comfirmMessage: async (id: number) => {
    try {
      await AxiosUtils.post(
       `${API.inquiryRead}/${id}` 
      );
      notification.success({
        message: 'Success',
        description: "問い合わせが解決済みです。",
        duration: 4,})
    } catch (error) {
        notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
};

export default ContactService;
