import { getLoading, getSuccess, getFailure } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { AppDispatch } from '../../types/redux';
import { NFT_ATTRIBUTES, TRANSACTION_ATTRIBUTES } from 'constants/common';
import { IS_ACTIVE } from 'constants/nft-custom';

export type Account = {
  email?: string;
  password?: string;
};

export const getAttributes = (name: any) => async (dispatch: AppDispatch) => {
  let endPoint = '';
  switch (name) {
    case NFT_ATTRIBUTES.COLOR:
      endPoint = API.getColors;
      break;
    case NFT_ATTRIBUTES.MOTION:
      endPoint = API.getMotions;
      break;
    case NFT_ATTRIBUTES.TYPE:
      endPoint = API.getNftType;
      break;
    case NFT_ATTRIBUTES.CHARACTER:
      endPoint = API.getCharacter;
      break;
    case NFT_ATTRIBUTES.STATUS:
      endPoint = API.getStatus;
      break;
    case NFT_ATTRIBUTES.POSITION:
      endPoint = API.getPosition;
      break;
    case TRANSACTION_ATTRIBUTES.STATUS:
      endPoint = API.getTransactionStatus;
      break;
    case NFT_ATTRIBUTES.IS_ACTIVE:
      dispatch(getSuccess({ data: IS_ACTIVE, name }));
      break;
    default:
      endPoint = '';
  }

  try {
    dispatch(getLoading({ name }));
    console.log(`[action.ts]: endpoint: ${endPoint}`)
    const res: any = await AxiosUtils.get(endPoint, { page: -1, limit: 10 });
    dispatch(getSuccess({ data: res?.data?.items, name }));
  } catch (error: any) {
    dispatch(getFailure(error));
  }
};
