import { Button, Form, Input, notification } from 'antd';
import logo from 'assets/images/logo.png';
import { REGEX_PASSWORD } from 'constants/rule-form';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from 'redux/api-route';
import { paths } from 'routes/routes';
import AxiosUtils from 'utils/axios-utils';
import { REQUIRED } from 'utils/rule-form';
import styles from './styles.module.scss';

interface FormValues {
  password: string;
}

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get('tokenResetPassword') || !searchParams.get('adminId')) {
      navigate(paths.login);
    }
  }, [navigate, searchParams]);
  const onResetPassword = async (values: FormValues) => {
    const data = {
      tokenResetPassword: searchParams.get('tokenResetPassword'),
      adminId: searchParams.get('adminId'),
      newPassword: values.password,
    };
    setLoading(true);
    try {
      await AxiosUtils.postToken(API.resetPassword, data);
      notification.success({
        message: 'success',
        description: 'パスワードを正常にリセットしました。',
        duration: 4,
      });
      navigate(paths.login);
    } catch (error) {      
      console.error(`Error reset password`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: 'パスワード リセット トークンが無効または期限切れです。',
        duration: 4,
      });
      navigate(paths.sendMailForgotPw);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.boxSendMailPw}>
      <Form
        layout="vertical"
        name="login-form"
        className={styles.sendMailPwForm}
        onFinish={onResetPassword}
      >
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <h2>パスワード変更</h2>
        <Form.Item
          label="新しいパスワード必須"
          name="password"
          hasFeedback
          rules={[
            REQUIRED(),
            {
              pattern: new RegExp(REGEX_PASSWORD),
              message:
                'パスワードは8文字以上15文字以内 、アルファベットの 大文字、小文字 、数字、記号の複合で作成してください。',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="パスワード確認必須"
          name="comparePw"
          dependencies={['password']}
          hasFeedback
          rules={[
            REQUIRED(),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('確認用パスワードが正しくありません。'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Button
          loading={loading}
          disabled={loading}
          className={styles.btnSend}
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          ログイン画面へ
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
