import { Card, Col, ConfigProvider, Empty, Row, Select, Table, Tooltip } from 'antd';
import { ADMIN_ROLE, TYPE_CHART } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardService from 'service/dashboard-service';
import { RootState } from 'types/redux';
import { UsersType } from 'types/users';
import { shortenName } from 'utils/short-name';
import BarChart from './bar-chart';
import styles from '../index.module.scss';
import { formatterCurrency } from 'utils/common';
import { getUserDetail } from 'redux/user/selector';

const NUMBER_OF_TOP_USER = 10;
const LIST_USER_MONTH = [
  { value: 0, title: '全て' },
  { value: 3, title: '3ヶ月' },
  { value: 6, title: '6ヶ月' },
  { value: 12, title: '12ヶ月' },
];

const ChartContainer = () => {
  const [dataChartRegister, setDataChartRegister] = useState<any>({
    chart: { labels: [], datasets: [] },
    total: 0,
  });
  const [dataChartTransaction, setDataChartTransaction] = useState<any>({
    chart: { labels: [], datasets: [] },
    total: 0,
  });
  const [dataUsers, setDataUsers] = useState<UsersType[]>([]);
  const user = useSelector(getUserDetail);
  const isShowRegistrationAndTopUser =
    user.role === ADMIN_ROLE.MASTER_ADMIN || user.role === ADMIN_ROLE.UPBOND_ADMIN;
  const isShowTransaction =
    user.role === ADMIN_ROLE.MEGAHOUSE_ADMIN || user.role === ADMIN_ROLE.MASTER_ADMIN;
  const isMegaUser = user.role === ADMIN_ROLE.MEGAHOUSE_ADMIN;
  const isUpbondUser = user.role === ADMIN_ROLE.UPBOND_ADMIN;

  const columns = [
    {
      title: 'ランク',
      key: 'rank',
      width: 100,
      align: 'center' as const,
      render: (record: any, _: any, index: number) => <span>{index + 1}</span>,
    },
    {
      title: '名前',
      dataIndex: 'user_name',
      key: 'name',
      width: 150,
      align: 'center' as const,
      render: (name: string) => (
        <>
          <Tooltip title={name}>{shortenName(name || '', 20) || ''}</Tooltip>
        </>
      ),
    },
    {
      title: '合計金額',
      dataIndex: 'total_price',
      key: 'price',
      width: 200,
      align: 'center' as const,
      render: (price: string) => <span>{formatterCurrency.format(Number(price))}</span>,
    },
    {
      title: 'アドレス',
      key: 'address',
      dataIndex: 'wallet_address',
      width: 200,
      align: 'center' as const,
      render: (address: string) => <span>{address}</span>,
    },
  ];

  const charts = useSelector((state: RootState) => state.charts);

  const yearsRegisterOption = charts.yearsRegister.data.map((item) => {
    return {
      label: item.year,
      value: item.year,
      type: TYPE_CHART.REGISTER,
    };
  });

  const yearsTransactionOption = charts.yearsTransaction.data.map((item) => {
    return {
      label: item.year,
      value: item.year,
      type: TYPE_CHART.TRANSACTION,
    };
  });

  const userMonthOption = LIST_USER_MONTH.map((item) => {
    return {
      label: item.title,
      value: item.value,
      type: TYPE_CHART.USERS,
    };
  });

  const currentYear = new Date().getFullYear();

  const renderDatasets = (data: any, year: number) => {
    const labelsRender: any[] = [];
    const dataResult: any[] = [];
    for (const key in data) {
      labelsRender.push(`${key}/${year}`);
      dataResult.push(data?.[key]);
    }
    return {
      labels: labelsRender,
      datasets: dataResult,
    };
  };

  const getRegistration = async (year: number) => {
    const result = await DashboardService.getRegistration(year);
    const total = result.total;

    if (result?.data) {
      const datasetsRender = renderDatasets(result.data, year);

      setDataChartRegister({
        total,
        chart: {
          ...datasetsRender,
          datasets: [{ data: datasetsRender.datasets, label: '登録数' }],
        },
      });
    }
  };

  const getTransaction = async (year: number) => {
    const result = await DashboardService.getTransaction(year);
    const total = result.total;

    if (result?.data) {
      const datasetsRender = renderDatasets(result.data, year);
      setDataChartTransaction({
        total,
        chart: {
          ...datasetsRender,
          datasets: [{ data: datasetsRender.datasets, label: '取引' }],
        },
      });
    }
  };

  const getTopUser = async (number: number, month) => {
    const result: UsersType[] | undefined = await DashboardService.getUser(number, month);

    if (result) {
      setDataUsers(result);
    }
  };

  const handleFilter = (_, option) => {
    const { type } = option;
    switch (type) {
      case TYPE_CHART.REGISTER:
        getRegistration(option.value);
        break;
      case TYPE_CHART.TRANSACTION:
        getTransaction(option.value);
        break;
      case TYPE_CHART.USERS:
        getTopUser(NUMBER_OF_TOP_USER, option.value);
        break;
    }
  };

  useEffect(() => {
    if (user?.role) {
      const isMegaHouse = user.role === ADMIN_ROLE.MEGAHOUSE_ADMIN;
      const isUpbond = user.role === ADMIN_ROLE.UPBOND_ADMIN;
      const isSuper = user.role === ADMIN_ROLE.MASTER_ADMIN;
      if (isMegaHouse || isSuper) {
        getTransaction(currentYear);
      }
      if (isUpbond || isSuper) {
        getTopUser(NUMBER_OF_TOP_USER, LIST_USER_MONTH[0].value);
        getRegistration(currentYear);
      }
    }
  }, [user]);

  return (
    <div>
      <Row gutter={[20, 20]} style={{ marginBottom: 24 }}>
        {isShowRegistrationAndTopUser && (
          <Col
            xl={{ span: isUpbondUser ? 24 : 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <BarChart
              title="ユーザーの登録量"
              data={{
                options: yearsRegisterOption,
                defaultValue: currentYear,
                dataChart: dataChartRegister,
              }}
              handleFilter={handleFilter}
            />
          </Col>
        )}
        {isShowTransaction && (
          <Col
            xl={{ span: isMegaUser ? 24 : 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <BarChart
              title="販売量"
              data={{
                options: yearsTransactionOption,
                defaultValue: currentYear,
                dataChart: dataChartTransaction,
              }}
              handleFilter={handleFilter}
            />
          </Col>
        )}

        {isShowRegistrationAndTopUser && (
          <Col span={24}>
            <Card hoverable className={styles.card}>
              <Row className={styles['chart__header']}>
                <Col span="20">
                  <span className={styles['chart__title']}>トップ 10 ユーザー</span>
                </Col>
                <Col span="4" className="text-right">
                  <Select
                    className={styles['chart__select']}
                    onChange={handleFilter}
                    options={userMonthOption}
                    defaultValue={LIST_USER_MONTH[0]}
                  />
                </Col>
              </Row>
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    description="対象データが見つかりません。"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              >
                <Table columns={columns} dataSource={dataUsers} pagination={false} />
              </ConfigProvider>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ChartContainer;
