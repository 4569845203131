import { ContractInformation } from "types/contract";
import { StorageKey, StorageUtils } from "./local-storage"
import {AbiItem} from 'web3-utils';

export const getContract = (): ContractInformation => {
    return StorageUtils.getObject(StorageKey.CONTRACT);
}

export const getContractAddress = () => {
    return getContract()?.contractAddress;
}

export const getAddressDeployContract = () => {
    return getContract().address;
}

export const getContractABI = (): AbiItem[] => {
    return JSON.parse(getContract().abi);
}

export const getMintRoleContract = () => {
    return getContract().mintRole;
}

export const getBurnRoleContract = () => {
    return getContract().burnRole;
}

export const getOpenseaLink = () => {
    return getContract().opensea;
}

export const getOpenseaItemLink = () => {
    return getContract().openseaItem;
}

export const getOpenseaLinkOfNft = (nftId: number) => {
    return `${getOpenseaItemLink()}/${getContractAddress().toLowerCase()}/${nftId}`
}
