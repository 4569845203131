import { Card, List, notification } from 'antd';
import React, { useState } from 'react';
import UserService from 'service/user-service';
import ChangePassword from './change-password';
import UpdateProfile from './update-profile';
import UpdateUserModal from './user-modal';
import styles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import { ChangePasswordModel, UpdateUserProfileRequest } from 'types/account';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'types/redux';
import { updateUserProfile } from 'redux/user/action';
import { useWeb3React } from '@web3-react/core';
import { StorageKey, StorageUtils } from 'utils/local-storage';
import { removeAuth } from 'redux/auth/reducers';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';

enum MENU_LIST {
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
  LOG_OUT,
}

type MenuType = { type: MENU_LIST; label: string } & { [key: string]: any; icon?: any };

const PROFILE_MENU: MenuType[] = [
  { type: MENU_LIST.CHANGE_PASSWORD, label: 'パスワード変更' },
  { type: MENU_LIST.UPDATE_PROFILE, label: 'プロファイル更新' },
  { type: MENU_LIST.LOG_OUT, label: 'ログアウト', icon: faArrowRightFromBracket },
];

const changePasswordInitialValues: ChangePasswordModel = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const ProfileMenu = () => {
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [isOpenUpdateProfile, setIsOpenUpdateProfile] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const user = useSelector(getUserDetail);
  const dispatch = useDispatch<AppDispatch>();
  const { deactivate } = useWeb3React();
  const session = StorageUtils.getObject(StorageKey.SESSION);

  const handleLogout = async () => {
    try {
      StorageUtils.clear();
      Sentry.setUser(null);
      dispatch(removeAuth());
      deactivate();      
      await UserService.logOut(session?.refreshToken);
    } catch (error) {
      // Dont need error logout here
    }
  };

  const handlerItemClick = (item: MenuType): void => {
    if (isOpenChangePassword || isOpenUpdateProfile) return;

    switch (item.type) {
      case MENU_LIST.CHANGE_PASSWORD:
        setIsOpenChangePassword(true);
        break;
      case MENU_LIST.UPDATE_PROFILE:
        setIsOpenUpdateProfile(true);
        break;
      case MENU_LIST.LOG_OUT:
        handleLogout();
        break;
    }
  };

  const onCloseChangePasswordModalHandler = () => {
    setIsOpenChangePassword(false);
  };

  const onCloseUpdateProfileModalHandler = () => setIsOpenUpdateProfile(false);

  const onSubmitChangePasswordHandler = async (
    value: ChangePasswordModel | UpdateUserProfileRequest
  ) => {
    if ((value as ChangePasswordModel).newPassword) {
      setIsModalLoading(true);
      const { newPassword, oldPassword } = value as ChangePasswordModel;
      try {
        await UserService.changePassword({ newPassword, oldPassword });
        notification.success({
          message: 'Success',
          description: 'パスワードは変更されました。',
          duration: 4,
        });
        onCloseChangePasswordModalHandler();
      } catch (error) {
        console.error(`Error change password`, JSON.stringify(error));
        notification.error({
          message: 'Error',
          description: error?.message ?? '現在のパスワードが正しくありません。 再試行してください...',
          duration: 4,
        });
      } finally {
        setIsModalLoading(false);
      }
    }
  };
  const onSubmitUpdateProfileHandler = async (
    value: ChangePasswordModel | UpdateUserProfileRequest
  ) => {
    if (value as UpdateUserProfileRequest) {
      setIsModalLoading(true);
      const body: UpdateUserProfileRequest = {
        name: (value as UpdateUserProfileRequest).name,
        phoneNumber: (value as UpdateUserProfileRequest).phoneNumber,
      };

      dispatch(
        updateUserProfile({
          body,
          onSuccess: () => {
            onCloseUpdateProfileModalHandler();
            setIsModalLoading(false);
          },
          onFailed: () => setIsModalLoading(false),
        })
      );
    }
  };

  return (
    <>
      <Card
        style={{
          boxShadow: '0 0.5rem 2.5rem rgba(0,0,0,0.15)',
        }}
      >
        <List
          className="demo-loadmore-list"
          dataSource={PROFILE_MENU}
          style={{ width: 'max-content' }}
          renderItem={(item) => (
            <List.Item className={styles['menu']} onClick={handlerItemClick.bind(null, item)}>
              {item?.icon && (
                <FontAwesomeIcon icon={item.icon} size="lg" style={{ marginRight: 10 }} />
              )}
              <p className={styles['menu-item']}>{item.label}</p>
            </List.Item>
          )}
        />
      </Card>

      <UpdateUserModal
        isLoading={isModalLoading}
        title="パスワード変更"
        isOpenModal={isOpenChangePassword}
        data={changePasswordInitialValues}
        onSubmit={onSubmitChangePasswordHandler}
        onClose={onCloseChangePasswordModalHandler}
      >
        <ChangePassword />
      </UpdateUserModal>
      <UpdateUserModal
        isLoading={isModalLoading}
        title="プロファイル変更"
        isOpenModal={isOpenUpdateProfile}
        data={user}
        onSubmit={onSubmitUpdateProfileHandler}
        onClose={onCloseUpdateProfileModalHandler}
      >
        <UpdateProfile />
      </UpdateUserModal>
    </>
  );
};

export default ProfileMenu;
