import React, { useMemo } from 'react';
import ModelViewer from 'components/model-viewer';
import { Avatar, Carousel, Col, Row, Skeleton, Typography } from 'antd';
import CustomButton from 'components/custom-button';
import styles from './model-card.module.scss';
import { get } from 'lodash';
import { FILE_TYPE } from 'constants/common';
import { DownloadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

type StatisticProps = {
  color?: string;
};

const StatisticInfo: React.FC<StatisticProps> = (props) => {
  const { color } = props;

  return (
    <div
      className={styles['statistic-item']}
      style={{ backgroundColor: color, borderWidth: color ? 0 : 1 }}
    >
      {props.children}
    </div>
  );
};

type ModelCardType = 'body' | '2nd' | 'presets';
type ModelProps = {
  type: ModelCardType;
  cardName?: string;
  data: any;
  loading?: boolean;
  isCustomize?: boolean;
};

const ModelCard: React.FC<ModelProps> = (props) => {
  const { type, cardName, data, loading = false, isCustomize } = props;
  const is2ndNFTCard = type === '2nd';
  const isBodyCard = type === 'body';
  const isPresetCard = !is2ndNFTCard && !isBodyCard;

  console.warn('=====', props, isCustomize);

  const fileInfo = useMemo(() => {
    let model = '';
    let preview = '';
    let preview2D = '';
    get(data, 'images', []).forEach((item: any) => {
      if (item.type === FILE_TYPE.PREVIEW) preview = item.objectKey;
      if (item.type === FILE_TYPE.MODEL) model = item.objectKey;
      if (item.type === FILE_TYPE.PREVIEW2D) preview2D = item.objectKey;
    });

    return { model, preview, preview2D };
  }, [data]);

  const onDownload = () => {
    const element = document.createElement('a');

    element.href = fileInfo.model;
    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <>
      <div className={`${styles['model-card']} ${is2ndNFTCard && styles['model-card--2nd']} `}>
        {cardName && <Text className={styles['model-card__name']}>{cardName}</Text>}

        <Row gutter={[is2ndNFTCard ? 48 : 16, 0]}>
          <Col lg={{ span: 8 }} className={styles['left-content']}>
            <div className={styles.modelWrapper}>
              {loading ? (
                <Skeleton.Image active className={styles.imageBox} />
              ) : (
                <>
                  {is2ndNFTCard ? (
                    <Carousel autoplay>
                      <div className={styles.imageBox}>
                        <ModelViewer
                          src={fileInfo.preview}
                          width="100%"
                          height="100%"
                          background="transparent"
                        />
                      </div>
                      <img src={fileInfo.preview2D} alt="model 3d" className={styles.imageBox} />
                    </Carousel>
                  ) : (
                    <ModelViewer
                      src={fileInfo.preview}
                      width="100%"
                      height="100%"
                      background="transparent"
                    />
                  )}
                </>
              )}
            </div>
            {is2ndNFTCard && (
              <CustomButton
                text={
                  <div className="d-flex align-center">
                    <DownloadOutlined style={{ marginRight: '1rem' }} />
                    ダウンロード
                  </div>
                }
                bgColor="primary-green"
                style={{ marginTop: '2rem' }}
                onClick={onDownload}
              />
            )}
          </Col>
          <Col lg={{ span: 16 }} className={styles['right-content']}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                {is2ndNFTCard && (
                  <div className={styles['header-statistic']}>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            人物
                          </Col>
                          <Col>
                            <b>{data.characterName}</b>
                          </Col>
                        </Row>
                      </Col>
                      {isCustomize && (
                        <Col span="14">
                          <Row>
                            <Col span="6" className={styles.label}>
                              所属チーム
                            </Col>
                            <Col>
                              <b>{data.teamName}</b>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            状態
                          </Col>
                          <Col>
                            <StatisticInfo>{data.status}</StatisticInfo>
                          </Col>
                        </Row>
                      </Col>
                      {isCustomize && (
                        <Col span="14">
                          <Row>
                            <Col span="6" className={styles.label}>
                              ベース素体
                            </Col>
                            <Col>
                              <b>{data?.originBody}</b>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            タイプ
                          </Col>
                          <Col>
                            <b>{data.type}</b>
                          </Col>
                        </Row>
                      </Col>
                      {isCustomize && (
                        <Col span="14">
                          <Row>
                            <Col span="6" className={styles.label}>
                              ファイル形式
                            </Col>
                            <Col>
                              <b>{data.fileType?.toUpperCase?.()}</b>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    {isCustomize && (
                      <Row className={styles.row}>
                        <Col span="10"></Col>
                        <Col span="14">
                          <Row>
                            <Col span="6" className={styles.label}>
                              その他
                            </Col>
                            <Col>
                              <b>{data.other}</b>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}

                <Title level={is2ndNFTCard ? 2 : 3} style={{ marginBottom: '0.5rem' }}>
                  {data.title}
                </Title>
                <Text>{data.description}</Text>

                {is2ndNFTCard && (
                  <div className={styles['artist-list']}>
                    <div className={styles['artist-item']}>
                      {data.creatorAvatar && <Avatar size="large" src={data.creatorAvatar} />}
                      <Text className={styles['artist-item__name']}>{data.creatorName}</Text>
                    </div>
                  </div>
                )}

                <div className={styles['model-card__footer']}>
                  {(isBodyCard || isPresetCard) && (
                    <div className={styles.statistics}>
                      {isBodyCard && (
                        <>
                          <StatisticInfo>{data.type}</StatisticInfo>
                          {/* <StatisticInfo color={get(data, 'colorName', '').toString()} />
                      <StatisticInfo>{data.motionName}</StatisticInfo> */}
                        </>
                      )}
                      {isPresetCard && (
                        <>
                          <StatisticInfo>{data.type}</StatisticInfo>
                          {/* <StatisticInfo>シルフィ</StatisticInfo> */}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ModelCard;
