/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ConfigProvider, Empty, Modal, notification, Pagination, Table } from 'antd';
import PageTitle from '../../components/page-title';
import styles from './styles.module.scss';
import {
  SecondNFTListResponse,
  SecondNFTMasterFilterForm,
  SecondNFTMasterFilterRequest,
  SecondNftMasterRequestData,
} from 'types/2st-nft';
import Create2ndModal from './component/create2ndModal';
import { masterTableConfig } from './common/config';
import { FILE_TYPE, LIMIT, NFT_ATTRIBUTES, StepInfo, STEPS } from 'constants/common';
import nftService from 'service/nft-service';
import { generateInitSteps, handlerFinishStep, getStepsLabel } from 'utils/common';
import LoadingSteps from 'components/loading-steps';
import { get } from 'lodash';
import socket from 'utils/socket';
import { useDispatch } from 'react-redux';
import { getAttributes } from 'redux/attributes/actions';
import { AppDispatch } from 'types/redux';
import MasterFilter from './component/masterFilter';
import ConfirmModal from './component/confirmModal';
import { CONFIRM_ACTION } from '.';
import Loading from 'components/loading';

const loadingStepInit: StepInfo = generateInitSteps([
  STEPS.CREATE_2ND,
  STEPS.CREATE_PRESIGN_URL,
  STEPS.UPLOAD,
  STEPS.SAVE_TO_DB,
]);

const initPreviewStatus = {
  [FILE_TYPE.MODEL]: false,
  [FILE_TYPE.PREVIEW]: false,
  [FILE_TYPE.PREVIEW2D]: false,
};

const initPresignInfo = {
  [FILE_TYPE.MODEL]: '',
  [FILE_TYPE.PREVIEW]: '',
  [FILE_TYPE.PREVIEW2D]: '',
};
const initialFilter: SecondNFTMasterFilterRequest = {
  name: '',
  character: '',
  page: 1,
  limit: LIMIT,
  sortField: 'created_at',
  desc: true,
  // status: '',
};

type Mode = 'edit' | 'create';

const Create2stNFTMaster = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [action, setAction] = useState<Mode>('create');
  const [isOpenNftModal, setIsOpenNftModal] = useState(false);
  const [isOpenLoadingStepModal, setIsLoadingStepModal] = useState(false);
  const [nftDetail, setNftDetail] = useState<any>(null);
  const [nftDetailId, setNftDetailId] = useState(0);
  const [presignInfo, setPresignInfo] = useState(initPresignInfo);
  const [preViewStatus, setPreviewStatus] = useState(initPreviewStatus);
  const [stepInfo, setStepInfo] = useState(loadingStepInit);
  const [isFinish, setIsFinish] = useState(false);
  const [filter, setFilter] = useState<SecondNFTMasterFilterRequest>(initialFilter);
  const [dataResponse, setDataResponse] = useState<SecondNFTListResponse>({
    items: [],
    totalCount: 0,
  });
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);
  const [fetchDetail, setFetchDetail] = useState(false);

  const isEdit = action === 'edit';

  const enablePaging = dataResponse.totalCount > LIMIT;

  useEffect(() => {
    [NFT_ATTRIBUTES.CHARACTER, NFT_ATTRIBUTES.POSITION].forEach((item) => {
      dispatch(getAttributes(item));
    });
  }, []);

  useEffect(() => {
    const getList = async () => {
      setIsLoading(true);

      const response = await nftService.getMasterSecondNfts(filter);
      if (response) setDataResponse(response);

      setIsLoading(false);
    };

    getList();
  }, [filter, reload]);

  useEffect(() => {
    if (isEdit && isOpenNftModal && nftDetailId) {
      const getNftDetail = async () => {
        setFetchDetail(true);
        const res = await nftService.getMasterNft(nftDetailId);
        if (res) {
          setNftDetail(res);
          setFetchDetail(false);
        }
      };

      getNftDetail();
    }
  }, [isEdit, isOpenNftModal, nftDetailId]);

  useEffect((): any => {
    socket.on('MASTER_UPLOAD_PREVIEW_SUCCESS', (message: any) => {
      if (
        message.nftId === nftDetailId &&
        message.imageId === get(presignInfo, FILE_TYPE.PREVIEW)
      ) {
        setPreviewStatus((pre) => ({ ...pre, [FILE_TYPE.PREVIEW]: true }));
      } else if (isFinish) {
        handlerFinishStep({
          step: STEPS.SAVE_TO_DB,
          error: 'データベースへの保存に失敗しました',
          setStep: setStepInfo,
        });
      }
    });

    return () => {
      if (socket && !presignInfo[FILE_TYPE.PREVIEW]) {
        socket.off('MASTER_UPLOAD_PREVIEW_SUCCESS');
      }
    };
  }, [nftDetailId, presignInfo[FILE_TYPE.PREVIEW]]);

  useEffect((): any => {
    socket.on('MASTER_UPLOAD_PREVIEW2D_SUCCESS', (message: any) => {
      if (
        message?.nftId === nftDetailId &&
        message.imageId === get(presignInfo, FILE_TYPE.PREVIEW2D)
      ) {
        setPreviewStatus((pre) => ({ ...pre, [FILE_TYPE.PREVIEW2D]: true }));
      } else if (isFinish) {
        handlerFinishStep({
          step: STEPS.SAVE_TO_DB,
          error: 'データベースへの保存に失敗しました',
          setStep: setStepInfo,
        });
      }
    });

    return () => {
      if (socket && !presignInfo[FILE_TYPE.PREVIEW2D]) {
        socket.off('MASTER_UPLOAD_PREVIEW2D_SUCCESS');
      }
    };
  }, [nftDetailId, presignInfo[FILE_TYPE.PREVIEW2D]]);

  useEffect((): any => {
    socket.on('MASTER_UPLOAD_MODEL_3D_SUCCESS', (message: any) => {
      if (message?.nftId === nftDetailId && message.imageId === get(presignInfo, FILE_TYPE.MODEL)) {
        setPreviewStatus((pre) => ({ ...pre, [FILE_TYPE.MODEL]: true }));
      } else if (isFinish) {
        handlerFinishStep({
          step: STEPS.SAVE_TO_DB,
          error: 'データベースへの保存に失敗しました',
          setStep: setStepInfo,
        });
      }
    });

    return () => {
      if (socket && !presignInfo[FILE_TYPE.MODEL]) {
        socket.off('MASTER_UPLOAD_MODEL_3D_SUCCESS');
      }
    };
  }, [nftDetailId, presignInfo[FILE_TYPE.MODEL]]);

  useEffect(() => {
    const uploadPreview = preViewStatus[FILE_TYPE.PREVIEW];
    const uploadPreview2D = preViewStatus[FILE_TYPE.PREVIEW2D];
    const uploadModel = preViewStatus[FILE_TYPE.MODEL];

    if (nftDetailId && uploadPreview && uploadPreview2D && uploadModel) {
      handlerFinishStep({
        step: STEPS.SAVE_TO_DB,
        error: null,
        setStep: setStepInfo,
      });

      notification.success({
        message: 'Success',
        description: isEdit
          ? '二次NFTマスタが変更されました。'
          : '二次NFTマスタの作成に成功しました。',
        duration: 4,
      });

      onCloseNftModal();

      setTimeout(() => {
        onCloseLoadingStepModal();
        // onHandlerClickDetail(nftDetailId);
      }, 300);

      onReloadPage();
    }
  }, [preViewStatus, nftDetailId, isEdit]);

  const onReloadPage = () => {
    setReload((pre) => !pre);
  };

  const reset = () => {
    setPresignInfo(initPresignInfo);
    setPreviewStatus(initPreviewStatus);
    setStepInfo(loadingStepInit);
    setIsFinish(false);
  };

  const onHandlerClickCreate = () => {
    setIsOpenNftModal(true);
    setAction('create');
  };

  const onHandlerClickDetail = async (id) => {
    setIsOpenNftModal(true);
    setAction('edit');
    setNftDetailId(id);
  };

  const onFinish = ({ name, characterIds = [], status = [] }: SecondNFTMasterFilterForm) => {
    setFilter({
      ...initialFilter,
      name,
      character: characterIds.join(','),
      // status: Array.isArray(status) ? status.filter(Boolean).join(',') : status,
      status: undefined,
    });
  };

  const handleChangePage = (page: number) => {
    setFilter((preState) => ({ ...preState, page }));
  };

  const onFinish2NFT = async (value: any) => {
    setIsLoadingStepModal(true);
    try {
      const { title, id, description, isPreset, listWeapons = [] } = value;

      const requestBody: SecondNftMasterRequestData = {
        title,
        description,
        bodyId:
          isEdit && typeof value.body === 'number' ? value.body : value.body.value ?? value.body,
        presetId:
          isEdit && typeof value.preset === 'number' ? value.preset : get(value, 'preset.value', 0),
        weapons: listWeapons
          .map((item: any) => get(item, 'weapon.id', ''))
          .filter(Boolean)
          .join(','),
        isPreset,
        isActive: true,
      };
      const files: any[] = [];
      let hasErrorCreatePresign = false;
      let hasErrorUpload: any = false;

      const [combineRes, createError] = isEdit
        ? await nftService.updateCombineNft(id, requestBody)
        : await nftService.masterCombineNft(requestBody);

      const nftId = isEdit ? id : get(combineRes, 'id');

      if (!isEdit) setNftDetailId(nftId);

      handlerFinishStep({ step: STEPS.CREATE_2ND, error: createError, setStep: setStepInfo });
      if (createError) return;

      const createPresignPromise = Object.values(FILE_TYPE)
        .map((val: string) => {
          const file = value[val];

          if (!file) return null;

          if (typeof file.file === 'string') {
            // case update but not upload file
            setPreviewStatus((pre) => ({
              ...pre,
              [val]: true,
            }));
            return null;
          }

          const fileData: any = { name: file.fileKey, nftId, type: val };
          return nftService.masterCreatePresign(fileData).then(([res, error]) => {
            if (error) hasErrorCreatePresign = error;
            setPresignInfo((pre) => ({ ...pre, [val]: get(res, 'imageId') }));
            files.push({
              url: get(res, 'url'),
              file: file.file,
              headers: get(res, 'headers'),
            });
          });
        })
        .filter(Boolean);
      await Promise.all(createPresignPromise);
      handlerFinishStep({
        step: STEPS.CREATE_PRESIGN_URL,
        error: hasErrorCreatePresign,
        setStep: setStepInfo,
      });
      if (hasErrorCreatePresign) return;

      const uploadToS3Promise = files.map(({ url, file, headers }: any) =>
        nftService.uploadFileS3(url, file, headers)
      );
      const res: any = await Promise.all(uploadToS3Promise);

      hasErrorUpload = res.some((resItem: any) => resItem?.status !== 200);

      handlerFinishStep({
        step: STEPS.UPLOAD,
        error: hasErrorUpload ? '画像のアップロードに失敗しました' : null,
        setStep: setStepInfo,
      });

      setIsFinish(true);
    } catch (error) {
      console.error(
        `error finish 2nd nft :`,
        isEdit
          ? 'セカンダリ NFT マスターの更新に失敗しました'
          : 'セカンダリ NFT マスターの作成に失敗しました', 
        JSON.stringify(error)
      );
      handlerFinishStep({
        step: STEPS.SAVE_TO_DB,
        error: isEdit
          ? 'セカンダリ NFT マスターの更新に失敗しました'
          : 'セカンダリ NFT マスターの作成に失敗しました',
        setStep: setStepInfo,
      });
    }
  };

  const onResetFilter = () => {
    setFilter(initialFilter);
    onReloadPage();
  };

  const onCloseNftModal = () => {
    setIsOpenNftModal(false);
    setNftDetail({});
  };

  const onCloseLoadingStepModal = () => {
    setIsLoadingStepModal(false);
    reset();
  };

  const onClickOpenConfirmDelete = (id: number) => {
    setNftDetailId(id);
    setIsOpenConfirmModel(true);
  };

  const handlerDeleteNft = async () => {
    try {
      setIsLoadingBtn(true);
      await nftService.deleteMasterNft(nftDetailId);
      onReloadPage();
      notification.success({
        message: 'Success',
        description: '二次NFTマスタが削除されました。',
        duration: 4,
      });
      onCloseNftModal();
    } catch (error) {
      console.error(`Error delete nft :`, JSON.stringify(error));
      notification.error({ message: 'Error', description: error.message, duration: 4 });
    } finally {
      setIsOpenConfirmModel(false);
      setIsLoadingBtn(false);
    }
  };

  const onCloseConfirmDelete = () => {
    setIsOpenConfirmModel(false);
  };

  const columnsConfig = masterTableConfig({
    handlerDeleteNft: onClickOpenConfirmDelete,
    onHandlerClickDetail,
  });

  return (
    <div className="custom-form">
      <PageTitle title="二次NFTマスタ管理" />
      <div className={styles.filterContainer}>
        <MasterFilter onSubmit={onFinish} onCreate={onHandlerClickCreate} onReset={onResetFilter} />
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="二次NFTマスタが見つかりません。「二次NFT作成」ボタンをクリックして二次NFTを作成してください。"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <Table
          columns={columnsConfig}
          rowKey="id"
          pagination={false}
          dataSource={get(dataResponse, 'items')}
          loading={isLoading}
        />
      </ConfigProvider>
      {enablePaging && (
        <div className="text-right mt-10">
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
          />
        </div>
      )}

      <Modal
        title={isEdit ? '二次NFT詳細' : '二次NFT作成'}
        open={isOpenNftModal}
        closable={false}
        footer={null}
        destroyOnClose
        width="75%"
        zIndex={100}
      >
        {fetchDetail ? (
          <Loading />
        ) : (
          <Create2ndModal
            edit={isEdit}
            data={nftDetail}
            onFinish={onFinish2NFT}
            onClose={onCloseNftModal}
            onDelete={onClickOpenConfirmDelete}
          />
        )}
      </Modal>

      <Modal
        title={isEdit ? '二次NFTマスタ詳細' : '二次NFTマスタ作成'}
        open={isOpenLoadingStepModal}
        closable={false}
        footer={null}
        destroyOnClose
        width="20%"
        zIndex={200}
      >
        <LoadingSteps
          steps={stepInfo}
          getLabels={getStepsLabel}
          onClose={onCloseLoadingStepModal}
        />
      </Modal>

      <Modal
        open={isOpenConfirmModel}
        footer={null}
        onCancel={onCloseConfirmDelete}
        destroyOnClose
        closable={false}
      >
        <ConfirmModal
          action={CONFIRM_ACTION.DELETE}
          onConfirm={handlerDeleteNft}
          onClose={onCloseConfirmDelete}
          buttonLoading={isLoadingBtn}
        />
      </Modal>
    </div>
  );
};

export default Create2stNFTMaster;
