import { Avatar, ConfigProvider, Empty, Modal, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import FaucetService from 'service/faucet-service';
import {
  Faucet,
  FaucetFilter,
  FaucetFilterRequest,
  FaucetListResponse,
  Faucets,
} from 'types/faucet';
import { formatedDate } from 'utils/common';
import PageTitle from '../../components/page-title';
import FaucetDetail from './component/faucet-detail-modal';
import Filter from './component/filter';
import styles from './styles.module.scss';
import { shortenAddress } from 'utils/short-name';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const { Option } = Select;

const FaucetManager = () => {
  const [loading, setLoading] = useState(true);
  const [isFetchDetail, setIsFetchDetail] = useState(true);
  const [faucetDetail, setFaucetDetail] = useState<Faucet[]>([]);
  const [infoUser, setInfoUser] = useState({ image: null, name: '' });
  const [dataResponse, setDataResponse] = useState<FaucetListResponse>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as FaucetFilterRequest);
  const onFinish = (value: FaucetFilter) => {
    setFilter((preState) => ({ ...preState, ...value, page: 1 }));
  };
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const fetchFaucet = async () => {
      setLoading(true);
      try {
        const res = await FaucetService.getFaucets({
          ...filter,
          sortField: 'last_faucet',
          sortType: 'DESC',
        });
        setDataResponse(res);
      } finally {
        setLoading(false);
      }
    };

    fetchFaucet();
  }, [filter]);

  const onClickDetail = async (item) => {
    setIsOpenModal(true);
    setIsFetchDetail(true);
    setInfoUser({ image: item?.avatar, name: item?.user_name });
    try {
      const res = await FaucetService.getFaucet(item?.id);
      setFaucetDetail(res);
    } finally {
      setIsFetchDetail(false);
    }
  };

  const columnsConfig: ColumnsType<Faucets> = [
    {
      title: 'ID',
      width: '5%',
      ellipsis: true,
      key: 'id',
      render: (_, record, index) => {
        return (filter.page - 1) * filter.limit + index + 1;
      },
    },
    {
      title: 'アバター',
      align: 'center',
      key: 'avatar',
      render: (_, record) => (
        <>
          <div>
            {record.avatar && <Avatar shape="square" size={50} src={record.avatar} />}
            <p className="mt-10">{record.user_name}</p>
          </div>
        </>
      ),
    },
    {
      title: 'ウォレットアドレス',
      ellipsis: true,
      dataIndex: 'wallet_address',
      key: 'wallet_address',
      render: (_, record) => (
        <>
          <div>
            <p className="mt-10">{shortenAddress(record.wallet_address, 12, 6)}</p>
          </div>
        </>
      ),
    },
    {
      title: 'Amount (MATIC)',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
    {
      title: 'フォーセット日付',
      dataIndex: 'last_update',
      key: 'last_update',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
    {
      title: 'アクション',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="アクション"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => onClickDetail(record)} style={{ textAlign: 'center' }}>
              表示
            </div>
          </Option>
        </Select>
      ),
    },
  ];

  const handleChangePage = (page: number) => {
    setFilter((preState) => ({ ...preState, page }));
  };

  return (
    <div>
      <PageTitle title="フォーセット一覧" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} />
      </div>

      <div className="custom-table custom-form">
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              description="フォーセットのトランザクション履歴がありません。"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        >
          <Table
            columns={columnsConfig}
            rowKey="id"
            pagination={false}
            dataSource={dataResponse.items}
            // scroll={{ x: 'max-content' }}
            loading={loading}
          />
        </ConfigProvider>
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      <Modal
        title={'フォーセットトランザクション詳細'}
        open={isOpenModal}
        closable={false}
        footer={null}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        destroyOnClose
        width={900}
      >
        <FaucetDetail
          onClose={() => setIsOpenModal(false)}
          isLoading={isFetchDetail}
          data={faucetDetail}
          infoUser={infoUser}
        />
      </Modal>
    </div>
  );
};

export default FaucetManager;
