import { Row, Col } from 'antd';
import PageTitle from 'components/page-title';
import React, { useEffect, useState } from 'react';
import UserStats from '../../../assets/icons/user-stats.svg';
import DocStats from '../../../assets/icons/doc-stats.svg';
import TickStats from '../../../assets/icons/tick-stats.svg';

import styles from '../index.module.scss';
import { Statistics } from 'types/common';
import DashboardService from 'service/dashboard-service';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import { ADMIN_ROLE } from 'constants/common';

const HeaderStatistic = () => {
  const [statistic, setStatistic] = useState<Statistics>();
  const [nftStatistic, setNftStatistic] = useState<any>();
  const user = useSelector(getUserDetail);
  const isShowFirstEle =
    user.role === ADMIN_ROLE.UPBOND_ADMIN || user.role === ADMIN_ROLE.MASTER_ADMIN;
  const isShowSecondToEnd =
    user.role === ADMIN_ROLE.MEGAHOUSE_ADMIN || user.role === ADMIN_ROLE.MASTER_ADMIN;

  const getStatisticDetail = async () => {
    const result: Statistics | undefined = await DashboardService.getStatistic();
    if (result) {
      setStatistic(result);
    }
  };

  const getNftStatisticDetail = async () => {
    const result: Statistics | undefined = await DashboardService.getNftStatistic();
    if (result) {
      setNftStatistic(result);
    }
  };

  useEffect(() => {
    if (user?.role) {
      const isMegaHouse = user.role === ADMIN_ROLE.MEGAHOUSE_ADMIN;
      const isUpbond = user.role === ADMIN_ROLE.UPBOND_ADMIN;
      const isSuper = user.role === ADMIN_ROLE.MASTER_ADMIN;
      if (isMegaHouse || isSuper) {
        getNftStatisticDetail();
      }
      if (isUpbond || isSuper) getStatisticDetail();
    }
  }, [user]);
  return (
    <>
      <PageTitle title="ダッシュボード" />

      <Row gutter={[24, 16]}>
        {isShowFirstEle && (
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <div className={`${styles.statistic} ${styles['statistic--1']} ${styles.card}`}>
              <p>
                <img src={UserStats} alt="icon" />
                ユーザー総量
              </p>
              <h3>{statistic?.total_user}</h3>
            </div>
          </Col>
        )}

        {isShowSecondToEnd && (
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <div className={`${styles.statistic} ${styles['statistic--2']} ${styles.card}`}>
              <p>
                <img src={DocStats} alt="icon" />
                NFT総量
              </p>
              <div className={styles.statistic2nd}>
                <p>
                  2D: &nbsp; <span>{nftStatistic?.total_first_nft_2d}</span>
                </p>
                <p>
                  3D: &nbsp; <span>{nftStatistic?.total_first_nft_3d}</span>
                </p>
              </div>
            </div>
          </Col>
        )}
        {isShowSecondToEnd && (
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <div className={`${styles.statistic} ${styles['statistic--3']} ${styles.card}`}>
              <p>
                <img src={TickStats} alt="icon" />
                NFT販売中
              </p>
              <div className={styles.statistic2nd}>
                <p>
                  2D: &nbsp; <span>{nftStatistic?.total_first_nft_selling_2d}</span>
                </p>
                <p>
                  3D: &nbsp; <span>{nftStatistic?.total_first_nft_selling_3d}</span>
                </p>
              </div>
            </div>
          </Col>
        )}
        {isShowSecondToEnd && (
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
            <div className={`${styles.statistic} ${styles['statistic--4']} ${styles.card}`}>
              <p>
                <img src={TickStats} alt="icon" />
                二次NFT:
              </p>
              <Row className={styles.statistic2ndPlus}>
                <Col span={12}>
                  <p className={styles.titleType}>2D</p>
                  <p>
                    ミント申請中: &nbsp; <span>{nftStatistic?.second_nft_2d?.pending_2d}</span>
                  </p>
                  <p>
                    承認済み量: &nbsp; <span>{nftStatistic?.second_nft_2d?.approved_2d}</span>
                  </p>
                  <p>
                    リジェクト量: &nbsp;<span>{nftStatistic?.second_nft_2d?.reject_2d}</span>
                  </p>
                </Col>
                <Col span={12}>
                  <p className={styles.titleType}>3D</p>
                  <p>
                    ミント申請中: &nbsp; <span>{nftStatistic?.second_nft_3d?.pending}</span>
                  </p>
                  <p>
                    承認済み量: &nbsp; <span>{nftStatistic?.second_nft_3d?.approved}</span>
                  </p>
                  <p>
                    リジェクト量: &nbsp;<span>{nftStatistic?.second_nft_3d?.reject}</span>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default HeaderStatistic;
