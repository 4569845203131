import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { API } from 'redux/api-route';
import { AdminDataRes, DataPost, DataRequestList } from 'types/admin';
import { DataResponseList } from 'types/common';
import AxiosUtils from 'utils/axios-utils';

const AdminService = {
  getListAdmins: async (dataRequest: DataRequestList) => {
    const res: AxiosResponse<DataResponseList<AdminDataRes>> = await AxiosUtils.get(
      API.adminManage,
      dataRequest
    );
    return res.data;
  },
  updateAdmin: async (data: DataPost) => {
    try {
      const res = await AxiosUtils.post(API.updateAdmin, data);
      notification.success({
        message: 'Success',
        description: data?.id ? '管理者の情報が変更されました !' : '管理者が作成されました !',
        duration: 4,
      });
      return [res, null];
    } catch (error: any) {
      if (error.message === 'Email already taken') {
        notification.error({
          message: 'Error',
          description: 'このメールアドレスは既に登録されています',
          duration: 4,
        });
      } else {
        notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
      }
      return [null, error];
    }
  },
  deleteAdmin: async (id?: string) => {
    try {
      await AxiosUtils._delete(API.deleteAdmin.replace('{{id}}', id ?? ''));
      notification.success({
        message: 'Success',
        description: '管理者が削除されました。',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
    }
  },
};

export default AdminService;
