import { Empty, Image, Select, Tooltip } from 'antd';
import Loading from 'components/loading';
import { NFT_STATUS } from 'constants/common';
import React, { useEffect, useState } from 'react';
import nftService from 'service/nft-service';
import { List1stParams, WeaponType } from 'types/1st-nft';
import { shortenName } from 'utils/short-name';
import styles from '../style.module.scss';

interface IProps {
  value: any;
  positionId: number;
  handleSelect: (value: any, index: number, positionId: number) => void;
  index: number;
  weaponDetail: {
    positionId: number | string;
    weapon: WeaponType;
  };
  disabled?: boolean;
  additionalParams?: { character?: number | undefined };
}

interface IParams extends List1stParams {
  page: number;
  limit: number;
  desc: boolean;
  sortField: string;
  type: string;
  position?: string;
  status: string;
  character?: number | undefined;
}

const LIMIT = 10;

const initialPaging = {
  page: 1,
  limit: LIMIT,
  desc: false,
  sortField: 'created_at',
  type: 'weapon',
  status: [NFT_STATUS.MINTED, NFT_STATUS.SELLING, NFT_STATUS.SELLING].toString(),
};

const { Option } = Select;

const SelectAnWeapon = ({
  handleSelect,
  value,
  positionId,
  additionalParams,
  index,
  weaponDetail,
  disabled,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isInitAdditionalParams, setInitAdditionalParams] = useState<boolean>(false);
  const [paging, setPaging] = useState<IParams>({
    ...initialPaging,
    position: String(positionId),
  });
  const [listWeapon, setListWeapon] = useState<WeaponType[]>([]);
  const [total, setTotal] = useState<number>(0);

  const getListWeapon = async () => {
    setLoading(true);
    const result = await nftService.getList1stNft(paging);
    if (result?.items) {
      const newList = listWeapon.concat(
        result?.items?.filter((item: WeaponType) => item.id !== weaponDetail?.weapon?.id)
      );
      setListWeapon(newList);
      setTotal(result?.totalCount);
    }
    setLoading(false);
  };

  const renderOption = (dataOption: any) => {
    return dataOption.map((item: any, index: number) => (
      <Option value={String(item?.id)} key={index}>
        <div className={styles.weaponOption}>
          <Image src={item?.previewImg} width={25} height={25} preview={false} />
          <Tooltip className={styles.weaponName} title={item?.name}>
            {shortenName(item.name, 13) || ''}
          </Tooltip>
        </div>
      </Option>
    ));
  };
  const onScroll = (e: any) => {
    const target = e.target;
    if (
      !loading &&
      total > listWeapon?.length &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setLoading(true);
      setPaging({ ...paging, page: paging.page + 1 });
    }
  };

  useEffect(() => {
    setPaging((pre) => ({ ...initialPaging, position: pre.position, ...additionalParams }));
    setListWeapon([]);
    setInitAdditionalParams(true);
  }, [additionalParams]);

  useEffect(() => {
      if (!disabled && isInitAdditionalParams) getListWeapon();
  }, [paging, disabled]);

  useEffect(() => {
    const weaponIndex = listWeapon.findIndex(
      (item) => Number(item.id) === Number(weaponDetail?.weapon?.id)
    );
    if (weaponIndex < 0 && weaponDetail?.weapon?.id) {
      const weaponPlus = {
        id: weaponDetail?.weapon?.id,
        previewImg: weaponDetail?.weapon?.objectKey ?? weaponDetail?.weapon?.previewImg,
        name: weaponDetail?.weapon?.title ?? weaponDetail?.weapon?.name,
      };

      setListWeapon([weaponPlus, ...listWeapon]);
    }
  }, [weaponDetail]);

  return (
    <div className="weapon-select-box">
      <Select
        notFoundContent={
          loading ? (
            <Loading />
          ) : (
            <Empty description="データがありません。" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onSelect={(value: string) => {
          const option = listWeapon?.filter((item) => String(item?.id) === value)[0];
          handleSelect(option, index, positionId);
        }}
        value={weaponDetail?.weapon?.id ? String(weaponDetail?.weapon?.id) : undefined}
        className="select-multiple"
        placeholder="Select"
        showArrow={true}
        style={{ width: '100%' }}
        getPopupContainer={(trigger) => trigger.parentElement}
        onPopupScroll={onScroll}
        listHeight={200}
        disabled={disabled}
      >
        <Option value="" key="all">
          None
        </Option>
        {listWeapon.length > 0 && renderOption(listWeapon)}
        {loading && total > listWeapon?.length && (
          <Option key="loading">
            <Loading />
          </Option>
        )}
      </Select>
    </div>
  );
};
export default SelectAnWeapon;
