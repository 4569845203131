import { Col, DatePicker, Form, Input, Row } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import { FaucetFilter } from 'types/faucet';
import moment from 'moment';

type FilterProps = {
  onSubmit: (value: FaucetFilter) => void;
};
const { RangePicker } = DatePicker;

const initialValues = {
  username: '',
  email: '',
  walletAddress: '',
  datePicker: [],
};

const Filter: React.FC<FilterProps> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit } = props;

  const onClearFilter = () => {
    form.resetFields();
    onSubmit({ ...form.getFieldsValue() });
  };

  const onFormSubmit = (value: typeof initialValues) => {
    const { datePicker, ...rest } = value;
    let startDate = '';
    let endDate = '';

    if (datePicker) {
      const [start, end] = datePicker as moment.Moment[];

      startDate = start?.format('YYYY-MM-DD');
      endDate = end?.format('YYYY-MM-DD');
    }

    onSubmit({ ...rest, startDate, endDate });
  };

  return (
    <Form form={form} name="register" onFinish={onFormSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="username" label="ユーザー名">
            <Input placeholder="名前を入力してください" />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="walletAddress" label="ウォレットアドレス">
            <Input placeholder="アドレスを入力してください" />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label="トランザクション日付" name="datePicker">
            <RangePicker placeholder={['開始日', '終了日']} />
          </Form.Item>
        </Col>
        <Col style={{ paddingTop: 25 }}>
          <Form.Item className="text-right mt-10">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="検索"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="クリア"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-5"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
