import { useWeb3Modal } from '@web3modal/react';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';

const Wrapper = (WrappedComponent) => {
  const WrapperComponent = () => {
    const { open } = useWeb3Modal();
    const { isConnected } = useAccount();

    useEffect(() => {
      if (!isConnected) {
        open();
      }
    }, [isConnected, open]);

    return (
      <div>
        <WrappedComponent isConnected={isConnected} />
      </div>
    );
  };

  return WrapperComponent;
};

export default Wrapper;
