import { Form, Modal, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { useEffect } from 'react';
import { ChangePasswordModel, UpdateUserProfileRequest } from 'types/account';

type DataType = ChangePasswordModel | UpdateUserProfileRequest;

type UpdateUserModalProps = {
  title: string;
  onSubmit: (value: DataType) => void;
  onClose: () => void;
  data: DataType;
  isOpenModal: boolean;
  isLoading?: boolean;
};

const UpdateUserModal: React.FC<UpdateUserModalProps> = (props) => {
  const [form] = Form.useForm();
  const { title, data, onSubmit, onClose, isOpenModal, isLoading = false } = props;

  const onCloseHandler = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
    }
  }, [isOpenModal, form]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <>
      <Modal
        forceRender
        title={title}
        open={isOpenModal}
        footer={null}
        closable={false}
        onCancel={!isLoading ? onCloseHandler : undefined}
        width={500}
        zIndex={100}
        destroyOnClose
      >
        <Form className="custom-form" form={form} initialValues={data} onFinish={onSubmit}>
          {props.children}

          <Form.Item label="">
            <div style={{ marginTop: '20px' }} className="d-flex justify-center">
              <Space size="large">
                <CustomButton
                  bgColor="primary-green"
                  text="保存"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                />
                <CustomButton
                  bgColor="primary-grey"
                  text="キャンセル"
                  htmlType="button"
                  onClick={onCloseHandler}
                  disabled={isLoading}
                />
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserModal;
