import React, { useMemo } from 'react';
import { Avatar, Col, Row, Skeleton, Typography, notification } from 'antd';
import CustomButton from 'components/custom-button';
import styles from './model-card.module.scss';
import { get } from 'lodash';
import { FILE_TYPE } from 'constants/common';
import { DownloadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

type StatisticProps = {
  color?: string;
};

const StatisticInfo: React.FC<StatisticProps> = (props) => {
  const { color } = props;

  return (
    <div
      className={styles['statistic-item']}
      style={{ backgroundColor: color, borderWidth: color ? 0 : 1 }}
    >
      {props.children}
    </div>
  );
};

type ModelCardType = 'body' | '2nd' | 'presets';
type ModelProps = {
  type: ModelCardType;
  cardName?: string;
  data: any;
  loading?: boolean;
};
type IImage = { name: string; objectKey: string };

const IMAGE_DETAIL_INITIAL = { name: '', objectKey: '' };

const ModelCard: React.FC<ModelProps> = (props) => {
  const { type, cardName, data, loading = false } = props;
  const is2ndNFTCard = type === '2nd';
  const isBodyCard = type === 'body';
  const isPresetCard = !is2ndNFTCard && !isBodyCard;

  const fileInfo = useMemo(() => {
    let preview: IImage = IMAGE_DETAIL_INITIAL;
    let preview2D: IImage = IMAGE_DETAIL_INITIAL;
    let model: IImage = IMAGE_DETAIL_INITIAL;
    get(data, 'images', []).forEach((item: any) => {
      if (item.type === FILE_TYPE.PREVIEW) preview = item;
      if (item.type === FILE_TYPE.PREVIEW2D) preview2D = item;
      if (item.type === FILE_TYPE.MODEL) model = item;
    });

    return { preview, preview2D, model };
  }, [data]);

  async function download(params) {
    const fileUrl = params?.objectKey;
    const fileName = params?.name;

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Set the desired file name

      // Append the link to the body and trigger the click event
      document.body.appendChild(a);
      a.click();

      // Remove the link from the document
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      notification.error({
        message: 'Error',
        description:
          'Error downloading file, it seems you have no access to download this file or the file is broken.',
        duration: 4,
      });
    }
  }

  const onDownload = async () => {
    var linkArr = [fileInfo.preview, fileInfo.model];
    // await Promise.all(linkArr.map((item) => download(item)));

    for (const item of linkArr) {
      await download(item);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve('');
        }, 1000);
      });
    }
  };

  return (
    <>
      <div className={`${styles['model-card']} ${is2ndNFTCard && styles['model-card--2nd']} `}>
        {cardName && <Text className={styles['model-card__name']}>{cardName}</Text>}

        <Row gutter={[is2ndNFTCard ? 48 : 16, 0]}>
          <Col lg={{ span: 8 }} className={styles['left-content']}>
            <div className={styles.modelWrapper}>
              {loading ? (
                <Skeleton.Image active className={styles.imageBox} />
              ) : (
                <>
                  <div
                    className={styles.bgImageBox}
                    style={{
                      backgroundImage: `url(${fileInfo.preview2D.objectKey})`,
                    }}
                  ></div>
                  {/* <img
                    src={fileInfo.preview2D.objectKey}
                    alt="preview"
                    className={styles.imageBox}
                  /> */}
                </>
              )}
            </div>
            {is2ndNFTCard && (
              <CustomButton
                text={
                  <div className="d-flex align-center">
                    <DownloadOutlined style={{ marginRight: '1rem' }} />
                    ダウンロード
                  </div>
                }
                bgColor="primary-green"
                style={{ marginTop: '2rem' }}
                onClick={async () => {
                  await onDownload();
                }}
              />
            )}
          </Col>
          <Col lg={{ span: 16 }} className={styles['right-content']}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                {is2ndNFTCard && (
                  <div className={styles['header-statistic']}>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            人物
                          </Col>
                          <Col>
                            <b>{data.characterName}</b>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            状態
                          </Col>
                          <Col>
                            <StatisticInfo>{data.status}</StatisticInfo>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className={styles.row}>
                      <Col span="10">
                        <Row>
                          <Col span="6" className={styles.label}>
                            タイプ
                          </Col>
                          <Col>
                            <b>{data.type}</b>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}

                <Title level={is2ndNFTCard ? 2 : 3} style={{ marginBottom: '0.5rem' }}>
                  {data.title}
                </Title>
                <Text>{data.description}</Text>

                {is2ndNFTCard && (
                  <div className={styles['artist-list']}>
                    <div className={styles['artist-item']}>
                      {data.creatorAvatar && <Avatar size="large" src={data.creatorAvatar} />}
                      <Text className={styles['artist-item__name']}>{data.creatorName}</Text>
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ModelCard;
