import { useWeb3React } from '@web3-react/core';
import { ConfigProvider, Empty, Modal, notification, Pagination, Table } from 'antd';
import { LIMIT, NFT_ATTRIBUTES } from 'constants/common';
import { useWagmiHook } from 'hooks/wagmi.hook';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAttributes } from 'redux/attributes/actions';
import nftService from 'service/nft-service';
import {
  PICK_UP_TYPE,
  SecondNftDetail,
  SecondNFTFilterForm,
  SecondNFTFilterRequest,
  SecondNFTListResponse,
  SECOND_NFT_STATUS,
} from 'types/2st-nft';
import { AppDispatch } from 'types/redux';
import { getWagmiContractConfig } from 'utils/connectors';
import { getAddressDeployContract } from 'utils/contract';
import { useContractWrite } from 'wagmi';
import PageTitle from '../../components/page-title';
import { tableConfig } from './common/config';
import ConfirmModal from './component/confirmModal';
import Detail2ndModal from './component/detail2ndModal';
import Filter from './component/filter';
import styles from './styles.module.scss';

export enum CONFIRM_ACTION {
  APPROVE = 'approve',
  REJECT = 'reject',
  DELETE = 'delete',
}

const initialPageInfo = { page: 1, limit: 10 };

const initialFilter: SecondNFTFilterRequest = {
  sortField: 'created_at',
  desc: true,
  name: '',
  ownerAddress: '',
  status: SECOND_NFT_STATUS.ALL,
  pickUpType: PICK_UP_TYPE.ALL,
  ...initialPageInfo,
};

const Create2stNFT = () => {
  const { account, library } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isFetchDetail, setIsFetchDetail] = useState(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState<CONFIRM_ACTION>(CONFIRM_ACTION.APPROVE);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState<SecondNFTFilterRequest>(initialFilter);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState<SecondNFTListResponse>({
    items: [],
    totalCount: 0,
  });
  const [dataDetail, setDataDetail] = useState<SecondNftDetail>({} as SecondNftDetail);
  const enablePaging = dataResponse.totalCount > LIMIT;

  const { hasMintRole } = useWagmiHook(dataDetail?.nftId);

  const { write: mintNft } = useContractWrite({
    ...(getWagmiContractConfig() as any),
    functionName: 'createNFT',
    onSuccess(data) {
      console.log('Mint Nft Success', data);
      console.log('Wait 10 second before approving');
      // add delay of 10 second before approving
      // to wait for minted NFT to be registered in RPC
      setTimeout(() => {
        console.log('approving NFT...');
        nftService.approveSecond2dNft(dataDetail.id).then((result) => {
          if (result) {
            const msg = '二次NFTが成功にミントされました。';
            notification.success({
              message: 'Success',
              description: msg,
              duration: 4,
            });
          }
  
          setButtonLoading(false);
          setIsOpenConfirmModal(false);
        }).catch((err) => {
          const msg = 'Please wait a moment, then try approving again!';
          notification.error({
            message: 'Failed to Approve NFT',
            description: msg,
            duration: 10,
          });

          setButtonLoading(false);
          setIsOpenConfirmModal(false);
        });
      }, 10000);
    },
    onError(error: any) {
      console.log('Mint Nft Error', error);
      console.error(`2nd nft Mint Nft Error :`, JSON.stringify(error));
      notification.info({
        message: 'Error',
        description: error?.shortMessage || 'ウォレットサイン要求がリジェクトされました。',
        duration: 4,
      });
    },
    onSettled() {},
  });

  useEffect(() => {
    [NFT_ATTRIBUTES.CHARACTER, NFT_ATTRIBUTES.POSITION].forEach((item) => {
      dispatch(getAttributes(item));
    });
  }, []);

  useEffect(() => {
    const fetchNfts = async () => {
      setLoading(true);
      const res = await nftService.getSecond2dNfts(filter);
      if (res) setDataResponse(res);
      setLoading(false);
    };

    fetchNfts();
  }, [filter, reload]);

  const handleChangePage = (page: number) => {
    setFilter((preState) => ({ ...preState, page }));
  };

  const onFinish = ({ name, ownerAddress, status, type, pickUpType }: SecondNFTFilterForm) => {
    setFilter((pre) => ({
      ...pre,
      name,
      ownerAddress,
      status: status.join(','),
      pickUpType: pickUpType.join(','),
    }));
  };

  const onHandlerClickDetail = async (id: number) => {
    setIsFetchDetail(true);
    setIsOpenDetailModal(true);

    const res = await nftService.getSecond2dNft(id);
    if (res) {
      setDataDetail(res);
    } else {
      setIsOpenDetailModal(false);
    }
    setIsFetchDetail(false);
  };
  const onCloseDetailModal = () => {
    setIsOpenDetailModal(false);
  };

  const onOpenConfirmModalClick = (actionType: CONFIRM_ACTION) => () => {
    onCloseDetailModal();
    setIsOpenConfirmModal(true);
    setConfirmAction(actionType);
  };
  const onCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleApproveNft = async (nftId: number, id: number) => {
    const check: any = await nftService.approveSecond2dNftVerify(id);
    if (check) {
      if (hasMintRole) {
        if (!check?.isMinted) {
          mintNft({
            args: [getAddressDeployContract(), Number(nftId), 1],
          });
          return;
        } else {
          nftService.approveSecond2dNft(dataDetail.id).then((result) => {
            if (result) {
              const msg = '二次NFTが成功にミントされました。';
              notification.success({
                message: 'Success',
                description: msg,
                duration: 4,
              });
            }
    
            setButtonLoading(false);
            setIsOpenConfirmModal(false);
          }).catch((err) => {
            console.log(err);
            const msg = 'Please wait a moment, then try approving again!';
            notification.error({
              message: 'Failed to Approve NFT',
              description: msg,
              duration: 10,
            });
  
            setButtonLoading(false);
            setIsOpenConfirmModal(false);
          });
          return;
        }
      }
      console.error(`2nd nft ミント権限がありません。`);
      notification.error({
        message: 'Error',
        description: 'ミント権限がありません。',
        duration: 4,
      });

      return;
    } else {
      console.error(`ミント権限がありません。`);
      throw new Error("ミント権限がありません。");
    }
  };

  const onConfirm = async (formValues: any) => {
    let msg = '';
    let isError = false;

    setButtonLoading(true);

    if (confirmAction === CONFIRM_ACTION.APPROVE) {
      await handleApproveNft(dataDetail.nftId, dataDetail.id);
      return;
    }
    try {
      await nftService.rejectSecond2dNft(dataDetail.id, formValues);
      msg = 'リジェクトされました。';
      setReload((pre) => !pre);
    } catch (error) {
      isError = true;
      msg = error.message;
      console.error(`2nd nft on confirm`, JSON.stringify(error));
    } finally {
      if (isError) {
        notification.error({
          message: 'Error',
          description: msg,
          duration: 4,
        });
      } else {
        notification.success({
          message: 'Success',
          description: msg,
          duration: 4,
        });
      }
      setButtonLoading(false);
      setIsOpenConfirmModal(false);
    }
  };

  const onResetFilter = () => {
    setReload((pre) => !pre);
    setFilter(initialFilter);
  };

  const columnsConfig = tableConfig(onHandlerClickDetail);

  const onPickUp = async (formValues: any) => {
    try {
      const isPickUp = formValues.isPickUp;
      setButtonLoading(true);

      if (isPickUp) {
        await nftService.pickUp2dNft(dataDetail.id);
      } else {
        await nftService.removePickUp2dNft(dataDetail.id);
      }
      notification.success({
        message: 'Success',
        description: isPickUp ? 'ピックアップが正常に完了しました。' : 'アンピックアップが正常に完了しました。',
        duration: 4,
      });
    } catch (error: any) {      
      console.error(`2nd nft on pick up`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: error.message,
        duration: 4,
      });
    } finally {
      onCloseDetailModal();
      setReload((pre) => !pre);
      setButtonLoading(false);
    }
  };

  const onApproveClick = (formValues: any) => {
    if (dataDetail.status === SECOND_NFT_STATUS.APPROVED) {
      onPickUp(formValues);
      return;
    }

    onOpenConfirmModalClick(CONFIRM_ACTION.APPROVE)();
  };

  return (
    <div className="custom-form">
      <PageTitle title="二次NFT管理（イラスト）" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} onReset={onResetFilter} />
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="対象データが見つかりません。" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      >
        <Table
          columns={columnsConfig}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          loading={loading}
        />
      </ConfigProvider>
      {enablePaging && (
        <div className="text-right mt-10">
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
          />
        </div>
      )}

      <Modal
        title={'二次NFT詳細（イラスト）'}
        open={isOpenDetailModal}
        footer={null}
        destroyOnClose
        width="75%"
        closable={false}
      >
        <Detail2ndModal
          data={dataDetail}
          isLoading={isFetchDetail}
          onClose={onCloseDetailModal}
          onReject={onOpenConfirmModalClick(CONFIRM_ACTION.REJECT)}
          onApprove={onApproveClick}
        />
      </Modal>

      <Modal
        open={isOpenConfirmModal}
        footer={null}
        onCancel={onCloseConfirmModal}
        destroyOnClose
        closable={false}
      >
        <ConfirmModal
          action={confirmAction}
          onConfirm={onConfirm}
          onClose={onCloseConfirmModal}
          buttonLoading={buttonLoading}
        />
      </Modal>
    </div>
  );
};

export default Create2stNFT;
