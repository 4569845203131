import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import CustomButton from 'components/custom-button';
import { StepInfo, STEPS } from 'constants/common';
import React from 'react';

type Props = {
  steps: StepInfo;
  getLabels: (step: STEPS) => string;
  onClose: () => void;
};

const LoadingSteps: React.FC<Props> = (props) => {
  const { steps, getLabels, onClose } = props;

  return (
    <div>
      {Object.keys(steps).map((k: string, index: number) => {
        const step = steps[k as STEPS];
        const label = getLabels(k as STEPS);

        return (
          <Row gutter={[24, 0]}>
            <Col xs={4} xl={4} md={4}>
              {step.loading ? (
                <Spin indicator={<LoadingOutlined />} className="mr-10" />
              ) : step.hasError ? (
                <CloseOutlined className="mr-10" style={{ color: 'red' }} />
              ) : (
                <CheckOutlined className="mr-10" style={{ color: '#2e85ff' }} />
              )}
            </Col>
            <Col xs={20} xl={20} md={20} className="text-left">
              {`${index + 1}. ${label}`}
            </Col>
          </Row>
        );
      })}

      <div className="text-center mt-10">
        <CustomButton text="キャンセル" bgColor="primary-grey" onClick={onClose} />
      </div>
    </div>
  );
};

export default LoadingSteps;
