import React from 'react';
import FileUpload from 'components/file-upload';
import { Input } from 'antd';

interface Props {
  value?: {
    fileKey: string;
    fileUrl: string;
  };
  onChange?: any;
  type: string;
  maxFileSize: number;
  edit?: boolean;
  enablePreview?: boolean;
  disabled?: boolean;
}

const CustomizedUpload = ({
  value,
  onChange,
  type,
  maxFileSize,
  edit,
  enablePreview,
  disabled,
}: Props) => {
  return (
    <div>
      <FileUpload
        value={value}
        onChange={onChange}
        type={type}
        maxFileSize={maxFileSize}
        edit={edit}
        enablePreview={enablePreview}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomizedUpload;
