import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import CustomButton from 'components/custom-button';
import PageTitle from 'components/page-title';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import NotificationService from 'service/notifications';
import { DataResponseList } from 'types/common';
import { NotificationDataRes } from 'types/notifications';
import { shortenName } from 'utils/short-name';
import ModalCreateEditNoti from './components/ModalCreateEditNoti';
import ModalViewNoti from './components/ModalViewNoti';
import styles from './styles.module.scss';
import { JP_DATE_FORMAT } from 'constants/common';
const { Option } = Select;
// table code start
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center' as AlignType,
    width: '10%',
  },
  {
    title: 'タイトル',
    dataIndex: 'title',
    key: 'title',
    align: 'center' as AlignType,
    width: '20%',
  },
  {
    title: '画像',
    dataIndex: 'image',
    key: 'image',
    align: 'center' as AlignType,
    width: '15%',
  },
  {
    title: '説明文',
    dataIndex: 'description',
    key: 'description',
    align: 'center' as AlignType,
    width: '20%',
  },
  {
    title: '状態',
    key: 'status',
    dataIndex: 'status',
    align: 'center' as AlignType,
    width: '10%',
  },
  {
    title: '更新日付',
    key: 'time',
    dataIndex: 'time',
    align: 'center' as AlignType,
    width: '15%',
  },
  {
    title: 'アクション',
    key: 'action',
    dataIndex: 'action',
    align: 'center' as AlignType,
    width: '15%',
  },
];

export enum StatusNoti {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

const LIMIT = 10;
interface FilterValue {
  title: string;
  description: string;
  status: Array<string>;
  [propName: string]: string | Array<string>;
}
const initFilterValue = {
  title: '',
  description: '',
  status: [],
};

export default function NotificationsManagement() {
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<FilterValue>(initFilterValue);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [isModalViewNotiOpen, setIsModalViewNotiOpen] = useState(false);
  const [isModalUnPublishNotiOpen, setIsModalUnPublishNotiOpen] = useState(false);
  const [valueNotiSelected, setValueNotiSelected] = useState<NotificationDataRes | null>(null);
  const [idNotiSelected, setIdNotiSelected] = useState<string | number | null>(null);
  const [statusUpdateData, setStatusUpdateData] = useState(false);

  const renderItemDataTable = useCallback((item: NotificationDataRes, index: number) => {
    return {
      key: index,
      id: <>{item?.id}</>,
      title: (
        <Tooltip title={item?.title}>
          <span>{shortenName(item?.title || '', 40) || ''}</span>
        </Tooltip>
      ),
      image: (
        <>
          {item?.bannerObjectKey && (
            <img
              width={136}
              height={72}
              style={{ objectFit: 'contain' }}
              src={item?.bannerObjectKey}
              alt=""
            />
          )}
        </>
      ),
      description: <span>{shortenName(item?.description || '', 40) || ''}</span>,
      status: <span>{item.status}</span>,
      time: <>{moment(item?.updatedDate).format(JP_DATE_FORMAT)}</>,
      action: (
        <>
          {item?.status === StatusNoti.DRAFT ? (
            <Select
              value={'アクション'}
              className={styles.action}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Option value={'Action1'}>
                <div
                  onClick={() => handelOpenViewNotiModal(item?.id)}
                  style={{ textAlign: 'center' }}
                >
                  表示
                </div>
              </Option>
              <Option value={'Action2'}>
                <div
                  onClick={() => handelOpenCreateEditModal(item)}
                  style={{ textAlign: 'center' }}
                >
                  変更
                </div>
              </Option>
              <Option value={'Action3'}>
                <div onClick={() => handelOpenDeleteModal(item)} style={{ textAlign: 'center' }}>
                  削除
                </div>
              </Option>
              <Option value={'Action4'}>
                <div
                  onClick={() => handelOpenViewNotiModal(item?.id)}
                  style={{ textAlign: 'center' }}
                >
                  公開
                </div>
              </Option>
            </Select>
          ) : (
            <Select
              value={'アクション'}
              className={styles.action}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Option value={'Action1'}>
                <div
                  onClick={() => handelOpenViewNotiModal(item?.id)}
                  style={{ textAlign: 'center' }}
                >
                  表示
                </div>
              </Option>
              <Option value={'Action2'}>
                <div
                  onClick={() => handelOpenUnPublishNotiModal(item)}
                  style={{ textAlign: 'center' }}
                >
                  未公開
                </div>
              </Option>
            </Select>
          )}
        </>
      ),
    };
  }, []);

  const getData = useCallback(
    async (filters: FilterValue) => {
      setLoading(true);
      try {
        const dataRequest = {
          page: currentPage,
          limit: LIMIT,
          sortField: 'created_at',
          desc: true,
          ...filters,
        };
        const data: DataResponseList<NotificationDataRes> =
          await NotificationService.getListNotifications(dataRequest);
        const dataTable = data?.items?.map(renderItemDataTable);

        setDataTable(dataTable);
        setTotalItem(data?.totalCount);
      } catch (error) {
        console.error('Error get data list notifications', JSON.stringify(error));
      }
      setLoading(false);
    },
    [currentPage, renderItemDataTable]
  );
  const handleChangePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterClone = { ...filter };
    filterClone[e.target.name] = e.target.value;
    setCurrentPage(1);
    setFilter(filterClone);
  };

  useEffect(() => {
    getData(filter);
  }, [getData, statusUpdateData]);

  const handelOpenCreateEditModal = (value?: NotificationDataRes) => {
    setIsOpenModalEditAndCreate(true);
    if (value?.type !== 'click') setValueNotiSelected(value as NotificationDataRes);
    setIsModalViewNotiOpen(false);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setValueNotiSelected(null);
  };

  const handelOpenDeleteModal = (item: NotificationDataRes | null) => {
    setIsModalDeleteOpen(true);
    setValueNotiSelected(item);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await NotificationService.deleteNotification(valueNotiSelected?.id);
    } catch (error) {
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
      setLoading(false);
    } finally {
      setLoading(false);
      handleCancelDeleteModal();
      onChangeStatusUpdateData();
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
    setValueNotiSelected(null);
  };

  const handelOpenViewNotiModal = (idItem: number | string | null) => {
    setIsModalViewNotiOpen(true);
    setIsOpenModalEditAndCreate(false);
    setIdNotiSelected(idItem);
  };
  const handleCancelViewNotieModal = () => {
    setIsModalViewNotiOpen(false);
    setValueNotiSelected(null);
    setIdNotiSelected(null);
  };

  const handelOpenUnPublishNotiModal = (item: NotificationDataRes | null) => {
    setIsModalUnPublishNotiOpen(true);
    setValueNotiSelected(item);
  };

  const handleCancelUnPublishNotiModal = () => {
    setIsModalUnPublishNotiOpen(false);
    setValueNotiSelected(null);
  };

  const handelSetValueNotiViewModal = (value: any) => {
    setValueNotiSelected(value);
  };

  const handleOkUnPublishNotiModal = async () => {
    setLoading(true);
    try {
      await NotificationService.unpublishNotification(valueNotiSelected?.id);
    } catch (error) {      
      console.error(`Error publish notification`, JSON.stringify(error));
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
      setLoading(false);
    } finally {
      setLoading(false);
      handleCancelUnPublishNotiModal();
      onChangeStatusUpdateData();
    }
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const onClearFilter = () => {
    setCurrentPage(1);
    setFilter(initFilterValue);
    onChangeStatusUpdateData();
  };
  return (
    <>
      <PageTitle title="お知らせ一覧" />
      <div className="custom-table">
        <Row gutter={[24, 0]} className="custom-form">
          <Col xs="24" xl={24} md={24}>
            <Card bordered={false} style={{ padding: 0 }}>
              <div>
                <Row gutter={15}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="タイトル">
                      <Input
                        name="title"
                        value={filter.title}
                        onChange={handleFilter}
                        // placeholder="タイトルで検索"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="説明文">
                      <Input
                        name="description"
                        value={filter.description}
                        onChange={handleFilter}
                        // placeholder="Search by description"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="状態">
                      <Select
                        value={filter.status}
                        onChange={(value) => {
                          const filterClone = { ...filter };
                          filterClone['status'] = value;
                          setFilter(filterClone);
                          setCurrentPage(1);
                        }}
                      >
                        <Option value="">全て</Option>
                        <Option value="PUBLISHED">公開中</Option>
                        <Option value="DRAFT">下書き</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <CustomButton
                      className={styles.btnFilter}
                      bgColor="secondary-green"
                      htmlType="submit"
                      text=" 検索"
                      icon={<SearchOutlined />}
                      onClick={onChangeStatusUpdateData}
                    />
                    <CustomButton
                      text="クリア"
                      bgColor="primary-grey"
                      icon={<CloseOutlined />}
                      className="ml-5"
                      onClick={onClearFilter}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <div className="mb-10 mt-10">
                  <CustomButton
                    onClick={() =>
                      handelOpenCreateEditModal(valueNotiSelected as NotificationDataRes)
                    }
                    bgColor="primary-green"
                    text="新規登録"
                    icon={<PlusOutlined />}
                  />
                </div>
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      description="通知を見つけません。「通知新規」ボタンをクリックして通知を作成してください。"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                >
                  <Table
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={loading}
                  />
                </ConfigProvider>
                {totalItem > LIMIT && (
                  <Pagination
                    showSizeChanger={false}
                    current={currentPage}
                    defaultPageSize={LIMIT}
                    onChange={handleChangePage}
                    showLessItems
                    total={totalItem}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isOpenModalEditAndCreate && (
        <ModalCreateEditNoti
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          handelOpenViewNotiModal={handelOpenViewNotiModal}
          valueNotiSelected={valueNotiSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      {isModalViewNotiOpen && (
        <ModalViewNoti
          isModalViewNotiOpen={isModalViewNotiOpen}
          idNotiSelected={idNotiSelected}
          handleCancelViewNotieModal={handleCancelViewNotieModal}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
          handelOpenCreateEditModal={handelOpenCreateEditModal}
          handelSetValueNotiViewModal={handelSetValueNotiViewModal}
        />
      )}
      <Modal
        title="通知を削除"
        open={isModalDeleteOpen}
        footer={false}
        onCancel={handleCancelDeleteModal}
      >
        <p style={{ textAlign: 'center' }}> 本当にこの通知を非公開してよろしいですか？</p>
        <div className="d-flex justify-center" style={{ marginTop: 30 }}>
          <Space size="large">
            <CustomButton bgColor="primary-red" text="OK" onClick={handleOkDeleteModal} />
            <CustomButton
              text="キャンセル"
              bgColor="primary-grey"
              htmlType="button"
              onClick={handleCancelDeleteModal}
            />
          </Space>
        </div>
      </Modal>

      <Modal
        title="非公開通知"
        open={isModalUnPublishNotiOpen}
        footer={false}
        onCancel={handleCancelUnPublishNotiModal}
      >
        <p style={{ textAlign: 'center' }}>本当にこの通知を非公開してよろしいですか?</p>
        <div className="d-flex justify-center" style={{ marginTop: 30 }}>
          <Space size="large">
            <CustomButton bgColor="primary-red" text="保存" onClick={handleOkUnPublishNotiModal} />
            <CustomButton
              text="キャンセル"
              bgColor="primary-grey"
              htmlType="button"
              onClick={handleCancelUnPublishNotiModal}
            />
          </Space>
        </div>
      </Modal>
    </>
  );
}
