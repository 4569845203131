import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import styles from '../style.module.scss';

interface IProps {
  loadingCreate: boolean;
  loadingPresign: boolean;
  loadingUpload: boolean;
  loadingSaveDb: boolean;
}

const CreateLoading = ({ loadingCreate, loadingPresign, loadingUpload, loadingSaveDb }: IProps) => {
  return (
    <div>
      <div>
        <Row gutter={[24, 0]}>
          <Col xs={4} xl={4} md={4}>
            {loadingCreate ? (
              <Spin indicator={<LoadingOutlined />} className="mr-10" />
            ) : (
              <CheckOutlined className="mr-10" />
            )}
          </Col>
          <Col xs={20} xl={20} md={20} className={styles.loadingContent}>
            <span>一次NFT作成（3D）</span>
          </Col>
        </Row>
        <Row gutter={[24, 0]} className="mt-10">
          <Col xs={4} xl={4} md={4}>
            {loadingPresign ? (
              <Spin indicator={<LoadingOutlined />} className="mr-10" />
            ) : (
              <CheckOutlined className="mr-10" />
            )}
          </Col>
          <Col xs={20} xl={20} md={20} className={styles.loadingContent}>
            <span>署名前URLの作成</span>
          </Col>
        </Row>
        <Row gutter={[24, 0]} className="mt-10">
          <Col xs={4} xl={4} md={4}>
            {loadingUpload ? (
              <Spin indicator={<LoadingOutlined />} className="mr-10" />
            ) : (
              <CheckOutlined className="mr-10" />
            )}
          </Col>
          <Col xs={20} xl={20} md={20} className={styles.loadingContent}>
            <span>S3へのアップロード</span>
          </Col>
        </Row>

        <Row gutter={[24, 0]} className="mt-10">
          <Col xs={4} xl={4} md={4}>
            {loadingSaveDb ? (
              <Spin indicator={<LoadingOutlined />} className="mr-10" />
            ) : (
              <CheckOutlined className="mr-10" />
            )}
          </Col>
          <Col xs={20} xl={20} md={20} className={styles.loadingContent}>
            <span>データベース保存</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CreateLoading;
