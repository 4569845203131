import { Modal, notification } from 'antd';
import CustomButton from 'components/custom-button';
import { useState } from 'react';
import nftService from 'service/nft-service';
import styles from '../style.module.scss';

const Active1stModal = ({
  id,
  clearFilter,
  handleCloseActivePopup,
  active,
  deactive,
  title
}) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleSubmitDeactive1stNft = async (id: number) => {
    setButtonLoading(true);
    const res: any[] = await nftService.deactive1stNFT(id);
    setButtonLoading(false);
    handleCloseActivePopup();
    clearFilter();
  };

  const handleSubmitActive1stNft = async (id: number) => {
    setButtonLoading(true);
    const res: any[] = await nftService.active1stNFT(id);
    setButtonLoading(false);
    handleCloseActivePopup();
    clearFilter();
  };

  
  return (
    <div>
      <Modal
        forceRender
        open={true}
        closable={false}
        footer={false}
        width={800}
        className="modal-custom"
        title={title}
      >
        <div className={styles.buttonSubmit}>
          {!active && deactive && (
            <CustomButton
              text="Submit"
              bgColor="primary-green"
              onClick={() => handleSubmitDeactive1stNft(id)}
              color="white"
              fontWeight="700"
            />
          )}

          {!deactive && active && (
            <CustomButton
              text="Submit"
              bgColor="primary-green"
              onClick={() => handleSubmitActive1stNft(id)}
              color="white"
              fontWeight="700"
            />
          )}

          <CustomButton
            text="Close"
            bgColor="primary-grey"
            onClick={() => handleCloseActivePopup()}
            color="black"
            fontWeight="700"
            loading={buttonLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Active1stModal;
