import {  getYearsSuccess, getYearsFailure } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { AppDispatch } from '../../types/redux';
import { YearType } from 'types/common';

export const getListYear= () => async (dispatch: AppDispatch) => {
  try {
    const res: {data: YearType[]} = await AxiosUtils.get(API.registerListYear);
    dispatch(getYearsSuccess({data: res.data, key: "yearsRegister"}));
  } catch (error: any) {
    dispatch(getYearsFailure(error));
  }
  try {
    const res: {data: YearType[]} = await AxiosUtils.get(API.transactionListYear);
    dispatch(getYearsSuccess({data: res.data, key: "yearsTransaction"}));
  } catch (error: any) {
    dispatch(getYearsFailure(error));
  }
};