import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import CustomButton from 'components/custom-button';
import PageTitle from 'components/page-title';
import { ADMIN_ROLE, JP_DATE_FORMAT } from 'constants/common';
import _ from 'lodash';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import AdminService from 'service/admin-service';
import { AdminDataRes, DataRequestList } from 'types/admin';
import { DataResponseList, DataResponseListSecond } from 'types/common';
import { ellipsisMiddle, shortenName } from 'utils/short-name';
import ModalCreateEditAdmin from './components/ModalCreateEditAdmin';
import styles from './styles.module.scss';
const { Option } = Select;
// table code start
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center' as AlignType,
  },
  {
    title: '権限',
    dataIndex: 'role',
    key: 'role',
    align: 'center' as AlignType,
  },
  {
    title: '名前',
    dataIndex: 'name',
    key: 'name',
    align: 'center' as AlignType,
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
    key: 'email',
    align: 'center' as AlignType,
  },
  {
    title: 'Wallet アドレス',
    key: 'walletAddress',
    dataIndex: 'walletAddress',
    align: 'center' as AlignType,
  },
  {
    title: '更新日付',
    key: 'updatedDate',
    dataIndex: 'updatedDate',
    align: 'center' as AlignType,
  },
  {
    title: 'アクション',
    key: 'action',
    dataIndex: 'action',
    align: 'center' as AlignType,
  },
];

interface FilterData {
  name: string;
  walletAddress: string;
  email: string;
  roles: string;
}

const LIMIT = 10;
const initFilterValue: FilterData = {
  name: '',
  walletAddress: '',
  email: '',
  roles: '',
};

export default function AdminManagement() {
  const [dataTable, setDataTable] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<FilterData>(initFilterValue);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueAdminSelected, setValueAdminSelected] = useState<AdminDataRes | null>(null);
  const [statusUpdateData, setStatusUpdateData] = useState(false);

  const renderItemDataTable = useCallback((item: AdminDataRes, index: number) => {
    return {
      id: <>{item.id}</>,
      key: index,
      role: <p>{item.role}</p>,
      name: <Tooltip title={item?.name}>{shortenName(item.name, 20) || ''}</Tooltip>,
      email: <Tooltip title={item?.email}>{shortenName(item.email || '', 20) || ''}</Tooltip>,
      walletAddress: (
        <Tooltip title={item?.walletAddress}>{ellipsisMiddle(item.walletAddress, 6, 4)}</Tooltip>
      ),
      updatedDate: <>{moment(item?.updatedDate).format(JP_DATE_FORMAT)}</>,
      action: (
        <>
          <Select value={'アクション'} className={styles.action}>
            <Option value={'Action1'}>
              <div onClick={() => handelOpenCreateEditModal(item)} style={{ textAlign: 'center' }}>
                変更
              </div>
            </Option>
            <Option value={'Action2'}>
              <div onClick={() => handelOpenDeleteModal(item)} style={{ textAlign: 'center' }}>
                削除
              </div>
            </Option>
          </Select>
        </>
      ),
    };
  }, []);

  const getData = useCallback(
    async (filters?: FilterData) => {
      setLoading(true);
      try {
        const dataRequest: DataRequestList = {
          limit: LIMIT,
          page: currentPage,
          ...filters,
          sortField: 'created_at',
          desc: true,
        };

        const response: DataResponseList<AdminDataRes> = await AdminService.getListAdmins(
          dataRequest
        );
        const dataTable = response?.items?.map(renderItemDataTable);
        setDataTable(dataTable);
        setTotalItem(response?.totalCount);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, renderItemDataTable]
  );

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterClone = { ...filter };
    filterClone[e.target.name] = e.target.value;
    setCurrentPage(1);
    setFilter(filterClone);
  };

  const onClearFilter = () => {
    setCurrentPage(1);
    setFilter(initFilterValue);
    onChangeStatusUpdateData();
  };

  useEffect(() => {
    if (_.isEqual(filter, initFilterValue)) {
      getData();
    } else {
      getData({
        ...filter,
      });
    }
  }, [getData, statusUpdateData]);

  const handelOpenCreateEditModal = (value: AdminDataRes | null) => {
    setIsOpenModalEditAndCreate(true);
    setValueAdminSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setValueAdminSelected(null);
  };

  const handelOpenDeleteModal = (value: AdminDataRes | null) => {
    setIsModalDeleteOpen(true);
    setValueAdminSelected(value);
  };

  const handelDeleteAdmin = async (id?: string) => {
    setLoading(true);
    try {
      await AdminService.deleteAdmin(id);
    } catch (error) {
      console.error(`Error delete admin`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: 'ログイン中の管理者アカウントは削除できませんスーパー」ではなく',
        duration: 4,
      });
      setLoading(false);
    } finally {
      setLoading(false);
      handleCancelDeleteModal();
      onChangeStatusUpdateData();
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
    setValueAdminSelected(null);
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);
  return (
    <>
      <PageTitle title="管理者一覧" />
      <div className="custom-table">
        <Row gutter={[24, 0]} className="custom-form">
          <Col xs="24" xl={24} md={24}>
            <Card bordered={false} style={{ padding: 0 }}>
              <div>
                <Row gutter={15}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="名前">
                      <Input
                        name="name"
                        value={filter.name}
                        onChange={onChangeFilter}
                        placeholder="名前で検索"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="メールアドレス">
                      <Input
                        name="email"
                        value={filter.email}
                        onChange={onChangeFilter}
                        placeholder="メールで検索"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="Wallet アドレス">
                      <Input
                        name="walletAddress"
                        value={filter.walletAddress}
                        onChange={onChangeFilter}
                        placeholder="Walletアドレスで検索"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item label="権限">
                      <Select
                        value={filter.roles}
                        onChange={(value) => {
                          const filterClone = { ...filter };
                          filterClone['roles'] = value;
                          setFilter(filterClone);
                          setCurrentPage(1);
                        }}
                      >
                        <Option value="">全て</Option>
                        <Option value={ADMIN_ROLE.MASTER_ADMIN}>Master Admin</Option>
                        <Option value={ADMIN_ROLE.UPBOND_ADMIN}>Upbond</Option>
                        <Option value={ADMIN_ROLE.MEGAHOUSE_ADMIN}>Mega House</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'} className="text-right mt-10">
                  <CustomButton
                    bgColor="secondary-green"
                    htmlType="submit"
                    text=" 検索"
                    icon={<SearchOutlined />}
                    onClick={onChangeStatusUpdateData}
                  />
                  <CustomButton
                    text="クリア"
                    bgColor="primary-grey"
                    icon={<CloseOutlined />}
                    className="ml-5"
                    onClick={onClearFilter}
                  />
                </Row>
              </div>
              <div>
                <div className="mb-10">
                  <CustomButton
                    onClick={() => handelOpenCreateEditModal(valueAdminSelected)}
                    bgColor="primary-green"
                    text="新規登録"
                    icon={<PlusOutlined />}
                  />
                </div>
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      description="対象データが見つかりません。"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                >
                  <Table
                    locale={{ emptyText: '対象データが見つかりません。' }}
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={loading}
                  />
                </ConfigProvider>
                {totalItem > LIMIT && (
                  <Pagination
                    showSizeChanger={false}
                    current={currentPage}
                    defaultPageSize={LIMIT}
                    onChange={handleChangePage}
                    showLessItems
                    total={totalItem}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isOpenModalEditAndCreate && (
        <ModalCreateEditAdmin
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueAdminSelected={valueAdminSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="管理者情報を削除"
        open={isModalDeleteOpen}
        onCancel={handleCancelDeleteModal}
        footer={false}
      >
        <p style={{ textAlign: 'center' }}>本当に管理者のアカウントを削除してよろしいですか？</p>
        <div className="d-flex justify-center" style={{ marginTop: 30 }}>
          <Space size="large">
            <CustomButton
              bgColor="primary-red"
              text="削除"
              loading={loading}
              onClick={() => handelDeleteAdmin(valueAdminSelected?.id)}
            />
            <CustomButton
              text="キャンセル"
              bgColor="primary-grey"
              htmlType="button"
              onClick={handleCancelDeleteModal}
            />
          </Space>
        </div>
      </Modal>
    </>
  );
}
