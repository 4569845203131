import './styles.scss';
import '@google/model-viewer';

const ModelViewer = (props) => {
  const { src, width, height, style, background } = props;
  return (
    <model-viewer
      src={src}
      ar
      ar-modes="webxr scene-viewer quick-look"
      camera-controls
      shadow-intensity="1"
      autoplay
      width="100"
      style={{ width: width, height: height, backgroundColor: background || 'white', borderRadius: 5 }}
      rest
    ></model-viewer>
  );
};

export default ModelViewer;
