import { Col, Form, Input, Modal, notification, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import SelectAttribute from 'components/select-attributes';
import { FILE_TYPE, LIMIT, NFT_ATTRIBUTES, NFT_STATUS, UPLOAD_TYPE } from 'constants/common';
import { useCallback, useEffect, useState } from 'react';
import nftService from 'service/nft-service';
import { CHECK_FILE, REQUIRED_MINT, REQUIRED_TITLE_MINT } from 'utils/rule-form';
import socket from 'utils/socket';
import styles from '../style.module.scss';
import CreateLoading from './create-loading';
import Loading from 'components/loading';

interface IProps {
  id?: number | string;
  handleMintPopUp: (id: number | string, status: boolean, isMint?: boolean) => void;
  handelCloseUpdatePoup: () => void;
  edit?: boolean;
  view?: boolean;
  updateNft?: boolean;
  clearFilter: () => void;
  title: string;
}

const Update1stModal = ({
  id,
  handleMintPopUp,
  edit,
  handelCloseUpdatePoup,
  view,
  clearFilter,
  updateNft,
  title,
}: IProps) => {
  const [form] = Form.useForm();

  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(true);
  const [loadingPresign, setLoadingPresign] = useState<boolean>(true);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(true);
  const [loadingSaveDb, setLoadingSaveDb] = useState<boolean>(true);

  const [nftCreated, setNftCreated] = useState<any>();

  const [uploadPreviewStatus, setUploadPreviewStatus] = useState<any>(false);
  const [uploadModalStatus, setUploadModalStatus] = useState<any>(false);
  const [upload2DStatus, setUpload2DStatus] = useState<any>(false);

  const [dataPreview, setDataPreview] = useState<any>(null);
  const [preview2DId, setPreview2DId] = useState<any>();
  const [dataImageId, setDataImageId] = useState<any>();
  const [guideId, setGuideId] = useState<any>();

  const [isCreateStatus, setIsCreateStatus] = useState<boolean>(false);
  const [isMintAction, setIsMintAction] = useState<boolean>(false);

  const resetState = () => {
    setLoadingCreate(true);
    setLoadingPresign(true);
    setLoadingUpload(true);
    setLoadingSaveDb(true);
    // setNftCreated(null);
    setLoadingModal(false);
  };

  const resetLoading = () => {
    setLoadingCreate(true);
    setLoadingPresign(true);
    setLoadingUpload(true);
    setLoadingSaveDb(true);
    setUploadPreviewStatus(false);
    setUpload2DStatus(false);
    setUploadModalStatus(false);
  };

  const errorNoti = (content: string) => {
    notification.error({
      message: 'Error',
      description: content,
      duration: 4,
    });
    resetState();
  };

  const handleUploadS3 = async (
    preview2D: any,
    data: any,
    guide: any,
    filePreview2D: any,
    fileData: any,
    fileGuide: any
  ) => {
    setPreview2DId(preview2D?.data?.imageId);
    setDataImageId(data?.data?.imageId);
    setGuideId(guide?.data?.imageId);
    Promise.allSettled([
      filePreview2D &&
        !(typeof filePreview2D === 'string') &&
        nftService.uploadFileS3(preview2D?.data?.url, filePreview2D, preview2D?.data?.headers),
      fileData &&
        !(typeof fileData === 'string') &&
        nftService.uploadFileS3(data?.data?.url, fileData, data?.data?.headers),
      fileGuide &&
        !(typeof fileGuide === 'string') &&
        nftService.uploadFileS3(guide?.data?.url, fileGuide, guide?.data?.headers),
    ]).then(function (valuesS3: any[]) {
      const itemReject = valuesS3?.findIndex((item: any) => item.status === 'rejected');
      if (itemReject !== -1) {
        errorNoti('Upload S3 error, please try again');
      } else {
        const valuePreview2D = valuesS3[0].value;
        const valueDataImage = valuesS3[1].value;
        const valueGuide = valuesS3[2].value;
        !valueDataImage && setUploadPreviewStatus(true);
        !valueGuide && setUploadModalStatus(true);
        !valuePreview2D && setUpload2DStatus(true);
        setLoadingUpload(false);
      }
    });
  };

  const handlePresign = async (value: any, result: any) => {
    const filePreview2D = value.preview2dImage?.file;
    const fileData = value.dataImage?.file;
    const fileGuide = value.dataGuide?.file;
    Promise.allSettled([
      filePreview2D &&
        !(typeof filePreview2D === 'string') &&
        nftService.createPresign2d({
          type: FILE_TYPE.PREVIEW2D,
          name: value.preview2dImage?.fileKey || '',
          nftId: result.nftId,
        }),
      fileData &&
        !(typeof fileData === 'string') &&
        nftService.createPresign2d({
          type: FILE_TYPE.PREVIEW,
          name: value.dataImage?.fileKey || '',
          nftId: result.nftId,
        }),
      fileGuide &&
        !(typeof fileGuide === 'string') &&
        nftService.createPresign2d({
          type: FILE_TYPE.MODEL,
          name: value.dataGuide?.fileKey || '',
          nftId: result.nftId,
        }),
    ]).then(function (values: any[]) {
      const itemReject = values?.findIndex((item: any) => item.status === 'rejected');
      if (itemReject !== -1) {
        errorNoti('Presign error, please try again');
      } else {
        setLoadingPresign(false);
        const preview2D = values?.[0]?.value;
        const data = values?.[1]?.value;
        const guide = values?.[2]?.value;
        handleUploadS3(preview2D, data, guide, filePreview2D, fileData, fileGuide);
      }
    });
  };

  const handleCreate1stNft = async (value: any, status: string, update?: boolean) => {
    setIsCreateStatus(status === '1' ? true : false);
    setLoadingModal(true);
    const { characterId, title, description } = value;
    const params = {
      ...(characterId && { characterId }),
      ...(title && { title }),
      ...(description && { description }),
      ...(status && { status }),
    };

    const [result, error]: any = update
      ? await nftService.update2dNft(nftCreated?.id, params)
      : await nftService.create2dNft(params);
    if (result) {
      const nftDetail = update ? { ...nftCreated, ...{ params } } : result;
      setNftCreated(nftDetail);
      setLoadingCreate(false);
      handlePresign(value, nftDetail);
    } else {
      errorNoti(error?.message);
    }
  };

  const getDetail = useCallback(
    async (id: string | number) => {
      setLoadingDetail(true);
      const result: any = await nftService.getDetail1st2dNft(id);
      if (result) {
        setNftCreated(result);
        const dataPreviewResult = result?.images.find(
          (item: any) => item.type === FILE_TYPE.PREVIEW2D
        );
        setDataPreview({
          ...result,
          name: dataPreviewResult?.name,
          file: dataPreviewResult?.objectKey,
        });
        let dataImage: any = null;
        let fileGuide: any = null;
        let preview2dImage: any = null;
        result?.images?.forEach((item: any, index: number) => {
          if (item?.type === FILE_TYPE.PREVIEW) dataImage = item;
          if (item?.type === FILE_TYPE.MODEL) fileGuide = item;
          if (item?.type === FILE_TYPE.PREVIEW2D) preview2dImage = item;
        });
        result &&
          form.setFieldsValue({
            ...result,
            dataGuide: { file: fileGuide?.objectKey, fileKey: fileGuide?.name },
            dataImage: { file: dataImage?.objectKey, fileKey: dataImage?.name },
            preview2dImage: { file: preview2dImage?.objectKey, fileKey: preview2dImage?.name },
          });
      }
      setLoadingDetail(false);
    },
    [form]
  );

  const handleUpdateNft = () => {
    setIsMintAction(false);
    resetLoading();
    
    if (nftCreated?.status === NFT_STATUS.CREATED) {
      form
        .validateFields()
        .then((value) => {
          handleCreate1stNft(value, '1', true);
        })
        .catch();
    } else {
      const data = form.getFieldsValue();
      handleCreate1stNft(data, '0', true);
    }
  };
  const handleResetWeapon = () => {
    form.setFieldValue('listWeapons', undefined);
  };

  const renderNotifiSuccess = () => {
    if (updateNft) {
      return '一次NFTの変更が完了しました。';
    } else if (nftCreated?.status === NFT_STATUS.CREATED) {
      return '一次NFTの作成が完了しました。';
    } else return '下書き保存が完了しました。';
  };

  const handleSaveDraft = () => {
    setIsMintAction(false);
    const listFields: string[] = [
      'characterId',
      'preview2dImage',
      'dataImage',
      'dataGuide',
      'title',
      'description',
      'positionId',
      'listWeapons',
    ];
    form.setFields(
      listFields.map((item: string) => {
        return {
          name: item,
          errors: [],
        };
      })
    );
    form.validateFields(['title']).then(() => {
      const value = form.getFieldsValue();
      handleCreate1stNft(value, '0');
    });
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [form, id, getDetail]);

  useEffect((): any => {
    socket.on('UPLOAD_FIRST_NFT_SIMPLE_MODEL_3D_SUCCESS', (message: any) => {
      if (message.nftId === nftCreated?.nftId && message.imageId === guideId) {
        setUploadModalStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_FIRST_NFT_SIMPLE_MODEL_3D_SUCCESS');
  }, [nftCreated, guideId]);

  useEffect((): any => {
    socket.on('UPLOAD_FIRST_NFT_SIMPLE_PREVIEW_SUCCESS', (message: any) => {
      if (message?.nftId === nftCreated?.nftId && message.imageId === dataImageId) {
        setUploadPreviewStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_FIRST_NFT_SIMPLE_PREVIEW_SUCCESS');
  }, [nftCreated, dataImageId]);

  useEffect((): any => {
    socket.on('UPLOAD_FIRST_NFT_SIMPLE_PREVIEW2D_SUCCESS', (message: any) => {
      if (message?.nftId === nftCreated?.nftId && message.imageId === preview2DId) {
        setUpload2DStatus(true);
      }
    });

    if (socket) return () => socket.off('UPLOAD_FIRST_NFT_SIMPLE_PREVIEW2D_SUCCESS');
  }, [nftCreated, preview2DId]);

  useEffect(() => {
    if (uploadPreviewStatus && uploadModalStatus && upload2DStatus) {
      setLoadingSaveDb(false);
      setLoadingModal(false);
      clearFilter();
      handleMintPopUp(nftCreated?.id, isCreateStatus, isMintAction);
      notification.success({
        message: 'Success',
        description: renderNotifiSuccess(),
        duration: 4,
      });
    }
  }, [uploadPreviewStatus, uploadModalStatus, upload2DStatus, nftCreated, updateNft, isMintAction]);

  return (
    <Modal
      forceRender
      title={title}
      open={true}
      footer={false}
      closable={false}
      width={900}
      style={{ top: 20 }}
    >
      {loadingDetail ? (
        <Loading />
      ) : (
        <Form form={form}>
          <Row gutter={30} className="custom-form update-modal-form">
            <Col span={12}>
              <Form.Item
                name="characterId"
                label="キャラクター"
                rules={[{ required: true, message: 'キャラクターが必須項目です。' }]}
              >
                <SelectAttribute
                  name={NFT_ATTRIBUTES.CHARACTER}
                  limit={LIMIT}
                  disabled={view}
                  onChange={handleResetWeapon}
                />
              </Form.Item>
              <Form.Item
                className={styles.imageUpload}
                name="preview2dImage"
                label="プレビューイメージ(2D)"
                rules={[CHECK_FILE('プレビューイメージ')]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.FILE2D}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={true}
                  disabled={view}
                  onChange={(data: any) => {
                    setDataPreview({
                      ...dataPreview,
                      name: data.fileKey,
                      file: URL.createObjectURL(data.file),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="dataImage"
                label="イラストデータ（PNG, JPG, GIF)"
                rules={[CHECK_FILE(' イラストデータ')]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.FILE2D}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={false}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item
                name="dataGuide"
                label="イラストガイド"
                rules={[CHECK_FILE('イラストガイド')]}
              >
                <CustomizedUpload
                  type={UPLOAD_TYPE.GUIDE}
                  maxFileSize={5000}
                  edit={edit}
                  enablePreview={false}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item
                label="タイトル"
                name="title"
                rules={[{ required: true, message: 'タイトルは必須項目です。' }]}
              >
                <Input
                  maxLength={150}
                  placeholder="タイプ"
                  onChange={(e) => setDataPreview({ ...dataPreview, title: e?.target?.value })}
                  disabled={view}
                />
              </Form.Item>
              <Form.Item label="説明文" name="description">
                <TextArea
                  maxLength={750}
                  placeholder="説明を入力"
                  rows={3}
                  onChange={(e) =>
                    setDataPreview({ ...dataPreview, description: e?.target?.value })
                  }
                  disabled={view}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div className={styles.nftPreview}>
                <p className="text-700 mb-0">NFT プレビュー</p>
                <div className={styles.nftPreviewDetail}>
                  {dataPreview?.file ? (
                    <img
                      src={dataPreview?.file}
                      style={{
                        width: 360,
                        height: 350,
                        objectFit: 'contain',
                        backgroundColor: '#f3f4f4',
                      }}
                      alt="image2d"
                    />
                  ) : (
                    <div className={styles.nftPreviewTextDefaut}>2d プレビュー画像表示</div>
                  )}
                  {dataPreview?.title && <p className={styles.title}>{dataPreview?.title}</p>}
                  {dataPreview?.description && (
                    <p className={styles.description}>{dataPreview?.description}</p>
                  )}
                  {[NFT_STATUS.MINTED, NFT_STATUS.SELLING].includes(nftCreated?.status) && (
                    <p className="mb-5 text-400">数量: {dataPreview?.quantity}</p>
                  )}
                  {[NFT_STATUS.SELLING].includes(nftCreated?.status) && (
                    <p className="mb-5 text-400">価格: {dataPreview?.price}</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className={styles.buttonSubmit}>
            {!view && !updateNft && (
              <CustomButton
                onClick={handleSaveDraft}
                text="下書保存"
                bgColor="third-green"
                color="#18C3AF"
                fontWeight="700"
              />
            )}
            {!view && ([NFT_STATUS.DRAFT].includes(nftCreated?.status) || !nftCreated) && (
              <CustomButton
                onClick={() =>
                  form
                    .validateFields()
                    .then((value) => {
                      const isUpdate = nftCreated?.status === NFT_STATUS.DRAFT;
                      setIsMintAction(true);
                      handleCreate1stNft(value, '1', isUpdate);
                    })
                    .catch()
                }
                text="保存＆ミントへ"
                bgColor="primary-green"
                color="white"
                fontWeight="700"
              />
            )}
            {!view && updateNft && (
              <CustomButton
                onClick={handleUpdateNft}
                text="変更"
                bgColor="primary-green"
                color="white"
                fontWeight="700"
              />
            )}
            <CustomButton
              onClick={handelCloseUpdatePoup}
              text="キャンセル"
              bgColor="primary-grey"
              color="black"
              fontWeight="700"
            />
          </div>

          <Modal
            title="一次NFT作成（イラスト）"
            open={loadingModal}
            closable={false}
            footer={false}
            width={300}
            style={{ marginTop: 200 }}
          >
            <CreateLoading
              loadingCreate={loadingCreate}
              loadingPresign={loadingPresign}
              loadingUpload={loadingUpload}
              loadingSaveDb={loadingSaveDb}
            />
          </Modal>
        </Form>
      )}
    </Modal>
  );
};
export default Update1stModal;
