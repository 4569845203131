import { Checkbox, Form, Input, Modal, notification, Select, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { useState } from 'react';
import SystemService from 'service/system-config';
import { IDataAddWalletConfig } from 'types/admin';
import styles from '../styles.module.scss';
const { Option } = Select;
interface Props {
  isOpen: boolean;
  handleCloseModalAddWallet: () => void;
  onChangeStatusUpdateData: () => void;
  // valueAdminSelected: any;
}

const ModalAddWalletConfig = ({
  isOpen,
  handleCloseModalAddWallet,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: IDataAddWalletConfig) => {
    if (values?.isMinted || values?.isBurned) {
      setLoading(true);
      const [res, error]: any = await SystemService.addWalletConfig(values).finally(() => {
        setLoading(false);
      });
      if (res?.status === 201) {
        handleCloseModalAddWallet();
        onChangeStatusUpdateData();
      }
    } else {      
      console.error(`Error add system setting: ミント権かバーン権か権限を指定してください。`);
      notification.error({
        message: 'Error',
        description: 'ミント権かバーン権か権限を指定してください。',
        duration: 4,
      });
    }
  };

  const regexWalletAddress = /^[a-zA-Z0-9]*$/;

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'ウォレットアドレス追加'}
        onCancel={handleCloseModalAddWallet}
        width={444}
        getContainer={false}
        footer={false}
        className="form-add-wallet"
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ isMinted: true, isBurned: true }}
        >
          <div>
            <label
              className={styles.labelInputForm}
              htmlFor="walletAddress"
              style={{
                font: 'normal normal 700 14px/20px Manrope',
                color: 'rgba(66, 66, 66, 0.6)',
              }}
            >
              ウォレットアドレス:
            </label>
            <Form.Item
              name="walletAddress"
              rules={[
                { required: true, message: 'ウォレットアドレス は必須項目です。' },
                {
                  pattern: regexWalletAddress,
                  message: 'ウォレットアドレスを42文字と数字で入力してください。',
                },
                // {
                //   min: 42,
                //   max: 42,
                //   message: 'ウォレットアドレスを42文字で入力してください。',
                // },
              ]}
              wrapperCol={{ span: 30 }}
            >
              <Input size="large" style={{ borderRadius: 6, marginTop: 8 }} maxLength={42}/>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item name="isMinted" wrapperCol={{ span: 24 }} valuePropName="checked">
              <Checkbox style={{ marginRight: 10 }} />
            </Form.Item>
            <label
              className={styles.labelInputForm}
              htmlFor="mint"
              style={{ font: 'normal normal 400 16px/22px Manrope', color: '#424242' }}
            >
              ミント権
            </label>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item name="isBurned" wrapperCol={{ span: 24 }} valuePropName="checked">
              <Checkbox style={{ marginRight: 10 }} />
            </Form.Item>
            <label
              className={styles.labelInputForm}
              htmlFor="burn"
              style={{ font: 'normal normal 400 16px/22px Manrope', color: '#424242' }}
            >
              バーン権
            </label>
          </div>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="追加"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="キャンセル"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCloseModalAddWallet}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddWalletConfig;
