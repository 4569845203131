export enum StorageKey {
  AUTH = 'AUTH',
  SESSION = 'SESSION',
  LOCALE = 'LOCALE',
  CONTRACT = 'CONTRACT',
}

export class StorageUtils {
  static save(key: StorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  static get(key: StorageKey): string | null {
    return localStorage.getItem(key);
  }

  static remove(key: StorageKey): void {
    return localStorage.removeItem(key);
  }

  static getObject(key: StorageKey) {
    const value = localStorage?.getItem(key);
    if (value && value !== 'undefined') {
      const object = JSON?.parse(value);
      return object;
    }
    return null;
  }

  static saveObject<T>(key: StorageKey, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static clear(ignores: (keyof typeof StorageKey)[] = [StorageKey.CONTRACT]): void {
    if (ignores.length) {
      Object.keys(StorageKey).forEach((key) => {
        if (!ignores.includes(key as keyof typeof StorageKey)) {
          localStorage.removeItem(key);
        }
      });
      return;
    }
    localStorage.clear();
  }
}
