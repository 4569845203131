import { Form, Input } from 'antd';
import { REQUIRED, VALIDATE_PHONE_NUMBER } from 'utils/rule-form';

const UpdateProfile = () => {
  return (
    <>
      <Form.Item
        label="管理者名"
        name="name"
        rules={[
          REQUIRED('管理者名'),
          { max: 20, message: '管理者名には20文字以下ご入力ください。' },
        ]}
        required
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="電話番号"
        name="phoneNumber"
        rules={[REQUIRED('電話番号'), VALIDATE_PHONE_NUMBER]}
        required
        normalize={(value, preValue) => {
          const reg = /^[+(]?\d*[+\d-()]*$/;
          if (reg.test(value) || value === '' || value === '-') {
            return value;
          }

          return preValue;
        }}
      >
        <Input />
      </Form.Item>
      <Form.Item label="メールアドレス" name="email">
        <Input disabled />
      </Form.Item>
    </>
  );
};

export default UpdateProfile;
