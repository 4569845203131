import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsProgress,
  faCirclePlus,
  faPeopleRoof,
  faRectangleList,
  faTableColumns,
  faUserGroup,
  faMoneyBill,
  faPlusMinus,
  faPlusSquare,
  faMessage,
  faImage,
  faImages,
} from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import { ADMIN_ROLE } from 'constants/common';
import Logo from 'assets/images/logo-small.png';

const DashboardMenu = {
  to: '/',
  name: 'ダッシュボード',
  icon: faTableColumns,
};

const ContactUsMenu = {
  to: '/contact-us',
  name: 'お問い合わせ ',
  icon: faMessage,
};

const Create1stNftMenu = {
  to: '/create-1st-nft',
  name: '一次NFT管理',
  icon: faCirclePlus,
};

const Create2ndNftMenu = {
  to: '/create-2nd-nft',
  name: '二次NFT管理',
  icon: faPlusSquare,
};

const Create2ndNftMasterMenu = {
  to: '/create-2nd-nft-master',
  name: '二次NFTマスタ',
  icon: faPlusMinus,
};

const Create1st2dNftMenu = {
  to: '/create-1st-2d-nft',
  name: '一次イラストNFT管理',
  icon: faImage,
};

const Create2nd2dNftMenu = {
  to: '/create-2nd-2d-nft',
  name: '二次イラストNFT管理',
  icon: faImages,
};

const UserManagementMenu = {
  to: '/user-management',
  name: 'ユーザ ',
  icon: faUserGroup,
};

const AdminManagementMenu = {
  to: '/admin-management',
  name: '管理者',
  icon: faPeopleRoof,
};

const RevenueMenu = {
  to: '/revenue',
  name: '決済',
  icon: faMoneyBill,
};

const FaucetManagementMenu = {
  to: '/faucet-management',
  name: 'フォーセット',
  icon: faBarsProgress,
};

const NotificationsManagementMenu = {
  to: '/notifications-management',
  name: 'お知らせ ',
  icon: faRectangleList,
};

const SystemConfigurationMenu = {
  to: '/system-configuration',
  name: 'システム設定',
  icon: faCirclePlus,
};

const pathsMega = [
  DashboardMenu,
  ContactUsMenu,
  Create1stNftMenu,
  Create2ndNftMenu,
  Create2ndNftMasterMenu,
  Create1st2dNftMenu,
  Create2nd2dNftMenu,
];

const pathsUpbond = [
  DashboardMenu,
  UserManagementMenu,
  AdminManagementMenu,
  RevenueMenu,
  FaucetManagementMenu,
  NotificationsManagementMenu,
  ContactUsMenu,
];

const pathsMaster = [
  DashboardMenu,
  UserManagementMenu,
  AdminManagementMenu,
  RevenueMenu,
  FaucetManagementMenu,
  NotificationsManagementMenu,
  SystemConfigurationMenu,
  ContactUsMenu,
  Create1stNftMenu,
  Create2ndNftMenu,
  Create2ndNftMasterMenu,
  Create1st2dNftMenu,
  Create2nd2dNftMenu,
];

const Menu = () => {
  const user = useSelector(getUserDetail);
  const [menu, setMenu] = useState<any[]>([]);

  useEffect(() => {
    if (user?.role) {
      switch (user.role) {
        case ADMIN_ROLE.MEGAHOUSE_ADMIN:
          setMenu(pathsMega);
          break;
        case ADMIN_ROLE.UPBOND_ADMIN:
          setMenu(pathsUpbond);
          break;
        case ADMIN_ROLE.MASTER_ADMIN:
          setMenu(pathsMaster);
          break;
        default:
          setMenu(pathsMaster);
      }
    }
  }, [user]);

  return (
    <div className={styles.menu}>
      <div className={styles.boxLogo}>
        <img src={Logo} alt="" />
      </div>
      <Link to="/" className={styles.linkToHome}></Link>
      {menu.map((path, index) => {
        return (
          <NavLink
            className={({ isActive }) => {
              const activeStyle = isActive ? styles.nav_active : styles.nav_normal;
              return cn(activeStyle, styles.nav_item);
            }}
            to={path.to}
            key={index}
          >
            <div className={styles.item}>
              <FontAwesomeIcon icon={path.icon} size="lg" color={'white'} />
              <span className={styles.nav_name}>{path.name}</span>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Menu;
