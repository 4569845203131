import React, { useEffect, useState } from 'react';
import {
  Avatar,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Pagination,
  Select,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageTitle from '../../components/page-title';
import Filter from './component/filter';
import styles from './styles.module.scss';
import UserModal from './component/user-modal';
import { UserFilterRequest, UserListResponse, UsersType } from 'types/users';

import UserService from 'service/user-service';
import { JP_DATE_FORMAT, LIMIT } from 'constants/common';
import moment from 'moment';

export type UserManagerAction = 'view' | 'update' | 'delete' | '';

const initFilter = {
  name: '',
  email: '',
  walletAddress: '',
  isBlocked: '',
  limit: LIMIT,
  page: 1,
};

const { Option } = Select;

const UserManager = () => {
  const [loading, setLoading] = useState(false);
  const onFinish = (value) => {
    setFilter({ ...initFilter, ...value });
  };
  const [action, setAction] = useState<UserManagerAction>('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState<any>({});
  const [dataResponse, setDataResponse] = useState<UserListResponse>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as UserFilterRequest);
  const [isReload, setIsReload] = useState(false);

  const hasMore = dataResponse.totalCount > LIMIT;

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      const res = await UserService.getUsers(filter);
      if (res) setDataResponse(res);
      setLoading(false);
    };
    getUsers();
  }, [filter, isReload]);

  const onClickDropDown = ({ action, id }: { action: UserManagerAction; id: string }) => {
    setAction(action);
    setIsOpenModal(true);
    let detail;

    const found = dataResponse.items.find((item) => item.id === id);
    if (found) detail = found;

    if (detail) setData(detail);
  };

  const columnsConfig: ColumnsType<UsersType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '8%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'アバター',
      dataIndex: 'avatarObjectKey',
      align: 'center',
      key: 'avatarObjectKey',
      render: (value) => value && <Avatar shape="square" size={64} src={value} />,
    },
    {
      title: '名前',
      dataIndex: 'userName',
      ellipsis: true,
      key: 'userName',
    },
    // {
    //   title: 'メールアドレス',
    //   dataIndex: 'email',
    //   ellipsis: true,
    //   key: 'email',
    // },
    {
      title: 'Wallet アドレス',
      dataIndex: 'walletAddress',
      ellipsis: true,
      key: 'wallet',
    },
    {
      title: '使用状況',
      dataIndex: 'isBlocked',
      key: 'lockState',
      render: (value) => (value ? 'ロック' : 'アンロック'),
    },
    {
      title: '更新日付け',
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      render: (value) => moment(value).format(JP_DATE_FORMAT),
    },
    {
      title: '利用規約の同意日付',
      dataIndex: 'acceptPolicyTermDate',
      key: 'acceptPolicyTermDate',
      render: (value) => (value ? moment(value).format(JP_DATE_FORMAT) : ''),
    },
    {
      title: 'アクション',
      width: 150,
      key: 'dropdown',
      render: (_, record) => (
        <Select
          value="アクション"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="-view">
            <div
              onClick={() => onClickDropDown({ action: 'view', id: record.id })}
              style={{ textAlign: 'center' }}
            >
              表示
            </div>
          </Option>
          <Option value="action-edit">
            <div
              onClick={() => onClickDropDown({ action: 'update', id: record.id })}
              style={{ textAlign: 'center' }}
            >
              変更
            </div>
          </Option>
          <Option value="action-delete">
            <div
              onClick={() => onClickDropDown({ action: 'delete', id: record.id })}
              style={{ textAlign: 'center' }}
            >
              消去
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const handleChangePage = (page: number) => {
    setFilter((preState) => ({ ...preState, page }));
  };

  const handleDelete = async (id: string) => {
    try {
      await UserService.deleteUser(id);
      notification.success({
        message: 'Success',
        description: 'ユーザーの削除成功',
        duration: 4,
      });
      const getUsers = async () => {
        setLoading(true);
        const res = await UserService.getUsers(filter);
        if (res) setDataResponse(res);
        setLoading(false);
      };
      getUsers();
      setIsOpenModal(false);
    } catch (error: any) {
      console.error(`Error delete user`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  };

  const handerSubmit = async (value: any) => {
    try {
      await UserService.updateUser({ ...value, isBlocked: !!value.isBlocked }, data.id);

      notification.success({
        message: 'Success',
        description: 'ユーザーの情報が変更されました。',
        duration: 4,
      });
      setAction('');
      setIsOpenModal(false);
      setIsReload((pre) => !pre);
    } catch (error: any) {      
      console.error(`Error update user`, JSON.stringify(error));
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  };

  const onReload = () => {
    setFilter(initFilter);
  };

  const renderTitle = () => {
    switch (action) {
      case 'view':
        return 'ユーザ情報';
      case 'update':
        return 'ユーザ情報';
      case 'delete':
        return 'このユーザーを削除してもよろしいですか';
      default:
        return 'ユーザ情報';
    }
  };

  return (
    <div>
      <PageTitle title="ユーザ一覧" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} onReload={onReload} />
      </div>
      <div className="custom-form">
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              description="対象データが見つかりません。"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        >
          <Table
            columns={columnsConfig}
            pagination={false}
            dataSource={dataResponse.items}
            // scroll={{ x: 600 }}
            rowKey="id"
            loading={loading}
          />
        </ConfigProvider>
      </div>

      {hasMore && (
        <div className="text-right mt-10">
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
          />
        </div>
      )}

      <Modal
        title={renderTitle()}
        open={isOpenModal}
        footer={null}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        destroyOnClose
        width={action === 'view' ? 750 : 500}
      >
        <UserModal
          action={action}
          onSubmit={handerSubmit}
          onDelete={handleDelete}
          onClose={() => setIsOpenModal(false)}
          data={data}
        />
      </Modal>
    </div>
  );
};

export default UserManager;
