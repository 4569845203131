import { notification } from "antd"
import { AxiosResponse } from "axios"
import { API } from "redux/api-route"
import { DataResponseList } from "types/common"
import { CreatePresignParams, DataRequestList, NotificationDataRes } from "types/notifications"
import AxiosUtils from "utils/axios-utils"

const NotificationService = {
    getListNotifications: async (dataRequest: DataRequestList) => {
        const res: AxiosResponse<DataResponseList<NotificationDataRes>> = await AxiosUtils.get(API.listNoti, dataRequest);
        return res.data
    },

    getDetailNotifications: async (id: number | string | null) => {
        const res: any = await AxiosUtils.get(`${API.listNoti}/${id}`);
        return res.data
    },


    updateNotification: async (data: any) => {
        try {
            if (data?.id) {
                const id = data?.id;
                delete data?.id;
                const res: any = await AxiosUtils.patch(`${API.createNoti}/${id}`, data);
                return res.data
            } else {
                const res: any = await AxiosUtils.post(API.createNoti, data);
                return res.data
            }

        } catch (error) {
            notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
        }
    },


    deleteNotification: async (id?: string) => {
        try {
            await AxiosUtils._delete(`${API.createNoti}/${id}`);
            notification.success({ message: 'Success', description: '通知が削除されました。', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
        }
    },

    publishNotification: async (id?: string | null | number) => {
        try {
            await AxiosUtils.post(`${API.publishNoti}/${id}`);
            notification.success({ message: 'Success', description: '通知が公開されました。', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
        }
    },

    unpublishNotification: async (id?: string) => {
        try {
            await AxiosUtils.post(`${API.unpublishNoti}/${id}`);
            notification.success({ message: 'Success', description: '通知が非公開になりました。', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
        }
    },
    createPresign: async (params: CreatePresignParams) => {
        return await AxiosUtils.post(API.createPresignNoti, params);
    },

}

export default NotificationService