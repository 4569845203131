import { Avatar, Image, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PreviewImageDefault from 'assets/images/preview-image-default.jpeg';

import { LIST_SECOND_NFT_STATUS, LIST_SECOND_NFT_TYPE, SecondNft } from 'types/2st-nft';

const { Option } = Select;

export const tableConfig = (onHandlerClickDetail: (id: number) => void): ColumnsType<any> => [
  {
    title: 'ID',
    dataIndex: 'id',
    ellipsis: true,
    key: 'id',
    width: 75,
    align: 'center' as const,
  },
  {
    title: '画像',
    dataIndex: 'preview2DObjectKey',
    align: 'center',
    key: 'preview2DObjectKey',
    width: 100,
    render: (value) => <Image src={value || PreviewImageDefault} />,
  },
  {
    title: 'タイトル',
    dataIndex: 'name',
    ellipsis: true,
    key: 'name',
    align: 'center' as const,
  },
  {
    title: '説明文',
    dataIndex: 'description',
    ellipsis: true,
    key: 'description',
    align: 'center' as const,
  },
  {
    title: 'アドレス',
    dataIndex: 'creatorAddress',
    ellipsis: true,
    key: 'creatorAddress',
    align: 'center' as const,
  },
  {
    title: 'タイプ',
    dataIndex: 'type',
    align: 'center' as const,
    render: (value) => (
      <>{LIST_SECOND_NFT_TYPE.find((item) => item.value === value)?.label ?? value}</>
    ),
    key: 'type',
  },
  {
    title: '状態',
    dataIndex: 'status',
    align: 'center' as const,
    render: (value) => (
      <>{LIST_SECOND_NFT_STATUS.find((item) => item.value === value)?.label ?? value}</>
    ),
    key: 'status',
  },
  // {
  //   title: '素体類',
  //   dataIndex: 'bodyNftName',
  //   align: 'center',
  //   key: 'bodyNftName',
  // },
  {
    title: 'プリセット類',
    dataIndex: 'presetName',
    align: 'center',
    key: 'presetName',
  },
  {
    title: '武器',
    dataIndex: 'weaponName',
    align: 'center',
    key: 'weaponName',
  },
  {
    title: 'アクション',
    key: 'confirm',
    align: 'center' as const,
    render: (_, record) => (
      <Select
        value="アクション"
        getPopupContainer={(trigger) => trigger.parentElement}
        dropdownStyle={{ minWidth: 97 }}
      >
        <Option value={'Action1'}>
          <div onClick={() => onHandlerClickDetail(record.id)} style={{ textAlign: 'center' }}>
            変更
          </div>
        </Option>
      </Select>
    ),
  },
];

export const masterTableConfig = ({
  onHandlerClickDetail,
  handlerDeleteNft,
}: {
  onHandlerClickDetail: (id: number) => void;
  handlerDeleteNft: (id: number) => void;
}): ColumnsType<any> => [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 75,
    ellipsis: true,
    key: 'id',
    align: 'center' as const,
  },
  {
    title: '画像',
    dataIndex: 'avatar',
    align: 'center',
    key: 'avatar',
    width: 100,
    render: (_, record) => <Image src={record.preview2DObjectKey || PreviewImageDefault} />,
  },
  {
    title: 'タイトル',
    dataIndex: 'name',
    ellipsis: true,
    key: 'name',
  },
  {
    title: '説明文',
    dataIndex: 'description',
    ellipsis: true,
    key: 'description',
    align: 'center' as const,
  },
  {
    title: '素体類',
    dataIndex: 'bodyType',
    align: 'center',
    key: 'bodyType',

    render: (_, record) => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ maxWidth: 75 }}>
          <Image src={record.bodyPreview2DObjectKey || PreviewImageDefault} />
        </div>

        <p className="mt-10">{record.bodyName}</p>
      </div>
    ),
  },
  {
    title: 'プリセット類',
    dataIndex: 'presetName',
    align: 'center',
    key: 'presetName',
    ellipsis: true,
  },
  {
    title: '武器',
    dataIndex: 'weaponName',
    align: 'center',
    key: 'weaponName',
    ellipsis: true,
  },
  {
    title: '状態',
    dataIndex: 'status',
    render: (value) => <>{LIST_SECOND_NFT_STATUS.find((item) => item.value === value)?.label}</>,
    key: 'status',
    align: 'center' as const,
  },
  {
    title: 'アクション',
    key: 'confirm',
    align: 'center' as const,
    render: (_, record) => {
      return (
        <Select value={'アクション'}>
          <Option value={'Action1'}>
            <div onClick={() => onHandlerClickDetail(record.id)} style={{ textAlign: 'center' }}>
              更新
            </div>
          </Option>
          <Option value={'Action2'}>
            <div onClick={() => handlerDeleteNft(record.id)} style={{ textAlign: 'center' }}>
              削除
            </div>
          </Option>
        </Select>
      );
    },
  },
];

export const MOCK_DATA: SecondNft[] = [];
