import { Avatar, Col, Form, Input, Modal, notification, Row } from 'antd';
import CustomButton from 'components/custom-button';
import Loading from 'components/loading';
import ModelViewer from 'components/model-viewer';
import { useEffect, useState } from 'react';
import styles from '../style.module.scss';

import { useWeb3React } from '@web3-react/core';
import { FILE_TYPE, MODAL_TYPE } from 'constants/common';
import nftService from 'service/nft-service';
import { FilePreviewType } from 'types/common';
import { onChangeValueInputNumber } from 'utils/common';

import Brush from 'assets/icons/brush.svg';
import AvatarAdmin from 'assets/images/admin-avatar.jpeg';
import { useWagmiHook } from 'hooks/wagmi.hook';
import { getWagmiContractConfig } from 'utils/connectors';
import {
  getAddressDeployContract
} from 'utils/contract';
import { REQUIRED } from 'utils/rule-form';
import { useAccount, useContractWrite } from 'wagmi';

interface IProps {
  id: number | string;
  handelCloseMintPoup: () => void;
  type: string;
  clearFilter: () => void;
  isConnected?: boolean;
}

const Mint1stModal = ({ id, handelCloseMintPoup, type, clearFilter, isConnected }: IProps) => {
  const [mintDetail, setMintDetail] = useState<any>();
  const { account, library } = useWeb3React();
  const [filePreview, setFilePreview] = useState<FilePreviewType>();
  const [fileModel, setFileModel] = useState<FilePreviewType>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const maxPrice = 999999;
  const maxQuantity = 100000;
  const { address } = useAccount();

  // const { data: contractName } = useContractRead({
  //   ...(getWagmiContractConfig() as any),
  //   functionName: 'name',
  // });

  const { hasMintRole, hasBurnRole, amountNftNeedBurn } = useWagmiHook(mintDetail?.nftId);

  useEffect(() => {
    // use hasMintRole
    console.log('checking hasMintRole', address, hasMintRole);
  }, [address, hasMintRole]);

  const { write: mintNft } = useContractWrite({
    ...(getWagmiContractConfig() as any),
    functionName: 'createNFT',
    onSuccess(data) {
      console.log('Mint Nft Success', data);
      // hash: "0x2d1c9d661802c78be6a94ac698e13ccab323f95669a36f89114011fa4c17e435"

      nftService.mintNft(id, Number(form?.getFieldValue('quantity'))).then((result) => {
        if (result) {
          notification.success({
            message: 'Success',
            description: '一次NFTがミントされました。',
            duration: 4,
          });
        }
      });
    },
    onError(error: any) {
      console.error('Mint Nft Error', JSON.stringify(error));
      notification.info({
        message: 'Error',
        description: error?.shortMessage || 'ウォレットサイン要求がリジェクトされました。',
        duration: 4,
      });
    },
    onSettled() {
      setButtonLoading(false);
      handelCloseMintPoup();
      clearFilter();
    },
  });

  const { write: burnNft } = useContractWrite({
    ...(getWagmiContractConfig() as any),
    functionName: 'burn',
    onSuccess(data) {
      console.log('Burn Nft Success', data);
      nftService.burnNft(id, Number(amountNftNeedBurn)).then((result) => {
        if (result) {
          notification.success({
            message: 'Success',
            description: '一次NFTがバーンされました。',
            duration: 4,
          });
        }
      });
    },
    onError(error: any) {
      console.error(`Burn Nft Error`, JSON.stringify(error));
      notification.info({
        message: 'Error',
        description: error?.shortMessage || 'ウォレットサイン要求がリジェクトされました。',
        duration: 4,
      });
    },
    onSettled() {
      setButtonLoading(false);
      handelCloseMintPoup();
      clearFilter();
    },
  });

  const handleDownload = () => {
    var link = document.createElement('a');
    link.href = fileModel?.objectKey || '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDetail = async (id: string | number) => {
    setLoading(true);
    const result: any = await nftService.getDetail1stNft(id);
    if (result) {
      const filePreviewRes = result?.images?.find((item: any) => item.type === FILE_TYPE.PREVIEW);
      const fileModelRes = result?.images?.find((item: any) => item.type === FILE_TYPE.MODEL);
      setFilePreview(filePreviewRes);
      setFileModel(fileModelRes);
      setMintDetail(result);
    }
    setLoading(false);
  };

  const handleSellNft = async (id: number | string, price: number, nftId: string | number) => {
    console.log('[handleSellNft] start');
    setButtonLoading(true);
    const check: any[] = await nftService.checkSellNft(id);
    if (check) {
      const result: any = await nftService.sellNft(id, { price });
      if (result) {
        notification.success({
          message: 'Success',
          description: '一次NFTが販売されました',
          duration: 4,
        });
      }
    }
    setButtonLoading(false);
    handelCloseMintPoup();
    clearFilter();
  };

  const handleCancelSellNft = async (id: number | string) => {
    setButtonLoading(true);
    const result: any = await nftService.cancelSellNft(id).finally(() => {
      setButtonLoading(false);
    });
    if (result) {
      notification.success({
        message: 'Success',
        description: '一次NFTの販売が取消されました。',
        duration: 4,
      });
    }
    handelCloseMintPoup();
    clearFilter();
  };

  const setPrice = (number: number) => {
    form.setFieldValue('price', number);
  };
  const setQuantity = (number: number) => {
    form.setFieldValue('quantity', number);
  };

  const renderTitle = () => {
    switch (type) {
      case MODAL_TYPE.MINT:
        return '一次NFTミント';
      case MODAL_TYPE.SELL:
        return '一次NFT販売';
      case MODAL_TYPE.BURN:
        return '一次NFTバーン';
      case MODAL_TYPE.CANCEL_SELL:
        return '一次NFT販売取消';
      default:
        return '一次NFT変更';
    }
  };

  useEffect(() => {
    id && getDetail(id);
  }, [id]);

  return (
    <Modal
      forceRender
      title={renderTitle()}
      open={true}
      closable={false}
      footer={false}
      width={800}
      className="modal-custom"
    >
      {loading ? (
        <Loading />
      ) : (
        <div>
          {type === MODAL_TYPE.MINT && (
            <div className="custom-quantity">
              <div className={styles.quantityBox}>
                <p className={styles.mintTitle}>数量:</p>
                <div className={styles.quantityBoxInput}>
                  <Form form={form}>
                    <Form.Item
                      name="quantity"
                      rules={[
                        { min: 1, message: '数量は0より大きくなければなりません' },
                        { max: maxQuantity },
                        REQUIRED('数量'),
                      ]}
                      validateFirst={true}
                    >
                      <Input
                        onChange={(e) =>
                          onChangeValueInputNumber(e.target.value, maxQuantity, setQuantity, true)
                        }
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          )}
          {type === MODAL_TYPE.SELL && (
            <div className="custom-quantity">
              <div className={styles.quantityBox}>
                <p className={styles.mintTitle}>販売値段:</p>
                <div className={styles.quantityBoxInput}>
                  <Form form={form}>
                    <Form.Item
                      name="price"
                      rules={[
                        { pattern: new RegExp(/0|[1-9]+[0-9]{0,5}/), message: '価格は0~999999までを指定して下さい。' },
                        { max: maxPrice },
                        REQUIRED('価格'),
                      ]}
                      validateFirst={true}
                    >
                      <Input
                        placeholder="価格を入力してください"
                        onChange={(e) => {
                          onChangeValueInputNumber(e.target.value, maxPrice, setPrice, true);
                        }}
                      />
                    </Form.Item>
                    <p>￥</p>
                  </Form>
                </div>
              </div>
            </div>
          )}
          <div className={styles.previewRow}>
            <p className={styles.mintTitle}>プレビュー画像</p>
            <Row gutter={30}>
              <Col span={12}>
                <div>
                  <div className={styles.previewBox}>
                    <ModelViewer
                      src={filePreview?.objectKey}
                      width={'100%'}
                      height={200}
                      background="#e3e3e3"
                    />
                  </div>
                  {type === MODAL_TYPE.MINT && (
                    <div className={styles.buttonDownload} onClick={handleDownload}>
                      <img src={Brush} alt="icon" />
                      <p>ダウンロード</p>
                    </div>
                  )}
                </div>
              </Col>

              <Col span={12}>
                <div className={styles.nftDetail}>
                  <p className={styles.nftDetailName}>{mintDetail?.title}</p>
                  <p>{mintDetail?.description}</p>
                  <div className={styles.artishInfo}>
                    <Avatar src={AvatarAdmin} />
                    <p>{mintDetail?.creatorName}</p>
                  </div>
                  <Row gutter={10}>
                    <Col span={12}>
                      <div className={styles.nftItem}>
                        <p>{mintDetail?.displayType}</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.nftItem}>
                        <p>{mintDetail?.characterName || ''}</p>
                      </div>
                    </Col>
                  </Row>
                  {[MODAL_TYPE.SELL, MODAL_TYPE.BURN, MODAL_TYPE.CANCEL_SELL].includes(type) && (
                    <p className={styles.quantityShow}>数量: {mintDetail?.quantity || 0}</p>
                  )}
                  {[MODAL_TYPE.CANCEL_SELL].includes(type) && (
                    <p className={styles.priceShow}>価格: {mintDetail?.price || 0}￥</p>
                  )}
                  {[MODAL_TYPE.SELL, MODAL_TYPE.BURN, MODAL_TYPE.CANCEL_SELL].includes(type) && (
                    <p className={styles.priceShow}>
                      キャラクター: {mintDetail?.characterName || ''}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {type === MODAL_TYPE.BURN && (
            <div className={styles.burnConfirm}>
              <p>本当にこのNFTをバーンしてよろしいですか?</p>
            </div>
          )}
          {type === MODAL_TYPE.CANCEL_SELL && (
            <div className={styles.burnConfirm}>
              <p>本当にこのNFTの販売を取消してよろしいですか?</p>
            </div>
          )}
          <div className={styles.buttonSubmit}>
            {type === MODAL_TYPE.MINT && mintDetail?.canMint && (
              <CustomButton
                onClick={() => {
                  form.validateFields().then((value: any) => {
                    // handleMintNft(id, Number(value?.quantity), mintDetail?.nftId)
                    console.log('[hasMintRole when click mint]: ', address, hasMintRole);
                    if (!hasMintRole) {
                      notification.error({
                        message: 'Error',
                        description: 'NFTミント権限がありません。',
                        duration: 4,
                      });
                      return;
                    }

                    setButtonLoading(true);
                    nftService.checkMintNft(id).then((check) => {
                      console.log(`[checkMintNft result]: ${JSON.stringify(check)}`)
                      if (check) {
                        mintNft({
                          args: [
                            getAddressDeployContract(),
                            mintDetail?.nftId,
                            Number(value?.quantity),
                          ],
                        });
                      } else {
                        setButtonLoading(false);
                        handelCloseMintPoup();
                        clearFilter();
                      }
                    });
                  });
                }}
                text="Mint"
                bgColor="primary-green"
                color="white"
                fontWeight="700"
                loading={buttonLoading}
              />
            )}

            {type === MODAL_TYPE.BURN && mintDetail?.canBurn && (
              <CustomButton
                onClick={() => {
                  // handleBurnNft(id, mintDetail?.nftId);
                  if (!hasBurnRole) {
                    notification.error({
                      message: 'Error',
                      description: 'NFTバーン権限がありません。',
                      duration: 4,
                    });
                    return;
                  }

                  setButtonLoading(true);
                  console.log('[amountNftNeedBurn]: ', amountNftNeedBurn);
                  console.log('[hasBurnRole when click burn]: ', address, hasBurnRole);
                  nftService.checkBurnNft(id).then((check) => {
                    if (check && amountNftNeedBurn) {
                      burnNft({
                        args: [
                          getAddressDeployContract(),
                          mintDetail?.nftId,
                          Number(amountNftNeedBurn),
                        ],
                      });
                    } else {
                      setButtonLoading(false);
                      handelCloseMintPoup();
                      clearFilter();
                    }
                  });
                }}
                text="Burn"
                bgColor="secondary-green"
                color="white"
                fontWeight="700"
                loading={buttonLoading}
              />
            )}

            {type === MODAL_TYPE.SELL && mintDetail?.canSell && (
              <CustomButton
                onClick={() => {
                  form.validateFields().then((value: any) => {
                    handleSellNft(id, Number(value?.price), mintDetail?.nftId);
                  });
                }}
                text="販売"
                bgColor="secondary-green"
                color="white"
                fontWeight="700"
                loading={buttonLoading}
              />
            )}

            {type === MODAL_TYPE.CANCEL_SELL && (
              <CustomButton
                onClick={() => {
                  handleCancelSellNft(id);
                }}
                text="OK"
                bgColor="secondary-green"
                color="white"
                fontWeight="700"
                loading={buttonLoading}
              />
            )}

            <CustomButton
              onClick={handelCloseMintPoup}
              text="キャンセル"
              bgColor="primary-grey"
              color="black"
              fontWeight="700"
              loading={buttonLoading}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
export default Mint1stModal;
