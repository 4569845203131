import { notification } from 'antd';
import { JP_DATE_FORMAT, StepInfo, STEPS } from 'constants/common';
import { get } from 'lodash';
import moment from 'moment';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../types/redux';
import AxiosUtils from './axios-utils';

export const useAppDisPatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj)?.length === 0;
};

export const handleUploadFile = async (file: any, limit?: string): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('limit', limit || '');
  const apiResponse = (await AxiosUtils.postS3('/common/s3/upload', formData)) as any;
  return apiResponse;
};

export const deleteS3File = async (key: string): Promise<any> => {
  return (await AxiosUtils.postS3('/common/s3/delete-file', { key })) as any;
};

export const onChangeValueInputNumber = (
  value: string | number,
  maxValue: number,
  action: any,
  isDeleteDot?: boolean
) => {
  if (value === '.') return action('');
  let number = value
    .toString()
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1');
  if (isDeleteDot) {
    number = number.replace(/\./g, '');
  }
  if (Number(number) > maxValue) {
    number = number.slice(0, -1);
  }
  console.log(`[onChangeValueInputNumber]: number=${number}`)
  action(number === '0' ? 0 : number);
};

export const formatedDate = (date: string) => {
  return moment(date).format(JP_DATE_FORMAT);
};

export const generateInitSteps = (steps: STEPS[]) => {
  return steps.reduce(
    (pre, current) => ({
      ...pre,
      [current]: {
        loading: true,
        hasError: false,
      },
    }),
    {} as StepInfo
  );
};

export const handlerFinishStep = ({
  step,
  error,
  setStep,
}: {
  step: STEPS;
  error: any;
  setStep: any;
}) => {
  if (error) {
    setStep((pre: StepInfo) => {
      return Object.keys(pre).reduce((preVal, current: string) => {
        if (current === step || get(pre, `${current}.loading`)) {
          return {
            ...preVal,
            [current]: { ...pre[current as keyof typeof STEPS], hasError: true, loading: false },
          };
        } else {
          return { ...preVal, [current]: { ...pre[current as keyof typeof STEPS] } };
        }
      }, {} as StepInfo);
    });

    notification.error({
      message: 'Error',
      description: error.message ?? error,
      duration: 4,
    });
  } else {
    setStep((pre: StepInfo) => ({
      ...pre,
      [step]: { ...pre[step], loading: false },
    }));
  }
};

export const getStepsLabel = (step: STEPS): string => {
  switch (step) {
    case STEPS.CREATE_1ST:
      return '一次NFT作成';
    case STEPS.CREATE_2ND:
      return '二次NFT作成';
    case STEPS.CREATE_PRESIGN_URL:
      return '署名前URLの作成';
    case STEPS.SAVE_TO_DB:
      return 'データベース保存';
    case STEPS.UPLOAD:
      return 'S3へのアップロード';
    default:
      return '';
  }
};

export const formatterCurrency = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
});
