import { Image, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PreviewImageDefault from 'assets/images/preview-image-default.jpeg';

import { LIST_SECOND_NFT_STATUS, SecondNft } from 'types/2st-nft';

const { Option } = Select;

export const tableConfig = (onHandlerClickDetail: (id: number) => void): ColumnsType<any> => [
  {
    title: 'ID',
    dataIndex: 'id',
    ellipsis: true,
    key: 'id',
    width: 75,
    align: 'center' as const,
  },
  {
    title: '画像',
    dataIndex: 'preview2DObjectKey',
    align: 'center',
    key: 'preview2DObjectKey',
    width: 100,
    render: (value) => <Image src={value || PreviewImageDefault} />,
  },
  {
    title: 'タイトル',
    dataIndex: 'name',
    ellipsis: true,
    key: 'name',
    // width: 200,
    align: 'center' as const,
  },
  {
    title: '説明文',
    dataIndex: 'description',
    ellipsis: true,
    key: 'description',
    align: 'center' as const,
    // width: 200,
  },
  {
    title: 'アドレス',
    dataIndex: 'creatorAddress',
    ellipsis: true,
    key: 'creatorAddress',
    align: 'center' as const,
    // width: 200,
  },
  {
    title: '状態',
    dataIndex: 'status',
    // width: 200,
    align: 'center' as const,
    render: (value) => (
      <>{LIST_SECOND_NFT_STATUS.find((item) => item.value === value)?.label ?? value}</>
    ),
    key: 'status',
  },
  // {
  //   title: '素体類',
  //   dataIndex: 'bodyNftName',
  //   align: 'center',
  //   key: 'bodyNftName',
  // },
  {
    title: 'アクション',
    // width: 200,
    key: 'confirm',
    align: 'center' as const,
    render: (_, record) => (
      <Select
        value="アクション"
        getPopupContainer={(trigger) => trigger.parentElement}
        dropdownStyle={{ minWidth: 97 }}
      >
        <Option value={'Action1'}>
          <div onClick={() => onHandlerClickDetail(record.id)} style={{ textAlign: 'center' }}>
            変更
          </div>
        </Option>
      </Select>
    ),
  },
];

export const MOCK_DATA: SecondNft[] = [];
