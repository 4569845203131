import {
  DEFAULT_REQUIRED_MESSAGE,
  REQUIRED_MESSAGE,
  REQUIRED_MESSAGE_MINT,
  REQUIRED_MESSAGE_TITLE_MINT,
  REQUIRED_PREVIEW_MODAL_IMAGE,
} from 'constants/common';
import { REGEX_PASSWORD, REGEX_PHONE_NUMBER } from 'constants/rule-form';
import { FileUploadTypes } from 'types/common';

const REQUIRED = (label = '') => {
  return {
    validator: (_: any, value: any) => {
      if (!value) {
        return Promise.reject(
          new Error(label ? REQUIRED_MESSAGE.replace('{{field}}', label) : DEFAULT_REQUIRED_MESSAGE)
        );
      }

      return Promise.resolve();
    },
  };
};

const REQUIRED_MINT = { required: true, message: REQUIRED_MESSAGE_MINT };

const REQUIRED_TITLE_MINT = { required: true, message: REQUIRED_MESSAGE_TITLE_MINT };

const VALIDATE_PASSWORD = {
  pattern: new RegExp(REGEX_PASSWORD),
  message:
    'パスワードは8文字以上15文字以内 、アルファベットの 大文字、小文字 、数字、記号の複合で作成してください。',
};
const VALIDATE_PHONE_NUMBER = {
  pattern: REGEX_PHONE_NUMBER,
  message: '電話番号の形式が正しくありません。',
};
const CHECK_FILE = (fieldName = '', isUploadNoti = false) => ({
  validator: (_: any, value: FileUploadTypes) => {
    if (value?.error) {
      return Promise.reject(new Error(value?.error));
    }
    if (value?.file) {
      return Promise.resolve();
    }

    if (isUploadNoti) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(REQUIRED_MESSAGE.replace('{{field}}', fieldName || 'プレビュー用画像 (2D)'))
    );
  },
});

export {
  REQUIRED,
  REQUIRED_TITLE_MINT,
  REQUIRED_MINT,
  CHECK_FILE,
  VALIDATE_PASSWORD,
  VALIDATE_PHONE_NUMBER,
};
