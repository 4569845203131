import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  data: any
  isLoading: any;
  error: any;
}
const initialState: InitialState = {
  data: {
    color: null
  },
  isLoading: {
    color: false
  },
  error: {},
};

const attributesSlice = createSlice({
  name: 'attributes',
  initialState: initialState,
  reducers: {
    getLoading: (state, action) => {
      const name = action?.payload?.name
      state.isLoading[name] = true;
    },
    getSuccess: (state, action?) => {
      const name = action?.payload?.name;
      const data = action?.payload?.data
      state.data[name] = data;
      state.isLoading[name] = false;
    },
    getFailure: (state, action) => {
      state.isLoading.color = false;
      state.error.color = action.payload;
    },
    remove: (state) => {
      state.isLoading = {};
      state.error = {};
    },
  },
});

export const { getLoading, getSuccess, getFailure, remove } =
attributesSlice.actions;

export default attributesSlice.reducer;
