import { Form, Input } from 'antd';
import { REQUIRED, VALIDATE_PASSWORD } from 'utils/rule-form';

const ChangePassword = () => {
  return (
    <>
      <Form.Item
        label="現在のパスワード"
        name="oldPassword"
        rules={[
          REQUIRED('現在のパスワード'),
          { min: 8, message: 'パスワードは 8 文字以上にする必要があります。' },
        ]}
      >
        <Input.Password maxLength={15} />
      </Form.Item>
      <Form.Item
        label="新しいパスワード"
        name="newPassword"
        dependencies={['oldPassword']}
        validateFirst
        rules={[
          REQUIRED('新しいパスワード'),
          VALIDATE_PASSWORD,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('oldPassword') !== value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('新しいパスワードは現在のパスワードと同じです。'));
            },
          }),
        ]}
      >
        <Input.Password maxLength={15} />
      </Form.Item>
      <Form.Item
        label="新しいパスワード（確認)"
        name="confirmNewPassword"
        dependencies={['newPassword']}
        rules={[
          REQUIRED('新しいパスワード（確認)'),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('新しいパスワード（確認）と新しいいパスワードは違います。')
              );
            },
          }),
        ]}
      >
        <Input.Password maxLength={15} />
      </Form.Item>
    </>
  );
};

export default ChangePassword;
