import { AxiosResponse } from 'axios';
import { API } from 'redux/api-route';
import { Faucet, FaucetFilterRequest, FaucetListResponse } from 'types/faucet';
import AxiosUtils from 'utils/axios-utils';

const FaucetService = {
  getFaucets: async (params: FaucetFilterRequest): Promise<FaucetListResponse> => {
    const response: AxiosResponse<FaucetListResponse> = await AxiosUtils.get(API.faucets, params);
    return response.data;
  },

  getFaucet: async (id: string): Promise<Faucet[]> => {
    const response: AxiosResponse<Faucet[]> = await AxiosUtils.get(
      API.faucet.replace('{id}', id.toString())
    );
    return response.data;
  },
};

export default FaucetService;
