import { Table, Avatar, Skeleton, ConfigProvider, Empty } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import CustomButton from 'components/custom-button';
import { Faucet } from 'types/faucet';
import { formatedDate } from 'utils/common';
import { get } from 'lodash';

const TableFooter = ({ data }: { data: Faucet[] }) => {
  const value = data.reduce((pre, current) => Number(current.amount) + pre, 0);

  return (
    <div className="d-flex justify-end">
      合計 <p style={{ marginLeft: '1.5rem', paddingLeft: '1.6rem' }}>{Number(value.toFixed(6))}</p>
    </div>
  );
};

const FaucetDetail = (props: {
  onClose: () => void;
  data: Faucet[];
  isLoading: boolean;
  infoUser: any;
}) => {
  const { onClose, infoUser } = props;
  const columnsConfig: ColumnsType<Faucet> = [
    {
      title: '#',
      width: '10%',
      ellipsis: true,
      key: 'index',
      render: (_, __, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: '日付',
      dataIndex: 'createdAt',
      width: '15%',
      key: 'createdAt',
      render: (value) => <>{formatedDate(value)}</>,
    },
    {
      title: 'フォーセットの理由',
      dataIndex: 'reason',
      ellipsis: true,
      key: 'reason',
    },
    {
      title: '取引ID',
      align: 'center',
      width: '30%',
      key: 'transactionId',
      dataIndex: 'transactionId',
    },
    {
      title: '量(MATIC)',
      dataIndex: 'amount',
      align: 'right',
      key: 'amount',
    },
  ];

  return (
    <>
      {props.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="d-flex align-center mb-10">
            <p className="mb-0 mr-10">ユーザー</p>
            <Avatar shape="square" size="large" src={infoUser?.image} />
            <p className="mb-0 ml-10">{infoUser?.name}</p>
          </div>

          <p className="mb-10">履歴</p>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description="対象データが見つかりません。"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          >
            <Table
              columns={columnsConfig}
              dataSource={props.data}
              scroll={{ x: 600, y: 800 }}
              pagination={false}
              footer={(currentPageData) => <TableFooter data={[...currentPageData]} />}
            />
          </ConfigProvider>

          <div className="text-center">
            <CustomButton
              style={{ marginTop: 20 }}
              text="キャンセル"
              bgColor="primary-grey"
              onClick={onClose}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FaucetDetail;
