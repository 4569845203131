import { Result } from 'antd';
import CustomButton from 'components/custom-button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<CustomButton onClick={() => navigate(paths.home)} text="Back Home" />}
    />
  );
};

export default NotFound;
