import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "service/user-service";
import { UpdateUserProfileRequest } from "types/account";
import { USER_ACTION_TYPES } from "./type";

export const getUserDetailAction = createAsyncThunk(USER_ACTION_TYPES.GET_USER_DETAIL, async () => {
    const response = await UserService.getUserDetail();
    return response;
})

export const updateUserProfile = createAsyncThunk(USER_ACTION_TYPES.UPDATE_USER_PROFILE, async ({ body, onSuccess, onFailed }: { body: UpdateUserProfileRequest, onSuccess: () => void, onFailed: () => void }) => {
    const response = await UserService.updateUserProfile(body, onSuccess, onFailed);
    return response;
})