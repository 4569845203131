import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { CustomToast } from 'components/custom-toast/custom-toast';
import { API } from 'redux/api-route';
import { ChangePasswordRequest } from 'types/account';
import { UpdateUserProfileRequest, User } from 'types/account';
import { Session } from 'types/common';
import { UpdateUserRequest, UserFilterRequest, UserListResponse } from 'types/users';
import AxiosUtils from 'utils/axios-utils';
import { getSession, setRefreshToken } from 'utils/session';

const UserService = {
  changePassword: async (requestBody: ChangePasswordRequest) => {
    const response: any = await AxiosUtils.post(API.changePassword, requestBody);
    return response.data;
  },
  getUserDetail: async (): Promise<User> => {
    const response: AxiosResponse<User> = await AxiosUtils.get(API.getUserDetail);
    return response.data;
  },
  updateUserProfile: async (
    requestBody: UpdateUserProfileRequest,
    onSuccess: () => void,
    onFailed: () => void
  ): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await AxiosUtils.patch(
        API.updateUserProfile,
        requestBody
      );

      setTimeout(() => {
        notification.success({
          message: 'Success',
          description: 'プロファイルは更新されました。',
          duration: 4,
        });
        onSuccess();
      });

      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'プロファイルの更新に失敗しました!',
        duration: 4,
      });
      onFailed();

      throw error;
    }
  },
  getUsers: async (params: UserFilterRequest) => {
    try {
      const response: AxiosResponse<UserListResponse> = await AxiosUtils.get(API.users, params);

      return response.data;
    } catch (error: any) {
      notification.error({ message: 'Error', description: error.message, duration: 4 });
    }
  },
  updateUser: async (body: UpdateUserRequest, id: string) => {
    const response: AxiosResponse<any> = await AxiosUtils.patch(
      API.updateUser.replace('{{id}}', id),
      body
    );

    return response.data;
  },
  deleteUser: async (id: string) => {
    const response: AxiosResponse<any> = await AxiosUtils._delete(
      API.updateUser.replace('{{id}}', id),
    );

    return response.data;
  },
  refreshToken: async (token: string) => {
    try {
      const res: any = await AxiosUtils.postToken(
        API.refreshToken,
        {},
        { 'x-upbond-refresh-session': token }
      );
      const session: Session = getSession();
      setRefreshToken({ ...session, ...res?.data });
      return res?.data;
    } catch (error: any) {
      return;
    }
  },
  logOut: async (token: string) => {
    await AxiosUtils.post(API.logOut, {}, { 'x-upbond-refresh-session': token });
  },
};

export default UserService;
