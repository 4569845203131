import { getWagmiContractConfig } from 'utils/connectors';
import { getAddressDeployContract, getBurnRoleContract, getMintRoleContract } from 'utils/contract';
import { useAccount, useContractRead } from 'wagmi';

export const useWagmiHook = (nftId?: number): {
  hasMintRole?: boolean;
  hasBurnRole?: boolean;
  amountNftNeedBurn?: number;
} => {
  const { address } = useAccount();

  const { data: hasMintRole } = useContractRead({
    ...(getWagmiContractConfig() as any),
    functionName: 'hasRole',
    args: [getMintRoleContract(), address],
    onSuccess(data) {
      console.log('Success hasRole mint', data);
    },
    onError(error) {
      console.log('Address:', address);
      console.log('Error hasRole mint', error);
    },
  });

  const { data: hasBurnRole } = useContractRead({
    ...(getWagmiContractConfig() as any),
    functionName: 'hasRole',
    args: [getBurnRoleContract(), address],
  });

  const { data: amountNftNeedBurn } = useContractRead({
    ...(getWagmiContractConfig() as any),
    functionName: 'balanceOf',
    args: [getAddressDeployContract(), nftId],
    onError: (error) => {
      console.log('[error balanceOf]: ', error)
    }
  });

  return {
    hasBurnRole: hasBurnRole as boolean,
    hasMintRole: hasMintRole as boolean,
    amountNftNeedBurn: Number(amountNftNeedBurn) as number,
  };
};
