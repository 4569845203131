import {
  Checkbox,
  ConfigProvider,
  Empty,
  Modal,
  notification,
  Pagination,
  Select,
  Space,
  Table,
} from 'antd';
import CustomButton from 'components/custom-button';
import PageTitle from 'components/page-title';
import { AlignType } from 'rc-table/lib/interface';
import { useEffect, useState } from 'react';
import SystemService from 'service/system-config';
import { DataRequestList } from 'types/admin';
import ModalAddWalletConfig from './components/ModalAddWalletConfig';
import styles from './styles.module.scss';
import { PlusOutlined } from '@ant-design/icons';

const LIMIT = 10;

const { Option } = Select;

const SystemConfiguration = () => {
  const [dataTable, setDataTable] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpenModalAddWallet, setIsOpenModalAddWallet] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [valueWalletSelected, setValueWalletSelected] = useState<any>(null);

  useEffect(() => {
    getListWalletConfig();
  }, [statusUpdateData, currentPage]);

  const getListWalletConfig = async () => {
    const dataRequest: DataRequestList = {
      limit: LIMIT,
      page: currentPage,
    };
    const data = await SystemService.getListWalletConfig(dataRequest);
    setDataTable(data?.data);
    setTotalItem(data?.metadata?.total);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleOpenModalAddWallet = () => {
    setIsOpenModalAddWallet(true);
  };

  const handleCloseModalAddWallet = () => {
    setIsOpenModalAddWallet(false);
  };

  const handleOpenDeleteModal = (record: any) => {
    setIsModalDeleteOpen(true);
    setValueWalletSelected(record);
  };

  const handleCloseDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleDeleteWalletConfig = async (id: string) => {
    setLoading(true);
    try {
      await SystemService.deleteWalletConfig(id);
    } catch (error) {
      console.error(`Error delete system setting: サーバーエラーが発生しました。しばらく経ってから再度お試しください。`, JSON.stringify(error));
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
      setLoading(false);
    } finally {
      setLoading(false);
      handleCloseDeleteModal();
      onChangeStatusUpdateData();
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <span>{record?.id}</span>,
    },
    {
      title: 'ウォレットアドレス',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => <span>{record?.walletAddress}</span>,
    },
    {
      title: 'ミント可',
      dataIndex: 'mint',
      key: 'mint',
      align: 'center' as AlignType,
      render: (_, record) => <Checkbox checked={record?.isMinted} disabled />,
    },
    {
      title: 'バーン可',
      dataIndex: 'burn',
      key: 'burn',
      align: 'center' as AlignType,
      render: (_, record) => <Checkbox checked={record?.isBurned} disabled />,
    },
    {
      title: 'アクション',
      key: 'action',
      align: 'center' as AlignType,
      render: (_, record) => {
        return (
          <Select
            value="アクション"
            getPopupContainer={(trigger) => trigger.parentElement}
            dropdownStyle={{ minWidth: 97 }}
          >
            <Option value="action-delete">
              <div onClick={() => handleOpenDeleteModal(record)} style={{ textAlign: 'center' }}>
                消去
              </div>
            </Option>
          </Select>
        );
      },
    },
  ];

  return (
    <div className="systemConfig">
      <PageTitle title="システム設定" />
      <div className={styles.boxSystemConfig}>
        <div className="custom-form">
          <div className="mb-10">
            <CustomButton
              onClick={() => handleOpenModalAddWallet()}
              bgColor="primary-green"
              text="新規追加"
              icon={<PlusOutlined />}
            />
          </div>
          <p>ウォレット権限</p>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description="ウォレットアドレスが見つかりません。「新規追加」ボタンをクリックし、新しいアドレスを追加してください。"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          >
            <Table columns={columns} dataSource={dataTable} pagination={false} loading={loading} rowKey="id"/>
          </ConfigProvider>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: 30 }}>
            {totalItem > LIMIT && (
              <Pagination
                showSizeChanger={false}
                current={currentPage}
                defaultPageSize={LIMIT}
                onChange={handleChangePage}
                showLessItems
                total={totalItem}
              />
            )}
          </div>
        </div>
      </div>
      {isOpenModalAddWallet && (
        <ModalAddWalletConfig
          isOpen={isOpenModalAddWallet}
          handleCloseModalAddWallet={handleCloseModalAddWallet}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="ウォレットアドレス情報を削除"
        open={isModalDeleteOpen}
        onCancel={handleCloseDeleteModal}
        footer={false}
        className="form-add-wallet"
        width={550}
      >
        <div className={styles.boxInfoWallet}>
          <div style={{ marginBottom: 18 }}>
            <label
              className={styles.labelInput}
              htmlFor="walletAddress"
              style={{
                font: 'normal normal 700 14px/20px Manrope',
                color: 'rgba(66, 66, 66, 0.6)',
              }}
            >
              ウォレットアドレス:
            </label>
            <p style={{ font: 'normal normal 400 16px/22px Inter', color: '#424242' }}>
              {valueWalletSelected?.walletAddress}
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 19 }}>
            <Checkbox
              checked={valueWalletSelected?.isMinted}
              disabled
              style={{ marginRight: 10 }}
            />
            <label
              className={styles.labelInput}
              htmlFor="mint"
              style={{ font: 'normal normal 400 16px/22px Manrope', color: '#424242' }}
            >
              ミント権
            </label>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={valueWalletSelected?.isBurned}
              disabled
              style={{ marginRight: 10 }}
            />
            <label
              className={styles.labelInput}
              htmlFor="burn"
              style={{ font: 'normal normal 400 16px/22px Manrope', color: '#424242' }}
            >
              バーン権
            </label>
          </div>
        </div>

        <p
          style={{
            textAlign: 'center',
            font: 'normal normal 600 16px/24px Inter',
            color: '#424242',
          }}
        >
          本当にこのウォレットアドレスを削除してよろしいですか？
        </p>
        <div className="d-flex justify-center" style={{ marginTop: 30 }}>
          <Space size="large">
            <CustomButton
              bgColor="primary-red"
              text="OK"
              loading={loading}
              onClick={() => handleDeleteWalletConfig(String(valueWalletSelected?.id))}
            />
            <CustomButton
              text="キャンセル"
              bgColor="primary-grey"
              htmlType="button"
              onClick={handleCloseDeleteModal}
            />
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default SystemConfiguration;
