import { Form, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import CustomButton, { ButtonType } from 'components/custom-button';
import React from 'react';
import { REQUIRED } from 'utils/rule-form';
import { CONFIRM_ACTION } from '../index';

type Props = {
  action: CONFIRM_ACTION;
  onClose: () => void;
  buttonLoading: boolean;
  onConfirm: (params: any) => void;
};

type ButtonConfig = {
  label: string;
  color?: ButtonType;
  onClick?: (params?: any) => void;
  htmlType?: any;
};

type ReturnConfig = {
  title: string;
  description?: string;
  buttonConfig: ButtonConfig[];
};

const getConfig = ({
  action,
  onClose,
}: {
  action: CONFIRM_ACTION;
  onClose: () => void;
}): ReturnConfig => {
  let config: ReturnConfig = {
    title: '',
    description: '',
    buttonConfig: [],
  };

  switch (action) {
    case CONFIRM_ACTION.APPROVE:
      config = {
        title: '二次創作NFT承認',
        description: 'この二次創作NFTを承認してよろしいですか?',
        buttonConfig: [
          {
            label: '承認',
            color: 'primary-green',
            htmlType: 'submit',
          },
          {
            label: 'キャンセル',
            color: 'primary-grey',
            onClick: onClose,
          },
        ],
      };
      break;
    case CONFIRM_ACTION.DELETE:
      config = {
        title: '二次NFTマスタ削除',
        description: 'この二次創作NFTマスタを削除してもよろしいですか',
        buttonConfig: [
          {
            label: '削除',
            color: 'primary-red',
            htmlType: 'submit',
          },
          {
            label: 'キャンセル',
            color: 'primary-grey',
            onClick: onClose,
          },
        ],
      };
      break;
    case CONFIRM_ACTION.REJECT:
      config = {
        title: '二次創作NFTリジェクト',
        description: '',
        buttonConfig: [
          {
            label: 'リジェクト',
            color: 'primary-red',
            htmlType: 'submit',
          },
          {
            label: 'キャンセル',
            color: 'primary-grey',
            onClick: onClose,
          },
        ],
      };
      break;
  }

  return config;
};

const ConfirmModal: React.FC<Props> = ({ action, onClose, onConfirm, buttonLoading }) => {
  const [form] = Form.useForm();
  const { title, description, buttonConfig } = getConfig({ action, onClose });

  return (
    <>
      <Form form={form} className="custom-form" onFinish={onConfirm}>
        <div className="ant-modal-title">{title}</div>
        {description && <div style={{ margin: '5rem 0', textAlign: 'center' }}>{description}</div>}

        {action === CONFIRM_ACTION.REJECT && (
          <Form.Item
            name="reason"
            label="リジェクトの理由"
            style={{ margin: '3rem 0' }}
            rules={[REQUIRED()]}
          >
            <TextArea placeholder="リジェクトの理由を入力" rows={3} maxLength={500} />
          </Form.Item>
        )}

        <div className="text-center">
          <Space size={24}>
            {buttonConfig.map((item, index) => (
              <CustomButton
                key={index}
                onClick={item.onClick}
                text={item.label}
                bgColor={item.color}
                htmlType={item.htmlType || 'button'}
                loading={buttonLoading}
                disabled={buttonLoading}
              />
            ))}
          </Space>
        </div>
      </Form>
    </>
  );
};

export default ConfirmModal;
