import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/redux';
import styles from '../style.module.scss';
import SelectAnWeapon from './select-an-weapon';

interface IProps {
  onChange?: (value: any) => void;
  value?: any;
  disabled?: boolean;
  expand?: boolean;
  additionalParams?: { character?: number | undefined };
}

const SelectAllWeapon = ({ onChange, value, disabled, expand, additionalParams }: IProps) => {
  const { data }: any = useSelector((state: RootState) => state?.attributes);
  const [isShow, setIsShow] = useState<boolean>(!!expand);
  const position = data?.position;
  const positonArr = position?.map((item: any, index: number) => {
    return { positionId: item.id };
  });
  const [weaponArr, setWeaponArr] = useState<any[]>(positonArr);

  const handleSelect = (option: any, index: number, positionId: number) => {
    let weaponArrClone = [...weaponArr];
    weaponArrClone = weaponArrClone.map((item: any, index: number) => {
      if (Number(item?.positionId) === Number(positionId)) return { weapon: option, positionId };
      else return item;
    });
    setWeaponArr(weaponArrClone);
    onChange && onChange(weaponArrClone);
  };

  // useEffect(() => {
  //   setWeaponArr(positonArr);
  // }, [additionalParams]);

  useEffect(() => {
    if (value) {
      let changeWeaponArr = weaponArr;
      for (let i = 0; i < value?.length; i++) {
        changeWeaponArr = changeWeaponArr?.map((item: any, index: number) => {
          if (Number(item?.positionId) === Number(value?.[i]?.positionId)) {
            return value?.[i];
          } else return item;
        });
      }
      setWeaponArr(changeWeaponArr);
    } else {
      setWeaponArr(positonArr);
    }
  }, [value]);

  return (
    <div className={styles.selectWeapon} style={isShow ? {} : { height: 160 }}>
      {additionalParams?.character ? (
        <div>
          <Row gutter={20}>
            {(isShow ? position : position.slice(0, 2)).map((item: any, index: number) => {
              const weaponDetail = weaponArr?.filter(
                (itemWeapon: any) => Number(itemWeapon?.positionId) === Number(item?.id)
              )[0];

              return (
                <Col span={12} className={styles.optionWeaponBox}>
                  <p className={styles.positionName}>{item.name}</p>
                  <SelectAnWeapon
                    value={value}
                    positionId={item?.id}
                    handleSelect={handleSelect}
                    index={index}
                    weaponDetail={weaponDetail}
                    disabled={disabled}
                    additionalParams={additionalParams}
                  />
                </Col>
              );
            })}
          </Row>
          <div className={styles.showAll}>
            <div
              onClick={() => {
                setIsShow(!isShow);
              }}
            >
              <p>{isShow ? 'Show less' : 'Show all'}</p>
              {isShow ? (
                <DoubleLeftOutlined className={styles.iconShowAll} />
              ) : (
                <DoubleRightOutlined className={styles.iconShowAll} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>武器を表示するには、キャラクターを選択する必要があります</p>
      )}
    </div>
  );
};
export default SelectAllWeapon;
