import React, { useEffect, useRef, useState } from 'react';
import { Image, Tooltip } from 'antd';
import { FileUploadPropsTypes } from 'types/common';
import './style.scss';
import { FILE_FORMAT, UPLOAD_TYPE } from 'constants/common';
import { shortenName } from 'utils/short-name';

const FileUpload = ({
  onChange,
  value,
  type,
  maxFileSize,
  edit,
  enablePreview = true,
  disabled,
}: FileUploadPropsTypes) => {
  const fileUrlImageRef = useRef<any>(null);
  const [filePreview, setFilePreview] = useState<any>();
  const id = String(Math.random());

  const fileAccept = () => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return '.png, .jpeg, .jpg';
      case UPLOAD_TYPE.MODEL:
        return ' .stl, .obj, .fbx, .blend, .zip';
      case UPLOAD_TYPE.PREVIEW:
        return '.glb, .gltf';
      case UPLOAD_TYPE.FILE2D:
        return '.png, .jpeg, .jpg, .gif';
      case UPLOAD_TYPE.GUIDE:
        return '.pdf, .jpeg';
      default:
        return '.png, .jpeg, .jpg';
    }
  };

  const getFileExtension = (fileName: string) => {
    const lastDot = fileName.lastIndexOf('.');
    return fileName.substring(lastDot + 1);
  };

  const onImageChange = (file: any) => {
    setFilePreview(renderUrl(file));
  };

  const onFileChange = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const fileDetail = e.target.files[0];
    const fileExtension = getFileExtension(fileDetail.name);

    if (!FILE_FORMAT[type].includes(fileExtension.toLowerCase())) {
      onChange({
        fileKey: fileDetail.name,
        error: `選択したイメージは${fileAccept()}形式である必要があります。`,
        file: fileDetail,
      });
      return;
    }
    onImageChange(fileDetail);
    onChange({
      fileKey: fileDetail.name,
      file: fileDetail,
    });
  };

  const renderUrl = (file: any) => {
    if (typeof file === 'string') return file;
    else return URL.createObjectURL(file);
  };

  const RenderPreviewFile = () => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return (
          <label htmlFor={id}>
            <div
              className="file-upload__upload-box-image"
              style={!disabled ? { cursor: 'pointer' } : {}}
            >
              {filePreview ? (
                <Image
                  className="file-upload__upload-box-image__img"
                  src={renderUrl(filePreview)}
                  alt="file-upload-image"
                  preview={false}
                />
              ) : (
                <div className="file-upload__upload-box-image__not-hover">プレビュー画像表示</div>
              )}
              {!disabled && (
                <div className="file-upload__upload-box-image__hover">プレビュー画像表示</div>
              )}
            </div>
          </label>
        );
      case UPLOAD_TYPE.PREVIEW:
      case UPLOAD_TYPE.MODEL:
      case UPLOAD_TYPE.FILE2D:
      case UPLOAD_TYPE.GUIDE:
        return (
          <div className="file-upload__upload-box-preview">
            <div className="file-upload__upload-box-preview__name">
              {value && (
                <Tooltip title={value?.fileKey}>
                  {shortenName(value?.fileKey || '', 30) || ''}
                </Tooltip>
              )}
            </div>
            {!disabled && <label htmlFor={id}>選択</label>}
          </div>
        );

      default:
        return filePreview ? (
          <Image
            className="file-upload__img"
            height={150}
            src={renderUrl(filePreview)}
            alt="file-upload-image"
          />
        ) : (
          <div>aaaaa</div>
        );
    }
  };

  useEffect(() => {
    if (edit) {
      value?.file && setFilePreview(value?.file);
    }
  }, [edit, value]);

  return (
    <div className="file-upload">
      <div>
        <input
          ref={fileUrlImageRef}
          accept={fileAccept()}
          type="file"
          placeholder="Add your file"
          onChange={onFileChange}
          disabled={disabled}
          id={id}
          style={{ display: 'none' }}
        />
        <RenderPreviewFile />
      </div>
    </div>
  );
};

export default FileUpload;
