const STATUS = [
  { name: 'All', id: 'all' },
  { name: 'Creating', id: 'creating' },
  { name: 'Minted', id: 'minted' },
  { name: 'Selling', id: 'selling' },
  { name: 'Sold', id: 'sold' },
];

const INITIAL_FILTER = {
  name: undefined,
  color: undefined,
  motion: undefined,
  status: undefined,
  ownerAddress: undefined,
  type: undefined,
  creatorAddress: undefined,
  isActive: undefined,
};

const IS_ACTIVE = [
  {
    id: true,
    name: '有効',
  },
  { id: false, name: '無効' },
];

export { INITIAL_FILTER, STATUS, IS_ACTIVE };
