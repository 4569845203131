import { DataResponseList, PageInfoRequest } from './common';

export type SecondNFTFilterForm = {
  name: string;
  ownerAddress: string;
  status: SECOND_NFT_STATUS[];
  type: SECOND_NFT_TYPE[];
  pickUpType: PICK_UP_TYPE[];
};

export type SecondNFTMasterFilterForm = {
  name: string;
  characterIds?: string[];
  character: string;
  sortField?: string;
  desc?: boolean;
  status?: string[] | string;
};

export type SecondNFTFilter = {
  name: string;
  ownerAddress: string;
  status: string;
  type?: string;
  pickUpType: string;
  sortField?: string;
  desc?: boolean;
};

export type SecondNFTFilterRequest = SecondNFTFilter & PageInfoRequest;
export type SecondNFTMasterFilterRequest = SecondNFTMasterFilterForm & PageInfoRequest;

export type SecondNFTListResponse = DataResponseList<SecondNft>;

export type SecondNft = {
  avatar2DObjectKey?: string;
  avatar2DStatus?: string;
  avatarPreview2DId?: number;
  bodyNftId: number;
  bodyNftName: string;
  creatorAddress: string;
  description: string;
  id: number;
  name: string;
  nftId: number;
  ownerId: number;
  ownerName: string;
  presetName: string;
  preview2DId: number;
  preview2DObjectKey: string;
  preview2DStatus: string;
  status: SECOND_NFT_STATUS;
  type: SECOND_NFT_TYPE;
  weaponName: string;
};

type BodyDetail = {
  bodyNftImageObjectKey: string;
  bodyNftImageObjectKeyFileName: string;
  bodyNftImageStatus: string;
};

type Property = {
  property: string;
  value: any;
};

export type SecondNftDetail = {
  body: BodyDetail;
  bodyNftId: number;
  creatorId: number;
  characterId: number;
  characterName: string;
  description: string;
  id: number;
  images: any[];
  nftId: number;
  nftStatus: string;
  preset?: any;
  pickUp?: number;
  presetId?: number;
  reason?: string;
  presetNftId?: number;
  status: SECOND_NFT_STATUS;
  title: string;
  type: SECOND_NFT_TYPE;
  weaponIds?: string;
  weaponNftIds?: string;
  weapons?: any[];
  propertiesSecondNft?: Property[];
  firstNftSimple?: any
};

export type SecondNftMasterRequestData = {
  title: string;
  description: string;
  bodyId: number;
  presetId: number;
  id?: number | string;
  weapons: string;
  isPreset: boolean;
  isActive:boolean;
};

export enum SECOND_NFT_STATUS {
  ALL = '',
  REVIEW = 'REVIEW',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECT = 'REJECT',
}

export enum SECOND_NFT_TYPE {
  ALL = '',
  PRESET = 'COMBINE',
  CUSTOMIZE = 'USER_CUSTOMIZE',
}

export enum PICK_UP_TYPE {
  ALL = 'all',
  PICK_UP = 'pick_up',
  UN_PICK_UP = 'un_pick_up',
}

export const LIST_SECOND_NFT_STATUS = [
  { label: '審査中', value: SECOND_NFT_STATUS.REVIEW },
  { label: 'Mint中', value: SECOND_NFT_STATUS.PENDING },
  { label: '承認済み', value: SECOND_NFT_STATUS.APPROVED },
  { label: 'リジェクト', value: SECOND_NFT_STATUS.REJECT },
];

export const LIST_SECOND_NFT_TYPE = [
  { label: 'プリセット', value: SECOND_NFT_TYPE.PRESET },
  { label: 'ユーザがカスタマイズ', value: SECOND_NFT_TYPE.CUSTOMIZE },
];

export const LIST_PICK_UP_TYPE = [
  { label: '選択する', value: PICK_UP_TYPE.PICK_UP },
  { label: 'アンピックアップ', value: PICK_UP_TYPE.UN_PICK_UP },
];

export enum SECOND_NFT_PROPERTIES {
  TEAM_NAME = 'Country',
  OTHER = 'Other',
  FILE_FORMAT = 'File Format',
}
