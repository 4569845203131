import { Empty, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { SelectProps } from 'antd/es/select';

type ValueType = { key?: string; label: React.ReactNode; value: any };

type Props = {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
} & Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'>;

const DebounceSelect: React.FC<Props> = (props) => {
  const { fetchOptions, debounceTimeout = 500, disabled, ...rest } = props;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  useEffect(() => {
    if (!disabled) {
      setFetching(true);

      fetchOptions('')
        .then((newOptions) => {
          setOptions(newOptions);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [disabled, fetchOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onScroll = (e: any) => {
    const target: any = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // load more goes here
    }
  };

  return (
    <Select
      onPopupScroll={onScroll}
      showSearch
      labelInValue
      disabled={disabled}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      {...rest}
      options={options}
    />
  );
};

export default DebounceSelect;
