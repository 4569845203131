import { API } from 'redux/api-route';
import { Statistics } from 'types/common';
import { UsersType } from 'types/users';
import AxiosUtils from 'utils/axios-utils';

const DashboardService = {
  getRegistration: async (year: number) => {
    try {
      const res: any = await AxiosUtils.get(
        API.registration,
        {year}
      );
      return res.data;
    } catch (error) {
      return
    }
    
  },
  getTransaction: async (year: number) => {
    try {
      const res: any = await AxiosUtils.get(
        API.transaction,
        {year}
      );
      return res.data;
    } catch (error) {
      return
    }
    
  },
  getUser: async (number: number, month: number) => {
    try {
      const res: {data: UsersType[]} = await AxiosUtils.get(
        API.topUser, {
          top: number,
          month
        }
      );
      return res.data;
    } catch (error) {
      return
    }
  },
  getStatistic: async () => {
    try {
      const res: {data: Statistics} = await AxiosUtils.get(
        API.statistic
      );
      return res?.data;
    } catch (error) {
      return
    }
  },
  getNftStatistic: async () => {
    try {
      const res: {data: Statistics} = await AxiosUtils.get(
        API.nftStatistic
      );
      return res?.data;
    } catch (error) {
      return
    }
  }
};

export default DashboardService;
