import { notification } from "antd";
import { API } from "redux/api-route";
import { ResponseBase } from "types/common";
import { IDataRequestTransaction, ITransactionData, ITransactionDetailData } from "types/transactions";
import AxiosUtils from "utils/axios-utils";

const transactionService = {
  getTransactions: async (params: IDataRequestTransaction) => {
    try {
      const response: ResponseBase<ITransactionData> = await AxiosUtils.get(API.getTransaction, params);   
      return response.data
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getTransactionDetail: async (id: number) => {
    try {
      const response: ResponseBase<ITransactionDetailData> = await AxiosUtils.get(`${API.getTransactionDetail}/${id}`)
      return response?.data
      
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  refundConfirm: async (id: number, params: {nftIds: string}) => {
    try {
      const response: any = await AxiosUtils.post(`${API.getTransactionDetail}/${id}/${API.refundTransaction}`, params)
      return [!!response?.data?.isSuccess, '確認に失敗しました']
      
    } catch (error) {
      return [false, error?.message]
    }
  },
}

export default transactionService

