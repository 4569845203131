import { Col, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import {
  LIST_PICK_UP_TYPE,
  LIST_SECOND_NFT_STATUS,
  LIST_SECOND_NFT_TYPE,
  SecondNFTFilterForm,
} from 'types/2st-nft';

type FilterProps = {
  onSubmit: (value: SecondNFTFilterForm) => void;
  onCreate?: () => void;
  onReset: () => void;
};
const { Option } = Select;

const initialValues: SecondNFTFilterForm = {
  name: '',
  ownerAddress: '',
  status: [],
  type: [],
  pickUpType: [],
};

const Filter: React.FC<FilterProps> = (props) => {
  const [form] = Form.useForm();

  const { onSubmit, onCreate, onReset } = props;

  const onClearFilter = () => {
    form.resetFields();
    onReset();
  };

  return (
    <Form form={form} name="register" onFinish={onSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="name" label="タイトル">
            <Input />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="ownerAddress" label="オナーアドレス">
            <Input />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="status" label="状態">
            <Select mode="multiple" allowClear showArrow>
              {LIST_SECOND_NFT_STATUS.map((item) => (
                <Option key={`second_nft_status_key_${item.value}`} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="type" label="タイプ">
            <Select mode="multiple" allowClear showArrow>
              {LIST_SECOND_NFT_TYPE.map((item) => (
                <Option key={`second_nft_type_key_${item.value}`} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="pickUpType" label="ピックアップ">
            <Select mode="multiple" allowClear showArrow>
              {LIST_PICK_UP_TYPE.map((item) => (
                <Option key={`second_nft_type_key_${item.value}`} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }} className="d-flex align-end">
          <Form.Item>
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="検索"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="クリア"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-10"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>

      {onCreate && (
        <Form.Item>
          <CustomButton
            bgColor="primary-green"
            htmlType="button"
            text="検索"
            onClick={onCreate}
            icon={<PlusOutlined />}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default Filter;
