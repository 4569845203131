enum API_PREFIX {
  MANAGEMENT = 'management',
  MASTER = 'master',
}

export const API = {
  // auth
  login: `${API_PREFIX.MANAGEMENT}/admin/login`,
  changePassword: `${API_PREFIX.MANAGEMENT}/admin/change-password`,
  sendEmailForgotPW: `${API_PREFIX.MANAGEMENT}/admin/send-mail-forgot-password`,
  resetPassword: `${API_PREFIX.MANAGEMENT}/admin/reset-password`,
  getUserDetail: `${API_PREFIX.MANAGEMENT}/admin/my-profile`,
  updateUserProfile: `${API_PREFIX.MANAGEMENT}/admin/my-profile`,
  refreshToken: `${API_PREFIX.MANAGEMENT}/admin/refresh-token`,
  logOut: `${API_PREFIX.MANAGEMENT}/admin/logout`,

  // user management
  users: `${API_PREFIX.MANAGEMENT}/users`,
  user: `${API_PREFIX.MANAGEMENT}/users/{{id}}`,
  updateUser: `${API_PREFIX.MANAGEMENT}/users/{{id}}`,

  // faucet management
  faucets: `${API_PREFIX.MANAGEMENT}/faucets`,
  faucet: `${API_PREFIX.MANAGEMENT}/faucets/user/{id}`,

  //get item
  getCharacter: '/characters',
  getNftType: '/master/nftTypes',
  getColors: '/colors',
  getMotions: '/motions',
  getStatus: `${API_PREFIX.MANAGEMENT}/first-nft/filter/statues`,
  getPosition: `/positions`,
  getTransactionStatus: `${API_PREFIX.MANAGEMENT}/transactions/filter/statuses`,

  // 1st nft
  create1stNft: `${API_PREFIX.MANAGEMENT}/first-nft`,
  getList1stNft: `${API_PREFIX.MANAGEMENT}/first-nft`,
  getDetail1stNft: `${API_PREFIX.MANAGEMENT}/first-nft`,
  createPresign: `${API_PREFIX.MANAGEMENT}/first-nft/create/presign`,
  mintNft: `${API_PREFIX.MANAGEMENT}/first-nft/`,
  getList1st2dNft: `${API_PREFIX.MANAGEMENT}/first-nft-simple`,
  create1st2dNft: `${API_PREFIX.MANAGEMENT}/first-nft-simple`,
  createPresign2d: `${API_PREFIX.MANAGEMENT}/first-nft-simple/create/presign`,
  mint2dNft: `${API_PREFIX.MANAGEMENT}/first-nft-simple/`,
  deactive1stNft: `${API_PREFIX.MANAGEMENT}/first-nft/{{id}}/deactive`,
  active1stNft: `${API_PREFIX.MANAGEMENT}/first-nft/{{id}}/active`,

  // 2nd nft
  masterCombineNft: `${API_PREFIX.MASTER}/nft/combined`,
  updateMasterCombineNft: `${API_PREFIX.MASTER}/nft/combined/{{id}}`,
  deleteMasterCombineNft: `${API_PREFIX.MASTER}/nft/combined/{{id}}`,

  masterCreatePresign: `${API_PREFIX.MASTER}/nft/combined/create/presign`,
  getSecondNftList: `${API_PREFIX.MANAGEMENT}/second-nft`,
  getSecondNft: `${API_PREFIX.MANAGEMENT}/second-nft/{{id}}`,
  approveSecondNftVerify: `${API_PREFIX.MANAGEMENT}/second-nft/approve/{{id}}/verify`,
  approveSecond2dNftVerify: `${API_PREFIX.MANAGEMENT}/second-nft-simple/approve/{{id}}/verify`,
  approveSecondNft: `${API_PREFIX.MANAGEMENT}/second-nft/approve/{{id}}/review`,
  approveSecond2dNft: `${API_PREFIX.MANAGEMENT}/second-nft-simple/approve/{{id}}`,
  rejectSecondNft: `${API_PREFIX.MANAGEMENT}/second-nft/reject/{{id}}`,
  rejectSecond2dNft: `${API_PREFIX.MANAGEMENT}/second-nft-simple/reject/{{id}}`,
  pickupNft: `${API_PREFIX.MANAGEMENT}/second-nft/pickup/{{id}}`,
  removePickupNft: `${API_PREFIX.MANAGEMENT}/second-nft/un-pickup/{{id}}`,
  pickup2dNft: `${API_PREFIX.MANAGEMENT}/second-nft-simple/pickup/{{id}}`,
  removePickup2dNft: `${API_PREFIX.MANAGEMENT}/second-nft-simple/un-pickup/{{id}}`,

  getSecondNft2dList: `${API_PREFIX.MANAGEMENT}/second-nft-simple`,

  //get transactions
  getTransaction: `${API_PREFIX.MANAGEMENT}/transactions`,
  getTransactionDetail: `${API_PREFIX.MANAGEMENT}/transactions`,
  refundTransaction: `refund`,

  //admin
  adminManage: `${API_PREFIX.MANAGEMENT}/admin`,
  updateAdmin: `${API_PREFIX.MANAGEMENT}/admin/admin-update`,
  deleteAdmin: `${API_PREFIX.MANAGEMENT}/admin/{{id}}`,

  //character
  getListCharacters: '/characters',

  //notification
  listNoti: `${API_PREFIX.MANAGEMENT}/notifications`,
  createNoti: `${API_PREFIX.MANAGEMENT}/notifications`,
  publishNoti: `${API_PREFIX.MANAGEMENT}/notifications/publish`,
  unpublishNoti: `${API_PREFIX.MANAGEMENT}/notifications/unpublish`,
  createPresignNoti: `${API_PREFIX.MANAGEMENT}/notifications/create/presign`,

  //system-config
  systemConfig: `${API_PREFIX.MANAGEMENT}/systems`,
  getListWalletConfig: `${API_PREFIX.MANAGEMENT}/systems/roles`,
  addWalletConfig: `${API_PREFIX.MANAGEMENT}/systems/set-role`,
  deleteWalletConfig: `${API_PREFIX.MANAGEMENT}/systems/{{id}}`,

  //dasboard-chart
  registerListYear: `${API_PREFIX.MANAGEMENT}/users/register/list-year`,
  transactionListYear: `${API_PREFIX.MANAGEMENT}/users/transaction/list-year`,
  registration: `${API_PREFIX.MANAGEMENT}/users/daily/registration`,
  transaction: `${API_PREFIX.MANAGEMENT}/users/daily/transaction`,
  topUser: `${API_PREFIX.MANAGEMENT}/users/daily/top`,
  statistic: `${API_PREFIX.MANAGEMENT}/users/statistic`,
  nftStatistic: `${API_PREFIX.MANAGEMENT}/users/statistic/nft`,

  // contract
  contracts: `contracts`,


  //contact-us
  inquiry: `${API_PREFIX.MANAGEMENT}/inquiry`,
  inquiryRead: `${API_PREFIX.MANAGEMENT}/inquiry/read`
};
