import { Button, Form, Input, notification } from 'antd';
import logo from 'assets/images/logo.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'redux/api-route';
import { paths } from 'routes/routes';
import AxiosUtils from 'utils/axios-utils';
import { REQUIRED } from 'utils/rule-form';
import styles from './styles.module.scss';

interface FormValues {
  email: string;
}

const SendMailPW = () => {
  const [loading, setLoading] = useState(false);

  const onSendEmailForgotPW = async (values: FormValues) => {
    const data = {
      email: values.email,
    };
    setLoading(true);
    try {
      await AxiosUtils.postToken(API.sendEmailForgotPW, data);
      notification.success({
        message: 'Success',
        description: 'メールに新しいパスワードを送信しました。メールをご確認ください！',
        duration: 4,
      });
    } catch (error: any) {
      if (error.code === 500)
        notification.error({
          message: 'Error',
          description: '入力いただいたメールアドレスは登録されていません。',
          duration: 2,
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.boxSendMailPw}>
      <Form
        layout="vertical"
        name="login-form"
        className={styles.sendMailPwForm}
        onFinish={onSendEmailForgotPW}
      >
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <h2>パスワード再発行</h2>
        <div className={styles.des}>
          登録されているメールアドレス宛てに、パスワードを送信します。
        </div>
        <Form.Item
          name="email"
          label="メール"
          rules={[
            {
              required: true,
              message: 'メールは必須項目です。',
            },

            {
              type: 'email',
              message: 'メールの正しいフォーマットをご入力ください。',
            },
            {
              max: 255,
              message: '名前には255文字以下ご入力ください。',
            },
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input className={styles.sendMailInput} />
        </Form.Item>
        <div className={styles.btnBackLogin}>
          <Link to={paths.login}>ログインはこちら</Link>
        </div>

        <Button
          loading={loading}
          disabled={loading}
          className={styles.btnSend}
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          送信
        </Button>
      </Form>
    </div>
  );
};

export default SendMailPW;
