import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Avatar,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  PaginationProps,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import CustomButton from 'components/custom-button';
import PageTitle from 'components/page-title';
import moment from 'moment';
import { useEffect, useState } from 'react';
import transactionService from 'service/transaction-service';
import {
  IDataRequestTransaction,
  ITransactionDetail,
  ITransactionFilterData,
} from 'types/transactions';
import styles from './styles.module.scss';
import TransactionDetail from './transaction-detail';
import { getAttributes } from 'redux/attributes/actions';
import { ADMIN_ROLE, JP_DATE_FORMAT, TRANSACTION_ATTRIBUTES } from 'constants/common';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'types/redux';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import SelectAttribute from 'components/select-attributes';
import PreviewImageDefault from 'assets/images/preview-image-default.jpeg';
import { shortenName } from 'utils/short-name';
import { formatterCurrency } from 'utils/common';

const LIMIT = 10;

const initialPaging = {
  page: 1,
  limit: LIMIT,
  sortField: 'created_at',
  desc: true,
  needRefund: false,
};

type IPaging = {
  page: number;
  limit: number;
  sortField: string;
  desc: boolean;
  needRefund: boolean;
};

const needRefundOption = [
  {
    label: '全て',
    value: false,
  },
  {
    label: '返金が必要',
    value: true,
  },
];

const { Option } = Select;

const Revenue = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [transactionList, setTransactionList] = useState<ITransactionDetail[]>([]);

  const [totalItem, setTotalItem] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [itemId, setItemId] = useState<number>(1);
  const [paging, setPaging] = useState<IPaging>(initialPaging);

  const [form] = Form.useForm();

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUserDetail);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getTransactionData();
  }, [paging]);

  const getList = async (params: IDataRequestTransaction) => {
    const result: any = await transactionService.getTransactions(params);
    if (result) {
      setTransactionList(result?.items);
      setTotalItem(result?.totalCount);
    }
    setLoading(false);
  };

  const getTransactionData = async () => {
    setLoading(true);
    form.validateFields().then((value: ITransactionFilterData) => {
      const params = {
        name: value?.name,
        status: value?.status,
        ...(value?.txDate?.[0] && { fromDate: moment(value?.txDate?.[0]).format('YYYY-MM-DD') }),
        ...(value?.txDate?.[0] && { toDate: moment(value?.txDate?.[1]).format('YYYY-MM-DD') }),
        ...(value?.needRefund && { needRefund: value?.needRefund }),
        page: paging.page,
        limit: paging.limit,
        sortField: paging.sortField,
        desc: paging.desc,
      };
      getList(params);
    });
  };

  const handelOpenTransactionPopup = (status: string, id: number) => {
    setItemId(id);
    setIsModalOpen(true);
  };

  const handleCloseTransactionPopup = () => {
    setIsModalOpen(false);
  };

  const columns: any = [
    {
      title: 'ID',
      key: 'index',
      dataIndex: 'index',
      align: 'center' as const,
    },
    {
      title: '購入者',
      key: 'buyers',
      align: 'center' as const,
      render: (record: any) => (
        <div className={styles.buyerBox}>
          <Avatar shape="square" size={50} src={record?.userAvatar || PreviewImageDefault} />
          <Tooltip title={record?.userName || ''} className="mt-10">
            {shortenName(record?.userName, 10) || ''}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'アイテム',
      dataIndex: 'nft',
      key: 'nfts',
      align: 'center' as const,
      render: (record: any) => {
        const array = record.split('__AND__');
        return (
          <div className={styles.buyerBox}>
            {array?.map((nft: string, index: number) => {
              return (
                <Tooltip title={nft || ''} className="mt-5" key={index}>
                  {shortenName(nft, 10) || ''}
                </Tooltip>
              );
            })}
          </div>
        );
      },
    },
    {
      title: '金額',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center' as const,
      render: (record: number) => formatterCurrency.format(record),
    },
    {
      title: '状態',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
    },
    {
      title: '購入日付',
      dataIndex: 'purchaseDate',
      key: 'buydate',
      align: 'center' as const,
      render: (record: string) => moment(record).format(JP_DATE_FORMAT),
    },
    {
      title: '返金が必要',
      dataIndex: 'needManualRefund',
      key: 'needRefund',
      align: 'center' as const,
      render: (record: boolean) =>
        record ? <CheckOutlined style={{ fontSize: 15, color: '#4ac86d' }} /> : '',
    },

    {
      title: 'アクション',
      dataIndex: '',
      key: 'confirm',
      align: 'center' as const,
      render: (_: any, record: any) => (
        <Select
          value="アクション"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action-view">
            <div
              onClick={() => handelOpenTransactionPopup(record?.status, record?.id)}
              style={{ textAlign: 'center' }}
            >
              表示
            </div>
          </Option>
        </Select>
      ),
    },
  ];

  const onChange: PaginationProps['onChange'] = (page) => {
    setLoading(true);
    setPaging({ ...paging, page });
  };

  const clearFilter = () => {
    form.resetFields();
    setPaging(initialPaging);
  };

  const transactionListDataTable = transactionList.map((item, index) => ({
    ...item,
    index: index + 1 + (paging.page - 1) * 10,
  }));

  useEffect(() => {
    dispatch(getAttributes(TRANSACTION_ATTRIBUTES.STATUS));
  }, [user]);

  useEffect(() => {
    if (user?.role === ADMIN_ROLE.MASTER_ADMIN || user?.role === ADMIN_ROLE.MEGAHOUSE_ADMIN) {
      getTransactionData();
    }
  }, [paging, user]);

  return (
    <div>
      <PageTitle title="トランザクション" />
      <div>
        <Form onFinish={getTransactionData} form={form} initialValues={{ needRefund: false }}>
          <Row className="custom-form" gutter={15}>
            <Col span={5}>
              <Form.Item label="ユーザー名" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="トランザクション日付" name="txDate">
                <RangePicker placeholder={['開始日', '終了日']} format={JP_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <div>
                <Form.Item label="状態" name="status">
                  <SelectAttribute name={TRANSACTION_ATTRIBUTES.STATUS} limit={LIMIT} />
                </Form.Item>
              </div>
            </Col>
            <Col span={4}>
              <div>
                <Form.Item label="返金が必要?" name="needRefund">
                  <Select options={needRefundOption} />
                </Form.Item>
              </div>
            </Col>
            <Col span={6} className={styles.buttonBox}>
              <CustomButton
                htmlType="submit"
                text="検索"
                bgColor="secondary-green"
                icon={<SearchOutlined />}
              />
              <CustomButton
                text="クリア"
                bgColor="primary-grey"
                icon={<CloseOutlined />}
                className="ml-5"
                onClick={clearFilter}
              />
            </Col>
          </Row>
        </Form>
      </div>
      <div className="mt-10 custom-table custom-form">
        <p>金額: {}</p>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              description="対象データが見つかりません。"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        >
          <Table
            loading={loading}
            columns={columns}
            pagination={false}
            dataSource={transactionListDataTable}
          />
        </ConfigProvider>

        {totalItem > LIMIT && (
          <Pagination current={paging.page} onChange={onChange} total={totalItem} />
        )}
      </div>

      {isModalOpen && (
        <Modal
          width={800}
          title="トランザクション詳細"
          open={isModalOpen}
          closable={false}
          onCancel={handleCloseTransactionPopup}
          footer={false}
        >
          <TransactionDetail
            itemId={itemId}
            handleCloseTransactionPopup={handleCloseTransactionPopup}
          />
        </Modal>
      )}
    </div>
  );
};

export default Revenue;
