import Wrapper from 'decorators/wrapper.decorator';
import AdminManagement from 'pages/admin-management';
import ContactUs from 'pages/contact-us';
import Create1st2dNft from 'pages/create-1st-2d-nft';
import Create1stNft from 'pages/create-1st-nft';
import Create2st2dNft from 'pages/create-2st-2d-nft';
import Create2stNFT from 'pages/create-2st-nft';
import Create2stNFTMaster from 'pages/create-2st-nft/master';
import FaucetManager from 'pages/faucet-manager';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import NotFound from 'pages/not-found';
import NotificationsManagement from 'pages/notifications-management';
import ResetPassword from 'pages/reset-password';
import SendMailPW from 'pages/reset-password/send-mail';
import Revenue from 'pages/revenue';
import SystemConfiguration from 'pages/system-configuration';
import UserManagement from 'pages/user-manager';
import { RouteObject } from 'types/route';

export const paths = {
  home: '/',
  login: '/login',
  notFound: '*',
  userManagement: '/user-management',
  adminProfile: '/admin-profile',
  revenue: '/revenue',
  adminManagement: '/admin-management',
  notificationsManagement: '/notifications-management',
  nftsManagement: '/nfts-management',
  create1stNft: '/create-1st-nft',
  create2stNft: '/create-2nd-nft',
  create2stNftMaster: '/create-2nd-nft-master',
  sendMailForgotPw: '/send-mail-forgot-password',
  resetPassword: '/forgot-password',
  faucetManagement: '/faucet-management',
  systemConfiguration: '/system-configuration',
  characterManagement: '/character-management',
  contactUs: 'contact-us',
  create1st2dNft: '/create-1st-2d-nft',
  create2st2dNft: '/create-2nd-2d-nft',
};

const mainRoutes: RouteObject[] = [
  { path: paths.notFound, component: NotFound },
  { path: paths.login, component: LoginPage, isPublish: true },
  { path: paths.sendMailForgotPw, component: SendMailPW, isPublish: true },
  { path: paths.resetPassword, component: ResetPassword, isPublish: true },
  { path: paths.home, component: HomePage },
  { path: paths.userManagement, component: UserManagement },
  { path: paths.revenue, component: Revenue },
  { path: paths.adminManagement, component: AdminManagement },
  { path: paths.notificationsManagement, component: NotificationsManagement },
  { path: paths.create1stNft, component: Wrapper(Create1stNft) },
  { path: paths.create2stNft, component: Wrapper(Create2stNFT) },
  { path: paths.create2stNftMaster, component: Create2stNFTMaster },
  { path: paths.faucetManagement, component: FaucetManager },
  { path: paths.systemConfiguration, component: SystemConfiguration },
  // { path: paths.characterManagement, component: CharacterManagement },
  { path: paths.contactUs, component: ContactUs },
  { path: paths.create1st2dNft, component: Wrapper(Create1st2dNft) },
  { path: paths.create2st2dNft, component: Wrapper(Create2st2dNft) },
];

export { mainRoutes };
