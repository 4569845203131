import React, { useMemo } from 'react';
import { Card, Col, Row, Select } from 'antd';
import { Chart } from 'components/chart';
import styles from '../index.module.scss';
import { isEqual } from 'lodash';

type IOptions = { value: number; label: number | string };

type Props = {
  title: string;
  data: {
    options: IOptions[];
    defaultValue: any;
    dataChart: any;
  };
  handleFilter: (_, option: any) => void;
};

const BarChart: React.FC<Props> = (props: Props) => {
  const { title, data, handleFilter } = props;

  const step = useMemo(() => {
    return 50;
  }, []);

  const optionsChart = useMemo(() => {
    return {
      responsive: true,
      aspectRatio: 1.8,
      maintainAspectRatio: true,
      resizeDelay: 300,
      barPercentage: data?.dataChart?.length <= 3 ? 0.5 : data?.dataChart?.length <= 6 ? 0.7 : 0.9,
      elements: {
        bar: {
          borderWidth: 1,
          backgroundColor: '#4AC86D',
          borderSkipped: 'start',
          borderRadius: 1,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {},
        subtitle: {},
      },
      scales: {
        x: {
          grid: { display: false },
          border: { width: 0 },
        },
        y: {
          grid: {
            tickWidth: 0,
            color: '#cbcbcb',
          },
          border: {
            width: 0,
            dash: [5, 10],
          },
          ticks: {
            // stepSize: step, // <----- This prop sets the stepSize
            // min: -50,
          },
        },
      },
    };
  }, [step, data?.dataChart?.length]);

  const formatter = new Intl.NumberFormat('en-US');

  return (
    <>
      <Card hoverable className={styles.card}>
        <Row className={styles['chart__header']}>
          <Col span="16">
            <span className={styles['chart__title']}>{title}</span>
          </Col>
          <Col span="8" className="text-right">
            <Select
              className={styles['chart__select']}
              onChange={handleFilter}
              options={data.options}
              defaultValue={data.defaultValue}
            />
          </Col>
        </Row>

        <div className={styles['chart__wrapper']}>
          <p className={styles['chart__total']}>合計: {formatter.format(data.dataChart.total)}</p>
          <Chart type="bar" options={optionsChart} data={data.dataChart.chart} />
        </div>
      </Card>
    </>
  );
};

export default React.memo(BarChart, (preProps, nextProps) => isEqual(preProps, nextProps));
