import { Col, Form, Image, Input, Row, Select, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { DEFAULT_IMAGE } from 'constants/common';
import moment from 'moment';
import React, { useEffect } from 'react';
import { UserManagerAction } from '../index';
import './user-modal.scss';

const { Option } = Select;

const ViewModal = ({ onClose, data }: { onClose: () => void; data: any }) => {
  return (
    <>
      <Row className="view-user-modal" gutter={[24, 24]}>
        <Col span={7} className="userAvatar">
          <Image
            src={data.avatarObjectKey ?? DEFAULT_IMAGE}
            fallback={DEFAULT_IMAGE}
            preview={false}
          />
        </Col>
        <Col span={17}>
          <Row gutter={[24, 24]}>
            <Col span="12">
              <div className="label">ユーザ名</div>
              <div className="value">{data.userName}</div>
            </Col>
            <Col span="12">
              <div className="label">状態</div>
              <div className="value">{data.isBlocked ? 'ロック' : 'アンロック'}</div>
            </Col>

            <Col span="12">
              <div className="label">メールアドレス</div>
              <div className="value">{data.email}</div>
            </Col>
            <Col span="12">
              <div className="label">更新日付</div>
              <div className="value">{moment(data.updatedDate).format('YYYY-MM-DD')}</div>
            </Col>

            <Col span="24">
              <div className="label">Wallet アドレス</div>
              <div className="value">{data.walletAddress}</div>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="text-center">
          <CustomButton bgColor="primary-grey" text="キャンセル" onClick={onClose} />
        </Col>
      </Row>
    </>
  );
};

const DeleteModal = ({
  onSubmit,
  onClose,
  data,
}: {
  onSubmit: (id: string) => void;
  onClose: () => void;
  data: any;
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <>
      <Form form={form} labelAlign="left" className="custom-form">
        <Form.Item label="ユーザ名" name="userName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Wallet アドレス" name="walletAddress">
          <Input disabled />
        </Form.Item>
        <div className="d-flex justify-center" style={{ marginTop: '20px' }}>
          <Space size="large">
            <CustomButton bgColor="primary-red" text="消去" onClick={() => onSubmit(data.id)} />
            <CustomButton
              bgColor="primary-grey"
              text="キャンセル"
              htmlType="button"
              onClick={onClose}
            />
          </Space>
        </div>
      </Form>
    </>
  );
};

const UpdateModal = (props: { onSubmit: (value: any) => void; onClose: () => void; data: any }) => {
  const { onSubmit, onClose, data } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <>
      <Form form={form} onFinish={onSubmit} labelAlign="left" className="custom-form">
        <Form.Item label="ユーザ名" name="userName">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Wallet アドレス" name="walletAddress">
          <Input disabled />
        </Form.Item>
        <div className="d-flex justify-center" style={{ marginTop: '20px' }}>
          <Space size="large">
            <CustomButton bgColor="primary-green" text="保存" htmlType="submit" />
            <CustomButton
              bgColor="primary-grey"
              text="キャンセル"
              htmlType="button"
              onClick={onClose}
            />
          </Space>
        </div>
      </Form>
    </>
  );
};

type UserModalProps = {
  action: UserManagerAction;
  onSubmit: (value: any) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
  data: any;
};

const UserModal: React.FC<UserModalProps> = (props) => {
  const { action, onDelete, onSubmit, onClose, data } = props;
  const RenderView = () => {
    switch (action) {
      case 'view':
        return <ViewModal onClose={onClose} data={data} />;
      case 'update':
        return <UpdateModal onSubmit={onSubmit} onClose={onClose} data={data} />;
      case 'delete':
        return <DeleteModal onSubmit={onDelete} onClose={onClose} data={data} />;
      default:
        return <ViewModal onClose={onClose} data={data} />;
    }
  };
  return (
    <>
      <RenderView />
    </>
  );
};

export default UserModal;
