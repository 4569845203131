import { useWeb3React } from '@web3-react/core';
import { Button, Form, Input, notification } from 'antd';
import logo from 'assets/images/logo.png';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'redux/auth/actions';
import { paths } from 'routes/routes';
import { StorageUtils } from 'utils/local-storage';
import { REQUIRED, VALIDATE_PASSWORD } from 'utils/rule-form';
import { Session } from '../../types/common';
import { RootState } from '../../types/redux';
import { useAppDisPatch } from '../../utils/common';
import { getSession } from '../../utils/session';
import './styles.scss';

interface FormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  const { deactivate } = useWeb3React();
  const session: Session = getSession();
  const isExpired = !!localStorage.getItem('isExpired');

  useEffect(() => {
    if (session && session.accessToken) {
      navigate(paths.home);
    }
  }, [session, navigate]);

  const auth = useSelector((state: RootState) => state.auth);
  const { error, isLoading } = auth;

  const onLogin = (values: FormValues) => {
    const data = {
      email: values.email.trim(),
      password: values.password.trim(),
    };
    dispatch(login(data, navigate));
  };

  useEffect(() => {
    deactivate();
    StorageUtils.clear();
  }, []);

  useEffect(() => {
    if (isExpired) {
      notification.error({
        message: 'Error',
        description: 'トークンの有効期限が切れました',
        duration: 4,
      });      
      console.error(`トークンの有効期限が切れました`);
      localStorage.removeItem('isExpired');
    }
  }, [isExpired]);

  return (
    <div className="login">
      <Form layout="vertical" name="login-form" className="login__form" onFinish={onLogin}>
        <div className="login__logo">
          <img src={logo} alt="" />
        </div>
        <h2>ログイン</h2>
        <Form.Item
          name="email"
          label="メール"
          rules={[
            { ...REQUIRED('メール') },
            {
              type: 'email',
              message: 'メールアドレスまたはパスワードが正しくありません。',
            },
            {
              max: 255,
              message: '名前には255文字以下ご入力ください。',
            },
          ]}
          validateFirst={true}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input className="login__inputStyle" />
        </Form.Item>
        <Form.Item
          label="パスワード"
          name="password"
          rules={[
            REQUIRED('パスワード'),
            VALIDATE_PASSWORD,
            {
              max: 15,
              message:
                'パスワードは8文字以上15文字以内 、アルファベットの 大文字、小文字 、数字、記号の複合で作成してください。',
            },
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input.Password />
        </Form.Item>

        <Button
          loading={isLoading}
          disabled={isLoading}
          className="login__btn_login"
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          ログイン
        </Button>
        {error.message && <div className="login__error">{error.message}</div>}
        <div className="login__forgot___pw">
          <Link to={paths.sendMailForgotPw}>パスワードの忘れ</Link>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
