import {
  Avatar,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Image,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import transactionService from 'service/transaction-service';
import styles from './styles.module.scss';
import moment from 'moment';
import PreviewImageDefault from 'assets/images/preview-image-default.jpeg';
import { shortenName } from 'utils/short-name';
import { formatterCurrency } from 'utils/common';
import CustomButton from 'components/custom-button';
import { IItemRefund, ITransactionDetailData, TRANSACTION_DETAIL_STATUS } from 'types/transactions';
import { REQUIRED } from 'utils/rule-form';

interface IProps {
  itemId: number;
  handleCloseTransactionPopup: () => void;
}

const { Option } = Select;

const TransactionDetail = (props: IProps) => {
  const { itemId, handleCloseTransactionPopup } = props;
  const [transactionDetailData, setTransactionDetailData] = useState<ITransactionDetailData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getTransactionDetailData();
  }, [itemId]);

  const getTransactionDetailData = async () => {
    setLoading(true);
    const result: ITransactionDetailData | undefined =
      await transactionService.getTransactionDetail(itemId);
    result && setTransactionDetailData(result);
    setLoading(false);
  };

  const TableFooter = ({ data }: { data: IItemRefund[] | undefined }) => {
    const totalAmount = data?.reduce(
      (pre: number, curr: IItemRefund) => curr.price * curr.quantity + pre,
      0
    );

    return (
      <div className={styles.totalBox} style={{ paddingRight: '70px' }}>
        <p className={styles.totalText}>Total:</p>{' '}
        {totalAmount && (
          <p className={styles.totalAmount}>{formatterCurrency.format(totalAmount)}</p>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'index',
      align: 'center' as const,
      render: (record: any, _: any, index: number) => index + 1,
    },
    {
      title: '画像',
      dataIndex: 'nftImage',
      key: 'image',
      align: 'center' as const,
      render: (nftImage: string) => (
        <Avatar shape="square" size={64} src={nftImage || PreviewImageDefault} />
      ),
    },
    {
      title: 'タイトル',
      dataIndex: 'nftName',
      key: 'name',
      align: 'center' as const,
      render: (name: string) => (
        <Tooltip title={name || ''} className="mt-10">
          {shortenName(name, 20) || ''}
        </Tooltip>
      ),
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center' as const,
    },

    {
      title: '単価 (￥)',
      key: 'amount',
      align: 'center' as const,
      render: (record: any) => {
        return record?.quantity * record?.price;
      },
    },
    {
      title: '合計 (￥)',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as const,
    },
  ];

  const handleSubmitRefund = async (params: { nftIds: string }) => {
    setLoadingSubmit(true);
    if (itemId) {
      const [isSuccess, message] = await transactionService.refundConfirm(itemId, params);
      isSuccess
        ? notification.success({
            message: 'Success',
            description: 'ユーザーに返金されました。',
            duration: 4,
          })
        : notification.error({
            message: 'Error',
            description: message,
            duration: 4,
          });
      handleCloseTransactionPopup();
    }
    setLoadingSubmit(false);
  };

  const handleSubmitForm = () => {
    form.validateFields().then((value) => {
      const params = { nftIds: value?.nftIds?.toString() };
      handleSubmitRefund(params);
    });
  };

  const onChangeSelectNft = (value: any) => {
    if (!value || value?.length === 0) {
      form.setFieldValue('nftIds', undefined);
    }
  };

  const renderNftsOption = () => {
    const filterNfts = transactionDetailData?.items?.filter(
      (item) => item.nftStatus !== TRANSACTION_DETAIL_STATUS.Success
    );
    return filterNfts?.map((item, index) => {
      return (
        <Option value={item.nftId} key={index}>
          <div className={styles.transactionNftOptions}>
            <Image
              preview={false}
              src={item?.nftImage || PreviewImageDefault}
              width={30}
              height={30}
              style={{ marginRight: 20 }}
            />
            <div className={styles.transactionNftOptionsText}>
              <Tooltip title={item.nftName || ''}>
                {shortenName(item.nftName || '', 20) || ''}
              </Tooltip>
              <p>量: {item.quantity}</p>
            </div>
          </div>
        </Option>
      );
    });
  };

  return (
    <div>
      {loading ? (
        <div className={styles.transactionDetailLoading}>
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <p className={styles.transactionInformationTitle}>Items</p>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description="対象データが見つかりません。"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                dataSource={transactionDetailData?.items}
                columns={columns}
                footer={(currentPageData) => <TableFooter data={transactionDetailData?.items} />}
                pagination={false}
                rowKey="id"
              />
            </ConfigProvider>
          </div>
          <div className="mt-10">
            <Row>
              <Col span={10}>
                <p className={styles.transactionInformationTitle}>購入日付け</p>
              </Col>
              <Col>
                <p>{moment(transactionDetailData?.createdAt).format('YYYY-MM-DD')}</p>
              </Col>
            </Row>
          </div>
          {transactionDetailData?.chargeMethod && (
            <div>
              <Row>
                <Col span={10}>
                  <p className={styles.transactionInformationTitle}>SB トランザクション ID</p>
                </Col>
                <Col span={14}>
                  <p>{transactionDetailData?.chargeMethod}</p>
                </Col>
              </Row>
            </div>
          )}
          <div>
            <Row>
              <Col span={10}>
                <p className={styles.transactionInformationTitle}>状態</p>
              </Col>
              <Col>
                <p>{transactionDetailData?.status}</p>
              </Col>
            </Row>
          </div>
          {transactionDetailData?.message && (
            <div>
              <Row>
                <Col span={10}>
                  <p className={styles.transactionInformationTitle}>メッセージ</p>
                </Col>
                <Col>
                  <p>{transactionDetailData?.message}</p>
                </Col>
              </Row>
            </div>
          )}
          {transactionDetailData?.needManualRefund && (
            <div>
              <Form form={form} className="form-refund">
                <Row gutter={15} className="custom-form">
                  <Col span={12}>
                    <Form.Item label="返金" name="nftIds" rules={[REQUIRED('NFT 払い戻し')]}>
                      <Select
                        mode="multiple"
                        allowClear
                        showArrow
                        placeholder="NFT 払い戻しを選択"
                        onChange={onChangeSelectNft}
                      >
                        {renderNftsOption()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </>
      )}

      <div className={styles.transactionButtonBox}>
        {transactionDetailData?.needManualRefund && !loading && (
          <CustomButton
            bgColor="primary-green"
            text="返金"
            borderRadius={6}
            fontWeight="bold"
            width={92}
            height={46}
            style={{ marginRight: 20 }}
            onClick={handleSubmitForm}
            loading={loadingSubmit}
          />
        )}
        <CustomButton
          bgColor="primary-grey"
          text="キャンセル"
          borderRadius={6}
          fontWeight="bold"
          width={140}
          height={46}
          onClick={handleCloseTransactionPopup}
        />
      </div>
    </div>
  );
};

export default TransactionDetail;
