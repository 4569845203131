export const commonChartOptions = {
  animation: {
    duration: 2000,
    //waterfall delay of datasets
    delay: (context: any) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default') {
        delay = context.dataIndex * 200; // + context.datasetIndex * 200;
      }
      return delay;
    },
  },
};
