import { WalletOutlined } from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import { useWeb3Modal } from '@web3modal/react';
import { MenuProps, notification, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { ADMIN_ROLE } from 'constants/common';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import { shortenAddress } from 'utils/short-name';
import { useAccount } from 'wagmi';
import styles from '../styles.module.scss';
import ProfileMenu from './profile-menu';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

declare global {
  interface Window {
    ethereum?: any;
  }
}

const Header = () => {
  const { account, activate, deactivate } = useWeb3React();
  const user = useSelector(getUserDetail);
  const { open, close } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  // const { disconnect } = useDisconnect();
  const isShowConnectWalletButton = user?.role === ADMIN_ROLE.MEGAHOUSE_ADMIN || user?.role === ADMIN_ROLE.MASTER_ADMIN;

  const connectWalletConnectConnector = () => {
    open();
    // setTimeout(() => {
    //   console.log('connect start')
    //   activate(walletConnectConnector, undefined, true)
    //     .then(() => {
    //       localStorage.setItem('isWalletConnect', 'true');
    //     })
    //     .catch((e) => {
    //       console.log('wallet error: ', e);
    //       notification.error({
    //         message: 'Error',
    //         description: e?.message,
    //         duration: 4,
    //       });
    //     });
    // });
  };

  const disconnectWallet = () => {
    localStorage.removeItem('walletconnect');
    localStorage.removeItem('isWalletConnect');
    deactivate();
    notification.success({
      message: 'Success',
      description: 'ウォレットが切断されました',
      duration: 4,
    });
    window.location.reload();
    // if ([paths.create1stNft, paths.create2stNft].includes(location.pathname)) {
    //   navigate(paths.home);
    // }
  };

  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: (
    //     <div className={styles.walletAddressCopy}>
    //       <CopyToClipboard text={account || ''} onCopy={() => message.success('Copy successfully')}>
    //         <span>
    //           <span className={styles.accountTooltip}>{shortenAddress(account || '', 6, 4)}</span>
    //           <CopyOutlined />
    //         </span>
    //       </CopyToClipboard>
    //     </div>
    //   ),
    // },
    // {
    //   key: '2',
    //   label: (
    //     <CustomButton
    //       bgColor="secondary-green"
    //       text=" Disconnect wallet"
    //       icon={<LogoutOutlined />}
    //       borderRadius={22}
    //       fontWeight="bold"
    //       onClick={disconnectWallet}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    if (address) {      
      Sentry.setUser({ username: address, email: localStorage.getItem('email')?? '' }); 
    }
  }, [address]);

  return (
    <div className={styles.header}>
      <div className={styles.boxInfo}>
        <span>Role： {user?.role}</span>
        <div className={styles.name}>
          {user.name}
          <div className={styles['profile-menu']}>
            <ProfileMenu />
          </div>
        </div>
        {isShowConnectWalletButton && (
          <>
            {isConnected ? (
              <div className={styles.walletAddress} onClick={() => open()}>
                {/* <Dropdown menu={{ items }}> */}
                <div onClick={(e) => e.preventDefault()}>
                  <Space>
                    {shortenAddress(address, 6, 4)}
                    {/* <DownOutlined /> */}
                  </Space>
                </div>
                {/* </Dropdown> */}
              </div>
            ) : (
              <CustomButton
                bgColor="secondary-green"
                text=" Connect wallet"
                type="primary"
                htmlType="submit"
                icon={<WalletOutlined />}
                borderRadius={22}
                fontWeight="bold"
                onClick={connectWalletConnectConnector}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
