import { useWeb3React } from '@web3-react/core';
import { notification } from 'antd';
import 'antd/dist/antd.min.css';
import { Buffer } from 'buffer';
import { ADMIN_ROLE, WALLET_CONNECT_CONFIG } from 'constants/common';
import SidebarLayout from 'layouts/header-layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'redux/api-route';
import { getUserDetailAction } from 'redux/user/action';
import { getUserDetail } from 'redux/user/selector';
import ProtectedRoute from 'routes/protected-route';
import { mainRoutes, paths } from 'routes/routes';
import UserService from 'service/user-service';
import { Session } from 'types/common';
import { ContractInformation } from 'types/contract';
import AxiosUtils from 'utils/axios-utils';
import { walletConnectConnector } from 'utils/connectors';
import { StorageKey, StorageUtils } from 'utils/local-storage';
import './styles/index.scss';
import { AppDispatch } from './types/redux';
import { RouteObject } from './types/route';
import { getSession, getTokenEndStatus, getTokenStatus } from './utils/session';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

const { publicClient } = configureChains(WALLET_CONNECT_CONFIG.chains, [
  w3mProvider({ projectId: WALLET_CONNECT_CONFIG.projectId }),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId: WALLET_CONNECT_CONFIG.projectId,
    chains: WALLET_CONNECT_CONFIG.chains,
  }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, WALLET_CONNECT_CONFIG.chains);

window.Buffer = Buffer;

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const { activate } = useWeb3React();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(getUserDetail);
  const session: Session = getSession();
  const [isLoadingContract, loadingContract] = useState(true);

  // get contract once time

  const getContract = useCallback(async () => {
    const contract = (await AxiosUtils.get(API.contracts)) as { data: ContractInformation };
    try {
      if (contract) {
        StorageUtils.saveObject(StorageKey.CONTRACT, contract.data);
        loadingContract(false)
      } else {
        console.log('error getContract');
      }
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    StorageUtils.remove(StorageKey.CONTRACT);
    const contract = StorageUtils.getObject(StorageKey.CONTRACT);
    if (!contract) {
      getContract();
    }
  }, []);

  const connectWallet = () => {
    setTimeout(() => {
      activate(walletConnectConnector, undefined, true)
        .then(() => {
          localStorage.setItem('isWalletConnect', 'true');
        })
        .catch((e) => {
          console.log('wallet error 2: ', e);
          notification.error({
            message: 'Error',
            description: e?.message,
            duration: 4,
          });
          navigate(paths.home);
        });
    });
  };

  const isValidToken = getTokenStatus();
  const isValidRefreshToken = getTokenEndStatus();

  const handleRefeshToken = async () => {
    await UserService.refreshToken(session?.refreshToken);
    dispatch(getUserDetailAction());
  };

  useEffect(() => {
    if (isValidToken) {
      dispatch(getUserDetailAction());
    } else if (isValidRefreshToken && session) {
      handleRefeshToken();
    }
    // else {
    //   if (location.pathname !== paths.login && session?.accessToken) {
    //     notification.error({
    //       message: 'Error',
    //       description: 'トークンの有効期限が切れました',
    //       duration: 4,
    //     });
    //     navigate(paths.login);
    //   }
    // }
  }, []);

  useEffect(() => {
    const isMegaHouse = user?.role === ADMIN_ROLE.MEGAHOUSE_ADMIN;
    const isUpbond = user?.role === ADMIN_ROLE.UPBOND_ADMIN;
    const isSuper = user?.role === ADMIN_ROLE.MASTER_ADMIN;
    const isNotShowUpbond =
      [
        paths.create1stNft,
        paths.create2stNft,
        paths.create2stNftMaster,
        paths.create1st2dNft,
        paths.create2st2dNft,
        paths.systemConfiguration,
      ].includes(location.pathname) && isUpbond;

    const isNotShowSuper = false;
    if (isNotShowUpbond || isNotShowSuper) {
      navigate(paths.home);
      notification.error({
        message: 'Error',
        description: 'あなたはこのページを見ることができません',
        duration: 4,
      });
    }
    if (
      [
        paths.adminProfile,
        paths.adminManagement,
        paths.characterManagement,
        paths.faucetManagement,
        paths.nftsManagement,
        paths.notificationsManagement,
        paths.contactUs,
        paths.revenue,
      ].includes(location.pathname) &&
      isMegaHouse
    ) {
      navigate(paths.home);
      notification.error({
        message: 'Error',
        description: 'あなたはこのページを見ることができません',
        duration: 4,
      });
    }
    if (
      [paths.create1stNft, paths.create2stNft, paths.create1st2dNft, paths.create2st2dNft].includes(
        location.pathname
      ) &&
      (isMegaHouse || isSuper)
    ) {
      // connectWallet();
    }
  }, [location, user]);

  if (isLoadingContract) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <Routes>
          {mainRoutes.map((route: RouteObject, index: number) => {
            const Component = route.component;
            if (route.isPublish) {
              return <Route key={index} path={route.path} element={<Component />} />;
            }
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <SidebarLayout>
                      <Component />
                    </SidebarLayout>
                  </ProtectedRoute>
                }
              />
            );
          })}
        </Routes>
        <ToastContainer />
      </WagmiConfig>
      <Web3Modal projectId={WALLET_CONNECT_CONFIG.projectId} ethereumClient={ethereumClient} />
    </div>
  );
}

export default App;
