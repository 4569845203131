import axios from 'axios';
import { getTokenEndStatus, getTokenStatus } from 'utils/session';
import { StorageUtils, StorageKey } from 'utils/local-storage';
import { SERVER_API_ENDPOINT } from 'constants/envs';
import UserService from 'service/user-service';
import { API } from 'redux/api-route';

const AxiosClient = axios.create({
  baseURL: SERVER_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'x-upbond-lang': 'ja'
  },
});

const AxiosUrlClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
});

const AxiosClientRefreshToken = axios.create({
  baseURL: SERVER_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});




const reqPaths = [API.logOut, API.login, 'contracts']

AxiosClient.interceptors.request.use(async (req) => {
  const session = StorageUtils.getObject(StorageKey.SESSION);
  const isValidToken = getTokenStatus();
  const isRefesh = getTokenEndStatus();
  const isReq = req.url && !reqPaths.includes(req.url);
  // token expired, get new token
  if (isReq && !isValidToken && isRefesh) {
    const data = await UserService.refreshToken(session?.refreshToken)
    if (req.headers) {
      req.headers['Authorization'] = `Bearer ${data.accessToken}`;
    }
  }
  // token expired and refresh token expried
  else if (isReq && !isValidToken && !isRefesh) {
    localStorage.setItem('isExpired', "true")
    window.location.href = '/login'
    return;
  }
  //token valid
  else if (session && req.headers && isValidToken) {
    req.headers['Authorization'] = `Bearer ${session.accessToken}`;
  }
  return req;
});

AxiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (error?.response?.data) {
      throw error?.response?.data;
    } else {
      throw error.message;
    }
  }
);

const get = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any
): Promise<ResType> => {
  return AxiosClient.get(url, { params, headers: customHeaders });
};

const getApiPublic = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any
): Promise<ResType> => {
  return AxiosClientRefreshToken.get(url, { params, headers: customHeaders });
};


function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}

function postToken<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClientRefreshToken.post(url, data, { headers: customHeaders });
}



function postS3<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosUrlClient.put(url, data, { headers: customHeaders });
}

async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.put(url, data, { headers: customHeaders });
}

async function patch<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.patch(url, data, { headers: customHeaders });
}

const getFormat = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any
): Promise<ResType> => {
  return AxiosUrlClient.get(url, { params, headers: customHeaders });
};



async function _delete<ReqType, ResType>(url: string, params?: ReqType): Promise<ResType> {
  return AxiosClient.delete(url, { params });
}

const AxiosUtils = { get, post, postS3, put, patch, _delete, getFormat, postToken };

export default AxiosUtils;
