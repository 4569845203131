import { Col, ConfigProvider, Empty, Pagination, Row, Select, Table, Tooltip } from 'antd';
import PageTitle from 'components/page-title';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ContactService from 'service/contact-service';
import { ContactDataRes } from 'types/contact';
import MessageDetail from './components/message-detail';
import styles from './styles.module.scss';
import { JP_DATE_FORMAT } from 'constants/common';
import { shortenName } from 'utils/short-name';
import { CheckOutlined } from '@ant-design/icons';

const LIMIT = 10;

type IPaging = {
  page: number;
  limit: number;
  desc: boolean;
  sortField: string;
};

const initialPaging = {
  page: 1,
  limit: LIMIT,
  desc: true,
  sortField: 'created_at',
};

const { Option } = Select;

const ContactUs = () => {
  const [paging, setPaging] = useState<IPaging>(initialPaging);
  const [dataTable, setDataTable] = useState<ContactDataRes[]>();
  const [totalItem, setTotalItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<ContactDataRes>();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center' as const,
    },
    {
      title: 'ユーザー名',
      key: 'userName',
      dataIndex: 'userName',
      align: 'center' as const,
      render: (name: string) => <Tooltip title={name}>{shortenName(name, 20) || ''}</Tooltip>,
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      align: 'center' as const,
      render: (content: string) => (
        <Tooltip title={content}>{shortenName(content, 20) || ''}</Tooltip>
      ),
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      align: 'center' as const,
      render: (content: string) => (
        <Tooltip title={content}>{shortenName(content, 20) || ''}</Tooltip>
      ),
    },
    {
      title: '作成日付',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center' as const,
      render: (date) => {
        return moment(date).format(JP_DATE_FORMAT);
      },
    },
    {
      title: 'ステータス',
      dataIndex: 'isRead',
      key: 'isRead',
      align: 'center' as const,
      render: (isRead: number) =>
        isRead === 1 ? <CheckOutlined style={{ fontSize: 15, color: '#4ac86d' }} /> : '',
    },
    {
      title: 'アクション',
      align: 'center' as const,
      key: 'action',
      width: 120,
      render: (record: any) => (
        <Select
          value="アクション"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action-view">
            <div
              onClick={() => {
                setDetail(record);
                setOpenDetail(true);
              }}
              style={{ textAlign: 'center' }}
            >
              表示
            </div>
          </Option>
        </Select>
      ),
    },
  ];

  const getListMessage = async () => {
    setLoading(true);
    const result = await ContactService.getListMessage(paging);
    if (result) {
      setDataTable(result.items);
      setTotalItem(result.totalCount);
    }

    setLoading(false);
  };

  const handleChangePage = (page: number) => {
    setPaging((oldState) => {
      return { ...oldState, page };
    });
  };

  const handleResetDataList = () => {
    setPaging({ ...paging });
  };

  useEffect(() => {
    getListMessage();
  }, [paging]);

  return (
    <div>
      <PageTitle title="お問い合わせ" />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24} md={24}>
          <div className="mt-10 custom-form">
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description="対象データが見つかりません。"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={columns}
                dataSource={dataTable}
                pagination={false}
                className="ant-border-space"
                // scroll={{ x: 1200, y: 500 }}
                loading={loading}
              />
            </ConfigProvider>
          </div>
          {totalItem > LIMIT && (
            <Pagination
              className={styles['nft-pagination']}
              showSizeChanger={false}
              current={paging.page}
              onChange={handleChangePage}
              showLessItems
              total={totalItem}
            />
          )}
        </Col>
      </Row>
      {openDetail && (
        <MessageDetail
          detail={detail}
          handleClose={() => setOpenDetail(false)}
          handleResetDataList={handleResetDataList}
        />
      )}
    </div>
  );
};

export default ContactUs;
