import { Col, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import { SecondNFTMasterFilterForm } from 'types/2st-nft';
import { NFT_ATTRIBUTES } from 'constants/common';
import SelectAttribute from 'components/select-attributes';

type FilterProps = {
  onSubmit: (value: SecondNFTMasterFilterForm) => void;
  onCreate?: () => void;
  onReset: () => void;
};
const { Option } = Select;

const initialValues: SecondNFTMasterFilterForm = {
  name: '',
  characterIds: [],
  character: '',
  status: [''],
};

const MasterFilter: React.FC<FilterProps> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit, onCreate, onReset } = props;

  const onClearFilter = () => {
    form.resetFields();
    onReset();
  };

  return (
    <Form form={form} name="register" onFinish={onSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="name" label="タイトル">
            <Input />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="characterIds" label="人物">
            <SelectAttribute name={NFT_ATTRIBUTES.CHARACTER} multiple limit={100} />
          </Form.Item>
        </Col>
        <Col
          md={{ span: 6 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
          style={{ display: 'flex', alignItems: 'end', marginBottom: '5px' }}
        >
          <div className="mb-5">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="検索"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="クリア"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-10"
              onClick={onClearFilter}
            />
          </div>
        </Col>
      </Row>
      {onCreate && (
        <Form.Item>
          <CustomButton
            bgColor="primary-green"
            htmlType="button"
            text="新規登録"
            onClick={onCreate}
            icon={<PlusOutlined />}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default MasterFilter;
