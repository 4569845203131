import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { API } from 'redux/api-route';
import { IDataAddWalletConfig } from 'types/admin';
import { PageInfoRequest } from 'types/common';
import AxiosUtils from 'utils/axios-utils';

const SystemService = {
  getInfoSystemConfig: async () => {
    try {
      const res: any = await AxiosUtils.get(API.systemConfig);
      return res.data;
    } catch (error) {
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
    }
  },

  updateSystemConfig: async (data: any) => {
    try {
      await AxiosUtils.post(API.systemConfig, data);
      notification.success({
        message: 'Success',
        description: 'Update system config successfully!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
    }
  },

	getListWalletConfig: async (dataRequest: PageInfoRequest) => {
		try {
		const res: any = await AxiosUtils.get(API.getListWalletConfig, dataRequest);
		return res.data
		} catch (error) {
			notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
		}
	},

	addWalletConfig: async (data: IDataAddWalletConfig) => {
		try {
			const res: any = await AxiosUtils.post(API.addWalletConfig, data);
			notification.success({
        message: 'Success',
        description: '新しいウォレットアドレスが追加されました。',
        duration: 4,
      });
			return [res, null]
		} catch (error) {
			if (error?.code === 400) {
				notification.error({
					message: 'Error',
					description: 'このウォレットアドレスは既に使用されています。別のアドレスを試してください。',
					duration: 4
				})
			} else if (error?.message?.includes('network does not support') || error?.message?.includes('bad address checksum')) {
				notification.error({
					message: 'Error',
					description: 'このウォレットアドレスは存在しません。別のアドレスを試してください。',
					duration: 4
				})
			}
		}
	},

	deleteWalletConfig: async (id: string) => {
		try {
				await AxiosUtils._delete(API.deleteWalletConfig.replace('{{id}}', id));
				notification.success({ message: 'Success', description: 'ウォレットアドレスを削除されました。', duration: 4 });
		} catch (error) {
				notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
		}
	},
};

export default SystemService;
