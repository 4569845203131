/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice } from "@reduxjs/toolkit";
import { User } from "types/account"
import { getUserDetailAction, updateUserProfile } from "./action";

type InitialState = {
    data: User,
    error: Error | null,
    loading: boolean,
}

const initialState: InitialState = { data: {} as User, loading: false, error: null }

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDetailAction.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getUserDetailAction.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false
        })
        builder.addCase(getUserDetailAction.rejected, (state, action) => {
            // state.error = action.payload;
            state.loading = false
        })
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.data = action.payload!;
        })
    }
});

// export const { } =
//     userSlice.actions;

export default userSlice.reducer;