import { Form, Input, Modal, Select, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { ADMIN_ROLE } from 'constants/common';
import { useEffect, useState } from 'react';
import AdminService from 'service/admin-service';
import { DataPost } from 'types/admin';
import { VALIDATE_PASSWORD } from 'utils/rule-form';
import styles from '../styles.module.scss';
const { Option } = Select;
interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueAdminSelected: any;
}

const ModalCreateEditAdmin = ({
  isOpen,
  valueAdminSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: DataPost) => {
    setLoading(true);
    if (valueAdminSelected?.id) {
      if (!values.password) {
        delete values?.password;
      }
      const [res, error]: any = await AdminService.updateAdmin({
        ...values,
        id: valueAdminSelected?.id,
      }).finally(() => {
        setLoading(false);
      });
      if (res?.status === 201) {
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
      }
    } else {
      const [res, error]: any = await AdminService.updateAdmin(values).finally(() => {
        setLoading(false);
      });
      if (res?.status === 201) {
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      role: valueAdminSelected?.role || undefined,
      name: valueAdminSelected?.name || '',
      email: valueAdminSelected?.email || '',
      password: '',
    });
  }, [form, valueAdminSelected]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueAdminSelected?.id ? '管理者情報を変更' : '管理者の新規追加'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            role: valueAdminSelected?.role || undefined,
            name: valueAdminSelected?.name || '',
            email: valueAdminSelected?.email || '',
            password: '',
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="role">
            権限
          </label>
          <Form.Item
            rules={[{ required: true, message: '権限は必須項目です。' }]}
            name="role"
            wrapperCol={{ span: 24 }}
          >
            <Select value={ADMIN_ROLE.MASTER_ADMIN} placeholder="選択ください" size="large">
              <Option value={ADMIN_ROLE.MASTER_ADMIN}>Master Admin</Option>
              <Option value={ADMIN_ROLE.UPBOND_ADMIN}>Upbond</Option>
              <Option value={ADMIN_ROLE.MEGAHOUSE_ADMIN}>Mega House</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="name">
            名前
          </label>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: '名前は必須項目です。' },
              {
                max: 20,
                message: '名前には20文字以下ご入力ください。',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="管理者者名を入力ください" size="large" />
          </Form.Item>
          <label className={styles.labelInput} htmlFor="email">
            メールアドレス
          </label>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'メールアドレスは必須項目です。' },
              {
                type: 'email',
                message: 'メールアドレスの正しいフォーマットをご入力ください。',
              },
              {
                max: 255,
                message: '名前には255文字以下ご入力ください。',
              },
            ]}
            validateFirst={true}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="メールアドレスを入力ください" size="large" />
          </Form.Item>
          <label className={styles.labelInput} htmlFor="password">
            パスワード
          </label>
          <Form.Item
            name="password"
            rules={[
              {
                required: !valueAdminSelected?.id,
                message: 'パスワードは必須項目です。',
              },
              VALIDATE_PASSWORD,
              {
                max: 15,
                message:
                  'パスワードは8文字以上15文字以内 、アルファベットの 大文字、小文字 、数字、記号の複合で作成してください。',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input.Password size="large" placeholder="パスワードを入力してください" />
          </Form.Item>
          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="保存"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="キャンセル"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEditAdmin;
