import { Alert, Col, Row, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { FILE_TYPE } from 'constants/common';
import { get, isEmpty } from 'lodash';
import SelectAllWeapon from 'pages/create-1st-nft/components/select-all-weapon';
import { useMemo } from 'react';
import {
  LIST_SECOND_NFT_STATUS,
  LIST_SECOND_NFT_TYPE,
  SecondNftDetail,
  SECOND_NFT_STATUS,
  SECOND_NFT_TYPE,
  SECOND_NFT_PROPERTIES,
} from 'types/2st-nft';
import ModelCard from './model-card';

type FooterProps = { status: SECOND_NFT_STATUS; pickUp?: number } & DetailModalProps;

const Footer: React.FC<FooterProps> = ({ pickUp, status, onReject, onApprove, onClose }) => {
  let buttons: any = [];

  switch (status) {
    case SECOND_NFT_STATUS.REJECT:
      buttons = [{ label: 'OK', color: 'primary-grey', onClick: onClose }];
      break;
    case SECOND_NFT_STATUS.APPROVED:
      if (pickUp === 0) {
        buttons = [
          {
            label: 'ピックアップする',
            color: 'primary-green',
            onClick: () => onApprove({ isPickUp: true }),
          },
          { label: 'キャンセル', color: 'primary-grey', onClick: onClose },
        ];
      } else if (pickUp === 1) {
        buttons = [
          {
            label: '再ピックアップ',
            color: 'primary-green',
            onClick: () => onApprove({ isPickUp: true }),
          },
          {
            label: 'アンピックアップ',
            color: 'primary-green',
            onClick: () => onApprove({ isPickUp: false }),
          },
          { label: 'キャンセル', color: 'primary-grey', onClick: onClose },
        ];
      }
      break;

    default:
      buttons = [
        {
          label: 'リジェクト',
          color: 'primary-outline-red',
          onClick: onReject,
        },
        { label: '承認', color: 'primary-green', onClick: onApprove },
        { label: 'キャンセル', color: 'primary-grey', onClick: onClose },
      ];
  }

  return (
    <>
      <Space size={24}>
        <>
          {buttons.map((item: any, index: number) => (
            <CustomButton
              key={`detail-modal-btn-${index}`}
              bgColor={item.color}
              text={item.label}
              onClick={item.onClick}
            />
          ))}
        </>
      </Space>
    </>
  );
};

type DetailModalProps = {
  isLoading: boolean;
  onClose: () => void;
  onReject: () => void;
  onApprove: (params: any) => void;
  data: SecondNftDetail;
};

const Detail2stModal: React.FC<DetailModalProps> = ({
  isLoading,
  onClose,
  onReject,
  onApprove,
  data,
}) => {
  const status = data.status;
  const pickUp = data.pickUp;
  // const characters: any = useSelector((state: RootState) =>
  //   get(state, `attributes.data.${NFT_ATTRIBUTES.CHARACTER}`, [])
  // );

  const dataTransformed: any = useMemo(() => {
    let master: any = {};
    let body: any = {};
    let weapon: any = [];
    let preset: any = {};
    let isCustomize = false;

    if (!isEmpty(data)) {
      let teamName = '';
      let other = '';
      let fileType = '';

      const {
        images,
        title,
        description,
        status,
        type,
        body: rawBody,
        characterName,
        preset: rawPreset,
        weapons = [],

        propertiesSecondNft = [],
      } = data;

      propertiesSecondNft.forEach((item) => {
        switch (item.property) {
          case SECOND_NFT_PROPERTIES.TEAM_NAME:
            teamName = item.value;
            break;
          case SECOND_NFT_PROPERTIES.FILE_FORMAT:
            fileType = item.value;
            break;
          case SECOND_NFT_PROPERTIES.OTHER:
            other = item.value;
            break;
        }
      });

      master = {
        characterName,
        images,
        title,
        description,
        status: LIST_SECOND_NFT_STATUS.find((item) => item.value === status)?.label ?? status,
        type: LIST_SECOND_NFT_TYPE.find((item) => item.value === type)?.label ?? type,
        teamName,
        originBody: get(rawBody, 'bodyNftTitle'),
        other,
        fileType,
      };
      body = {
        images: [{ type: FILE_TYPE.PREVIEW, objectKey: rawBody.bodyNftImageObjectKey }],
        ...rawBody,
        title: get(rawBody, 'bodyNftTitle'),
        description: get(rawBody, 'bodyNftDescription'),
        type: 'body',
      };
      weapon = weapons.map((item) => ({
        positionId: item.positionId,
        weapon: {
          id: item.id,
          objectKey: item.images?.find?.((i) => i.type === FILE_TYPE.PREVIEW2D)?.objectKey,
          title: item.title,
        },
      }));
      preset = { ...rawPreset };
      isCustomize = type === SECOND_NFT_TYPE.CUSTOMIZE;
    }

    return { master, body, weapon, preset, isCustomize, isPreset: !isEmpty(preset) };
  }, [data]);
  return (
    <div style={{ padding: '0 3rem' }}>
      <Row>
        <ModelCard
          loading={isLoading}
          cardName="NFT化する二次創作モデル"
          type="2nd"
          data={dataTransformed.master}
          isCustomize={dataTransformed.isCustomize}
        />
      </Row>
      <Row gutter={[30, 0]} className="mb-10">
        <Col span={dataTransformed.isCustomize ? 18 : 12}>
          <ModelCard
            loading={isLoading}
            cardName="素体類"
            type="body"
            data={dataTransformed.body}
          />
        </Col>
        {!dataTransformed.isCustomize && (
          <Col span="12">
            {dataTransformed.isPreset ? (
              <ModelCard
                loading={isLoading}
                cardName="プリセット類"
                type="presets"
                data={dataTransformed.preset}
              />
            ) : (
              <>
                {!isLoading && (
                  <SelectAllWeapon
                    expand
                    disabled
                    additionalParams={{ character: data.characterId }}
                    value={dataTransformed.weapon}
                  />
                )}
              </>
            )}
          </Col>
        )}
      </Row>

      {status === SECOND_NFT_STATUS.REJECT && (
        <Alert
          style={{ width: '100%', margin: '2rem 0' }}
          message={
            <div className="d-flex">
              <div style={{ marginRight: '2rem' }}>リジェクトの理由</div>
              <div>
                <b>{data.reason}</b>
              </div>
            </div>
          }
          type="success"
        />
      )}

      <div className="text-center" style={{ marginTop: '2rem' }}>
        <Footer
          data={data}
          status={status}
          pickUp={pickUp}
          onClose={onClose}
          onReject={onReject}
          onApprove={onApprove}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Detail2stModal;
