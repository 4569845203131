import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ArcElement,
  ChartTypeRegistry,
} from 'chart.js';
import { Chart as ChartJS2 } from 'react-chartjs-2';
import { merge } from 'lodash';
import { commonChartOptions } from './config';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  ArcElement
);

export const Chart = ({
  options,
  data,
  type,
  chartRef,
  ...props
}: {
  type: keyof ChartTypeRegistry;
  options: any;
  data: any;
  chartRef?: any;
}) => {
  const mergedOptions = merge(options, commonChartOptions);

  return (
    <ChartJS2 ref={chartRef} type={type} options={{ ...mergedOptions }} data={data} {...props} />
  );
};
