import { Col, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';

const { Option } = Select;

type FilterProps = {
  onSubmit: (value: any) => void;
  onReload: () => void;
};

const Filter: React.FC<FilterProps> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit, onReload } = props;

  const onClearFilter = () => {
    form.resetFields();
    onReload();
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onSubmit}
      initialValues={{
        name: '',
        email: '',
        walletAddress: '',
        isBlocked: '',
      }}
    >
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="name" label="名前">
            <Input />
          </Form.Item>
        </Col>
        {/* <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="email" label="メールアドレス">
            <Input />
          </Form.Item>
        </Col> */}
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="walletAddress" label="Walletアドレス">
            <Input />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="isBlocked" label="状態">
            <Select>
              <Option value="">全て</Option>
              <Option value={true}>ロック</Option>
              <Option value={false}>アンロック</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          md={{ span: 6 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
          style={{ display: 'flex', alignItems: 'end', marginBottom: '10px' }}
        >
          <CustomButton
            bgColor="secondary-green"
            htmlType="submit"
            text=" 検索"
            icon={<SearchOutlined />}
          />
          <CustomButton
            text="クリア"
            bgColor="primary-grey"
            icon={<CloseOutlined />}
            className="ml-5"
            onClick={onClearFilter}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
