import { Result } from 'antd';
import CustomButton from 'components/custom-button';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';

interface Props {
  children?: ReactNode;
  navigate: NavigateFunction;
}

interface State {
  hasError: boolean;
}

export const withRouter = (Component: typeof React.Component) => {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };

  return Wrapper;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.onBackHomeClick = this.onBackHomeClick.bind(this);
  }

  onBackHomeClick() {
    this.setState({ hasError: false });
    this.props.navigate(paths.home);
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', JSON.stringify(error), errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="サーバーエラーが発生しました。しばらく経ってから再度お試しください。"
          extra={<CustomButton onClick={this.onBackHomeClick} text="Back Home" />}
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
