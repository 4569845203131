import { Row, Col, Input, Space, Form, Card, Radio } from 'antd';
import Meta from 'antd/lib/card/Meta';
import TextArea from 'antd/lib/input/TextArea';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import DebounceSelect from 'components/debounce-select';
import ModelViewer from 'components/model-viewer';
import SelectAttribute from 'components/select-attributes';
import { NFT_ATTRIBUTES, LIMIT, UPLOAD_TYPE, NFT_STATUS, FILE_TYPE } from 'constants/common';
import { get, isEmpty } from 'lodash';
import SelectAllWeapon from 'pages/create-1st-nft/components/select-all-weapon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import nftService from 'service/nft-service';
import { CHECK_FILE, REQUIRED } from 'utils/rule-form';
import ModelCard from './model-card';
import './model-preview-card.scss';

type Props = {
  onFinish: (value: any) => void;
  onClose: () => void;
  data: any;
  edit?: boolean;
  onDelete: (id: number) => void;
};

const MIN_WEAPON_CHOOSE = 1;
let initialFetch = true;

const Create2stModal: React.FC<Props> = ({ onClose, onFinish, data, edit, onDelete }) => {
  const [previewInfo, setPreviewInfo] = useState(
    {} as { title: string; description: string; model: string }
  );
  const [form] = Form.useForm();
  const [body, setBody] = useState();
  const [preset, setPreset] = useState();
  const characterId: number | undefined = Form.useWatch('characterId', form);
  const isPreset = Form.useWatch('isPreset', form);

  const [loadingWeaponPreset, setLoadingWeaponPreset] = useState(false);
  const [loadingBody, setLoadingBody] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      initialFetch = true;

      const {
        id,
        characterId,
        name: title,
        description,
        images,
        bodyId: body,
        preset,
        type,
        weapon: listWeapons = [],
      } = data;
      let isPreset = true;
      let preview: any = null;
      let model: any = null;
      let preview2d: any = null;

      const presetId = get(preset, 'id');

      if (type === 'weapon') {
        isPreset = false;
      }

      images?.forEach((item: any, index: number) => {
        if (item?.type === FILE_TYPE.PREVIEW) preview = item;
        if (item?.type === FILE_TYPE.PREVIEW2D) preview2d = item;
        if (item?.type === FILE_TYPE.MODEL) model = item;
      });

      form.setFieldsValue({
        id,
        characterId,
        preview2D: { file: preview2d?.objectKey, fileKey: preview2d?.name },
        preview: { file: preview?.objectKey, fileKey: preview?.name },
        model: { file: model?.objectKey, fileKey: model?.name },
        title,
        description,
        body,
        preset: presetId,
        isPreset,
        listWeapons: listWeapons.map((item: any) => ({
          positionId: item.positionId,
          weapon: {
            id: item.id,
            objectKey: item.preview2DObjectKey,
            title: item.title,
          },
        })),
      });

      setPreviewInfo({ title, description, model: preview?.objectKey });
      body && hanlderBodyChangeSelect({ value: body });
      preset && hanlderPresetChangeSelect({ value: presetId });

      setTimeout(() => {
        initialFetch = false;
      }, 100);
    }
  }, [data]);

  const hanlderBodyChangeSelect = async (selectedOption: any) => {
    try {
      setLoadingBody(true);
      const selectedBody = await nftService.getDetail1stNft(selectedOption.value);
      setBody(selectedBody as any);
    } finally {
      setLoadingBody(false);
    }
  };

  const hanlderPresetChangeSelect = async (selectedOption: any) => {
    try {
      setLoadingWeaponPreset(true);
      const selectedPreset = await nftService.getDetail1stNft(selectedOption.value);
      setPreset(selectedPreset as any);
    } finally {
      setLoadingWeaponPreset(false);
    }
  };

  const filterOptions = useCallback(
    async (type: string, query: string) => {
      const queryObj = {
        limit: 20,
        page: 1,
        type,
        name: query,
        creatorAddress: '',
        ownerAddress: '',
        status: [NFT_STATUS.MINTED, NFT_STATUS.SELLING, NFT_STATUS.SOLD, NFT_STATUS.BURNED].join(
          ','
        ),
        sortField: 'title',
        desc: false,
        character: characterId,
      };

      const response = await nftService.getList1stNft(queryObj);

      return response.items.map((item: any) => ({
        id: `filter_option_${type}_${item.id}`,
        label: item.name,
        value: item.id,
      }));
    },
    [characterId]
  );

  const fetchBody = useCallback((value) => filterOptions('body', value), [filterOptions]);
  const fetchWeaponPreset = useCallback((value) => filterOptions('preset', value), [filterOptions]);

  const onPreviewInfoChange = (e: any) => {
    setPreviewInfo((preState) => ({ ...preState, [e.target.name]: e.target.value }));
  };

  const onUploadPreview = ({ fileKey, file }: any) => {
    const fileUrl = URL.createObjectURL(file);
    onPreviewInfoChange({ target: { name: 'model', value: fileUrl } });
  };

  const onCharacterChange = (value) => {
    setBody(undefined);
    setPreset(undefined);
    form.setFieldValue('body', null);
    form.setFieldValue('preset', null);
  };

  const additionalParams = useMemo(() => {
    return characterId
      ? {
          character: characterId,
        }
      : undefined;
  }, [characterId]);

  useEffect(() => {
    if (!initialFetch && characterId) {
      form.setFieldValue('listWeapons', []);
    }
  }, [characterId]);

  return (
    <>
      <Form
        name="create-2nd-nft-form"
        form={form}
        onFinish={onFinish}
        initialValues={{ isPreset: true }}
      >
        <Row gutter={30} className="custom-form">
          <Col span={12}>
            <Form.Item name="id" hidden></Form.Item>

            <Form.Item name="characterId" label="キャラクター" rules={[REQUIRED('キャラクター')]}>
              <SelectAttribute
                name={NFT_ATTRIBUTES.CHARACTER}
                limit={LIMIT}
                onChange={onCharacterChange}
              />
            </Form.Item>
            <Form.Item name="preview2D" label="プレビュー画像 (2D)" rules={[CHECK_FILE()]}>
              <CustomizedUpload edit={edit} type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
            </Form.Item>
            <Form.Item
              name="preview"
              label="プレビュー画像 (3D)"
              rules={[CHECK_FILE('プレビュー画像 (3D)')]}
            >
              <CustomizedUpload
                edit={edit}
                type={UPLOAD_TYPE.PREVIEW}
                maxFileSize={250}
                enablePreview={false}
                onChange={onUploadPreview}
              />
            </Form.Item>
            <Form.Item
              name="model"
              label="モデルデータのアップロード"
              rules={[CHECK_FILE('3Dモデルデータ')]}
            >
              <CustomizedUpload
                edit={edit}
                type={UPLOAD_TYPE.MODEL}
                maxFileSize={250}
                enablePreview={false}
              />
            </Form.Item>
            <Form.Item label="タイトル" name="title" rules={[REQUIRED('タイトル')]}>
              <Input
                placeholder="タイトルを入力してください"
                name="title"
                onChange={onPreviewInfoChange}
                maxLength={150}
              />
            </Form.Item>
            <Form.Item label="説明" name="description">
              <TextArea
                placeholder="説明を入力してください"
                name="description"
                rows={3}
                onChange={onPreviewInfoChange}
                maxLength={750}
              />
            </Form.Item>
            <Form.Item name="isPreset">
              <Radio.Group disabled={!characterId || edit}>
                <Radio value={true}> 素体-プリセット組合 </Radio>
                <Radio value={false}> 素体-武器組合 </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Card
              title="NFTプレビュー:"
              className={'master-preview-card'}
              cover={
                <div className={'model-wrapper'}>
                  <ModelViewer
                    src={previewInfo.model}
                    width="100%"
                    height="100%"
                    background="transparent"
                  />
                </div>
              }
            >
              <div style={{ margin: '2.4rem 0' }}>
                <Meta title={previewInfo.title} description={previewInfo.description} />
              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={30} className="custom-form" style={{ marginTop: '3rem' }}>
          <Col span={12}>
            <Form.Item label="ベース素体" name="body" rules={[REQUIRED('ベース素体')]}>
              <DebounceSelect
                disabled={!characterId}
                className="w-100"
                fetchOptions={fetchBody}
                onChange={hanlderBodyChangeSelect}
              />
            </Form.Item>
            {body && <ModelCard type="body" loading={loadingBody} data={body} />}
          </Col>
          <Col span={12}>
            {isPreset ? (
              <>
                <Form.Item label="プリセット類" name="preset" rules={[REQUIRED('プリセット類')]}>
                  <DebounceSelect
                    className="w-100"
                    fetchOptions={fetchWeaponPreset}
                    onChange={hanlderPresetChangeSelect}
                    disabled={!characterId}
                  />
                </Form.Item>
                {preset && <ModelCard loading={loadingWeaponPreset} type="presets" data={preset} />}
              </>
            ) : (
              <Form.Item
                label="武器"
                name="listWeapons"
                validateFirst={true}
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: `少なくとも ${MIN_WEAPON_CHOOSE} つの武器を選択する必要があります`,
                  },
                  {
                    message: `少なくとも ${MIN_WEAPON_CHOOSE} つの武器を選択する必要があります`,
                    validator: (_, weapon) => {
                      const newWeapon = weapon.filter((item: any) => !!item?.weapon?.id);
                      if (newWeapon?.length >= MIN_WEAPON_CHOOSE) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          `少なくとも ${MIN_WEAPON_CHOOSE} つの武器を選択する必要があります`
                        );
                      }
                    },
                  },
                ]}
              >
                <SelectAllWeapon
                  expand
                  disabled={!characterId}
                  additionalParams={additionalParams}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <div className="text-center" style={{ marginTop: '2.5rem' }}>
          <Space size={24}>
            <CustomButton
              htmlType="submit"
              text="保存"
              bgColor="primary-green"
              color="white"
              width={100}
            />
            {edit && (
              <CustomButton
                text="削除"
                bgColor="primary-red"
                onClick={() => onDelete(data.id)}
                width={100}
              />
            )}
            <CustomButton text="キャンセル" bgColor="primary-grey" onClick={onClose} width={100} />
          </Space>
        </div>
      </Form>
    </>
  );
};

export default Create2stModal;
