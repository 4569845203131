import { createSlice } from '@reduxjs/toolkit';
import { YearType } from 'types/common';

interface InitialState {
  yearsRegister: {
    data: YearType[];
    isLoading: boolean;
  };
  yearsTransaction: {
    data: YearType[];
    isLoading: boolean;
  };

}
const initialState: InitialState = {
  yearsRegister: {
    data: [],
    isLoading: false
  },
  yearsTransaction: {
    data: [],
    isLoading: false
  },
};

const chartsSlice = createSlice({
  name: 'charts',
  initialState: initialState,
  reducers: {
    getYearsLoading: (state, action) => {
      const key = action?.payload?.key;
      state[key].isLoading= true;
    },
    getYearsSuccess: (state, action?) => {
      const key = action?.payload?.key;
      state[key].data =  action?.payload?.data;
      state[key].isLoading = false;
    },
    getYearsFailure: (state, action) => {
      const key = action?.payload?.key;
      state[key].isLoading = false;
      state[key].data= []
    },
  },
});

export const { getYearsLoading, getYearsSuccess, getYearsFailure } =
chartsSlice.actions;

export default chartsSlice.reducer;
