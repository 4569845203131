import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import auth from './auth/reducers';
import user from './user/reducers';
import attributes from './attributes/reducers';
import charts from './charts/reducers'


const reducer = combineReducers({
  auth,
  user,
  attributes,
  charts
});

const store = configureStore({
  reducer,
});

export default store;
