import { Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { ContactDataRes } from 'types/contact';
import styles from '../styles.module.scss';
import CustomButton from 'components/custom-button';
import ContactService from 'service/contact-service';
import { JP_DATE_FORMAT } from 'constants/common';

interface IProps {
  detail?: ContactDataRes;
  handleClose: () => void;
  handleResetDataList: () => void;
}

const MessageDetail = ({ detail, handleClose, handleResetDataList }: IProps) => {
  const handleConfirmMessage = async () => {
    detail?.id && (await ContactService.comfirmMessage(detail?.id));
    handleResetDataList();
    handleClose();
  };

  return (
    <Modal
      forceRender
      title={'メッセージ詳細'}
      open={true}
      closable={false}
      footer={false}
      width={500}
      className="modal-custom"
    >
      <div className={styles.messageDetail}>
        <p className={styles.title}>ユーザー名:</p>
        <p>{detail?.userName}</p>
        <p className={styles.title}>内容:</p>
        <p>{detail?.content}</p>
        <p className={styles.title}>メールアドレス :</p>
        <p>{detail?.email}</p>
        <p className={styles.title}>作成日付:</p>
        <p>{moment(detail?.createdDate).format(JP_DATE_FORMAT)}</p>
      </div>
      <div className={styles.contactButtonBox}>
        {detail?.isRead !== 1 && (
          <CustomButton
            bgColor="primary-green"
            text="解決済み"
            borderRadius={6}
            fontWeight="bold"
            width={92}
            height={46}
            style={{ marginRight: 20 }}
            onClick={handleConfirmMessage}
          />
        )}

        <CustomButton
          bgColor="primary-grey"
          text="キャンセル"
          borderRadius={6}
          fontWeight="bold"
          width={140}
          height={46}
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};
export default MessageDetail;
