import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getListYear } from 'redux/charts/actions';
import { AppDispatch } from 'types/redux';
import ChartContainer from './component/chart-container';
import HeaderStatistic from './component/header-statistic';
import styles from './index.module.scss';

const HomePage = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getListYear());
  }, []);

  return (
    <div className={`${styles.home} custom-form`}>
      <div className={styles.header}>
        <HeaderStatistic />
      </div>

      <div className={styles.chartContainer}>
        <ChartContainer />
      </div>
    </div>
  );
};

export default HomePage;
