import { Session } from 'types/common';
import { StorageKey, StorageUtils } from './local-storage';

// set session to local
export const setSession = (session: Session | null) => {
  const session_copy = { ...session };
  if (session) {
    session_copy.accessTokenExpire = Date.now() + Number(session?.expireTime) * 1000;
    session_copy.accessTokenRefreshExpire = Date.now() + Number(session?.expireTimeRefreshToken) * 1000
  }
  StorageUtils.saveObject(StorageKey.SESSION, session_copy);
};

export const setRefreshToken = (session: Session | null) => {
  let session_copy = { ...session };
  if (session) {
    const accessTokenExpire = Date.now() + Number(session?.expireTime) * 1000;
    const accessToken = session?.accessToken;
    session_copy = {...session_copy, accessToken, accessTokenExpire}
  }
  StorageUtils.saveObject(StorageKey.SESSION, session_copy);
};

// get
export const getSession = () => {
  const session = StorageUtils.getObject(StorageKey.SESSION);
  if (session) {
    return session;
  } else {
    localStorage.removeItem(StorageKey.SESSION);
  }

  setSession(null);
  localStorage.removeItem(StorageKey.SESSION);
  return null;
};

export const deleteSession = () => {
  StorageUtils.saveObject(StorageKey.SESSION, {});
};

//check if token is not expired or existed
export const getTokenStatus = () => {
  const session: Session = getSession();
  const date = new Date();

  return (
    session?.accessTokenExpire &&
    session?.accessToken &&
    date.getTime() < session?.accessTokenExpire
  );
};

export const getTokenEndStatus = () => {
  const session: Session = getSession();
  const date = new Date();

  return (
    session?.accessTokenRefreshExpire &&
    session?.accessToken &&
    date.getTime() < session?.accessTokenRefreshExpire
  );
};
