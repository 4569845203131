export const ellipsisMiddle = (account: string, beginning: number, final: number) => {
  return account ? account.slice(0, beginning) + '...' + account.slice(-final) : '';
};

export function shortenName(name = '', start = 10) {
  return name?.length > start ? `${name?.substring(0, start)}...` : name;
}

export function shortenAddress(address = '', start = 10, chars = 4) {
  return `${address.substring(0, start)}...${address.substring(address.length - chars)}`
}