import { Image, Modal, notification, Space, Tabs, TabsProps } from 'antd';
import BannerDefault from 'assets/images/default-img.png';
import CustomButton from 'components/custom-button';
import { useEffect, useMemo, useState } from 'react';
import NotificationService from 'service/notifications';
import { NotificationDataRes } from 'types/notifications';
import { StatusNoti } from '..';

import styles from '../styles.module.scss';

interface Props {
  idNotiSelected: number | string | null;
  isModalViewNotiOpen: boolean;
  handleCancelViewNotieModal: () => void;
  onChangeStatusUpdateData: () => void;
  handelOpenCreateEditModal: () => void;
  handelSetValueNotiViewModal: (item: any) => void;
}

const ModalViewNoti = ({
  idNotiSelected,
  isModalViewNotiOpen,
  handleCancelViewNotieModal,
  onChangeStatusUpdateData,
  handelOpenCreateEditModal,
  handelSetValueNotiViewModal,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [valueNotiSelected, setValueNotiSelected] = useState<NotificationDataRes | null>(null);

  useEffect(() => {
    const getDetailNoti = async () => {
      const res = await NotificationService.getDetailNotifications(idNotiSelected);
      setValueNotiSelected(res);
      handelSetValueNotiViewModal(res);
    };
    if (idNotiSelected) {
      getDetailNoti();
    }
  }, [idNotiSelected]);

  const handleOkPublishNotiModal = async () => {
    setLoading(true);
    try {
      await NotificationService.publishNotification(idNotiSelected);
    } catch (error) {
      notification.error({ message: 'Error', description: 'サーバーエラーが発生しました。しばらく経ってから再度お試しください。', duration: 4 });
      setLoading(false);
    } finally {
      setLoading(false);
      handleCancelViewNotieModal();
      onChangeStatusUpdateData();
    }
  };

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'Desktop',
        label: `Desktop`,
        children: (
          <div className={`${styles.boxViewNotiDesktop} boxViewNotification`}>
            <h1 className={styles.titleNoti}>{valueNotiSelected?.title}</h1>
            <div className={styles.boxBanner}>
              {valueNotiSelected?.bannerObjectKey && (
                <Image
                  className={styles.imageBanner}
                  src={valueNotiSelected?.bannerObjectKey}
                  alt="file-upload-image"
                />
              )}
            </div>
            <p className={styles.boxDescription}>{valueNotiSelected?.description}</p>
          </div>
        ),
      },
      {
        key: 'Mobile',
        label: `Mobile`,
        children: (
          <div className={`${styles.boxViewNotiMobile} boxViewNotification`}>
            <h1 className={styles.titleNoti}>{valueNotiSelected?.title}</h1>
            <div className={styles.boxBanner}>
              <Image
                className={styles.imageBanner}
                src={valueNotiSelected?.bannerObjectKey || BannerDefault}
                alt="file-upload-image"
              />
            </div>
            <p className={styles.boxDescription}>{valueNotiSelected?.description}</p>
          </div>
        ),
      },
    ];
  }, [
    valueNotiSelected?.bannerObjectKey,
    valueNotiSelected?.description,
    valueNotiSelected?.title,
  ]);

  return (
    <Modal
      open={isModalViewNotiOpen}
      title={'レビュー/公開通知'}
      onCancel={handleCancelViewNotieModal}
      width={1000}
      getContainer={false}
      footer={false}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey="1" items={items} />
      <div className="d-flex justify-center">
        <Space size="large">
          {valueNotiSelected?.status !== StatusNoti.PUBLISHED && (
            <>
              <CustomButton
                bgColor="secondary-green"
                loading={loading}
                text="編集"
                onClick={handelOpenCreateEditModal}
              />
              <CustomButton
                bgColor="secondary-green"
                loading={loading}
                text="公開"
                onClick={handleOkPublishNotiModal}
              />
            </>
          )}
          <CustomButton
            text="キャンセル"
            bgColor="primary-grey"
            htmlType="button"
            onClick={handleCancelViewNotieModal}
            loading={loading}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default ModalViewNoti;
