import { getAuthLoading, getAuthSuccess, getAuthFailure } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { AppDispatch } from '../../types/redux';
import { setSession } from '../../utils/session';
import { paths } from 'routes/routes';
import { getUserDetailAction } from 'redux/user/action';
import * as Sentry from '@sentry/react'

export type Account = {
  email?: string;
  password?: string;
};

export const login = (account: Account, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAuthLoading());
    const res: any = await AxiosUtils.post(API.login, account);
    const token = res?.data;
    dispatch(getAuthSuccess(token));
    dispatch(getUserDetailAction());
    setSession(token);    
    Sentry.setUser({ username: token, email: account?.email });
    localStorage.setItem('email', account?.email?? '');
    navigate(paths.home);
  } catch (error: any) {
    if (error.code === 400) {
      console.error(`Error login : トークンの有効期限が切れました`, JSON.stringify(error));
      dispatch(getAuthFailure({ ...error, message: "メールアドレスまたはパスワードが正しくありません。" }));
    } else {
      console.error(`Error login :`, JSON.stringify(error));
      dispatch(getAuthFailure(error));
    }

  }
};
