import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { API } from 'redux/api-route';
import { Create1stParams, CreatePresignParams, List1stParams } from 'types/1st-nft';
import {
  SecondNft,
  SecondNFTFilterRequest,
  SecondNFTListResponse,
  SecondNFTMasterFilterRequest,
  SecondNftMasterRequestData,
} from 'types/2st-nft';
import AxiosUtils from 'utils/axios-utils';

const nftService = {
  createNft: async (params: Create1stParams) => {
    try {
      const response: any = await AxiosUtils.post(API.create1stNft, params);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  create2dNft: async (params: Create1stParams) => {
    try {
      const response: any = await AxiosUtils.post(API.create1st2dNft, params);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  updateNft: async (id: string | number, params: Create1stParams) => {
    try {
      const response: any = await AxiosUtils.patch(`${API.create1stNft}/${id}`, params);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  update2dNft: async (id: string | number, params: Create1stParams) => {
    try {
      const response: any = await AxiosUtils.patch(`${API.create1st2dNft}/${id}`, params);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  getList1stNft: async (params: List1stParams) => {
    try {
      const response: any = await AxiosUtils.get(API.getList1stNft, params);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getList1st2dNft: async (params: List1stParams) => {
    try {
      const response: any = await AxiosUtils.get(API.getList1st2dNft, params);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getDetail1stNft: async (id: string | number) => {
    try {
      const response: any = await AxiosUtils.get(`${API.getDetail1stNft}/${id}`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getDetail1st2dNft: async (id: string | number) => {
    try {
      const response: any = await AxiosUtils.get(`${API.getList1st2dNft}/${id}`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getFormatFile: async (url: string) => {
    try {
      const response: any = await AxiosUtils.getFormat(url);
      const contentType = response.headers['content-type'];
      const type = contentType.split('/')[0];
      return [type, null];
    } catch (error) {
      return [null, error];
    }
  },
  createPresign: async (params: CreatePresignParams) => {
    return await AxiosUtils.post(API.createPresign, params);
  },
  createPresign2d: async (params: CreatePresignParams) => {
    return await AxiosUtils.post(API.createPresign2d, params);
  },
  uploadFileS3: async (url: string, file: any, headers: Headers) => {
    return await AxiosUtils.postS3(url, file, headers);
  },
  mintNft: async (id: number | string, quantity: number) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mintNft}${id}/mint`, { quantity });
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Mint Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  mint2dNft: async (id: number | string, quantity: number) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mint2dNft}${id}/mint`, { quantity });
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Mint Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  checkMintNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mintNft}${id}/mint/check`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Mint Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  checkMint2dNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mint2dNft}${id}/mint/check`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Mint Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  burnNft: async (id: number | string, quantity: number) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mintNft}${id}/burn`, { quantity });
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Burn Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  burn2dNft: async (id: number | string, quantity: number) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mint2dNft}${id}/burn`, { quantity });
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Burn Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  checkBurnNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mintNft}${id}/burn/check`);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  checkBurn2dNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mint2dNft}${id}/burn/check`);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  sellNft: async (id: number | string, params: { price: number }) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mintNft}${id}/sell`, params);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  sell2dNft: async (id: number | string, params: { price: number }) => {
    try {
      const response: any = await AxiosUtils.post(`${API.mint2dNft}${id}/sell`, params);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  cancelSellNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils._delete(`${API.mintNft}${id}/sell/cancel`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Cancel Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  cancelSell2dNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils._delete(`${API.mint2dNft}${id}/sell/cancel`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Cancel Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  checkSellNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mintNft}${id}/sell/check`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  checkSell2dNft: async (id: number | string) => {
    try {
      const response: any = await AxiosUtils.get(`${API.mint2dNft}${id}/sell/check`);
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Sell Nft error',
        description: error?.message,
        duration: 4,
      });
    }
  },

  getMasterSecondNfts: async (
    params: SecondNFTMasterFilterRequest
  ): Promise<SecondNFTListResponse | undefined> => {
    try {
      const response: AxiosResponse<SecondNFTListResponse> = await AxiosUtils.get(
        API.masterCombineNft,
        params
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  masterCombineNft: async (body: SecondNftMasterRequestData): Promise<any> => {
    try {
      const response: any = await AxiosUtils.post(API.masterCombineNft, body);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  updateCombineNft: async (id: number, body: SecondNftMasterRequestData): Promise<any> => {
    try {
      const response: any = await AxiosUtils.put(
        API.updateMasterCombineNft.replace('{{id}}', id.toString()),
        body
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  deleteMasterNft: async (id: number): Promise<void> => {
    await AxiosUtils._delete(API.deleteMasterCombineNft.replace('{{id}}', id.toString()));
  },
  masterCreatePresign: async (params: CreatePresignParams): Promise<any> => {
    try {
      const response: any = await AxiosUtils.post(API.masterCreatePresign, params);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  getMasterNft: async (id) => {
    try {
      const response: AxiosResponse<SecondNft> = await AxiosUtils.get(
        `${API.masterCombineNft}/${id}`
      );
      return response.data;
    } catch (error) {
      notification.error({ message: 'Error', description: error?.message, duration: 4 });
    }
  },
  getSecondNfts: async (
    params: SecondNFTFilterRequest
  ): Promise<SecondNFTListResponse | undefined> => {
    try {
      const response: AxiosResponse<SecondNFTListResponse> = await AxiosUtils.get(
        API.getSecondNftList,
        params
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getSecond2dNfts: async (
    params: SecondNFTFilterRequest
  ): Promise<SecondNFTListResponse | undefined> => {
    try {
      const response: AxiosResponse<SecondNFTListResponse> = await AxiosUtils.get(
        API.getSecondNft2dList,
        params
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getSecondNft: async (id: number): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await AxiosUtils.get(
        API.getSecondNft.replace('{{id}}', id.toString())
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  getSecond2dNft: async (id: number): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await AxiosUtils.get(
        `${API.getSecondNft2dList}/${id.toString()}`
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message,
        duration: 4,
      });
    }
  },
  approveSecondNftVerify: async (id: number) => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.approveSecondNftVerify.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  approveSecond2dNftVerify: async (id: number) => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.approveSecond2dNftVerify.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  approveSecondNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.approveSecondNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  approveSecond2dNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.approveSecond2dNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  rejectSecondNft: async (id: number, body: any): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.rejectSecondNft.replace('{{id}}', id.toString()),
      body
    );
    return response.data;
  },
  rejectSecond2dNft: async (id: number, body: any): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.rejectSecond2dNft.replace('{{id}}', id.toString()),
      body
    );
    return response.data;
  },
  pickUpNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.pickupNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  pickUp2dNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.pickup2dNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  removePickUpNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.removePickupNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  removePickUp2dNft: async (id: number): Promise<any> => {
    const response: AxiosResponse<any> = await AxiosUtils.post(
      API.removePickup2dNft.replace('{{id}}', id.toString())
    );
    return response.data;
  },
  deactive1stNFT: async (id: number): Promise<any> => {
    try {
      const response: any = await AxiosUtils.patch(API.deactive1stNft.replace('{{id}}', id.toString()));
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  active1stNFT: async (id: number): Promise<any> => {
    try {
      const response: any = await AxiosUtils.patch(API.active1stNft.replace('{{id}}', id.toString()));
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  }
};

export default nftService;
