import moment from "moment";
import { CreatorDetail, Metadata, NftDetail } from "./common"

export interface ITransactionDetail {
 id: number;
 amount: number;
 orderId: number;
 transactionStatus: string;
 updatedAt: string;
 createdAt: string;
 creator: CreatorDetail;
 nfts: NftDetail[];
}

export interface ITransactionData {
 data: ITransactionDetail[];
 metadata: Metadata;
}

export enum TRANSACTION_DETAIL_STATUS {
  Success = 'success',
  Processing = 'processing',
  Fail = 'fail',
}

export interface IItemRefund {
  nftId: number;
  nftName: string;
  nftImage: string;
  price: number;
  quantity: number;
  nftStatus: TRANSACTION_DETAIL_STATUS;
}

export interface ITransactionDetailData {
 id: number;
 amount: number;
 chargeMethod:string;
 createdAt: string;
 orderId: number;
 status: string;
 updateAt: string;
 nfts: NftDetail[];
  creator: CreatorDetail;
  items: IItemRefund[];
  needManualRefund: boolean;
  message: string
}

export interface TxDate {
 endDate: Date | string;
 startDate: Date | string;
}

export interface ITransactionFilterData{
 name?: string;
 status?: string;
  txDate?: moment.Moment[] | null;
  needRefund?: boolean
}

export interface IDataRequestTransaction {
 status?: string;
 username?: string;
 page?: number;
 fromDate?: Date | string;
 toDate?:Date | string;
 limit?: number;
}