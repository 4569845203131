import { Empty, Select } from 'antd';
import Loading from 'components/loading';

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/redux';

const { Option } = Select;

interface IProps {
  name: string;
  limit: number;
  value?: string;
  onChange?: (value: string, option?: any) => void;
  multiple?: boolean;
  placeholder?: string;
  disabled?: boolean;
  allOptions?: boolean;
}

const SelectAttribute = ({
  name,
  limit,
  onChange,
  multiple,
  placeholder,
  value,
  allOptions,
  ...rest
}: IProps) => {
  const attributes: any = useSelector((state: RootState) => state?.attributes);
  const loading = attributes?.isLoading?.[name];

  const renderOption = (dataOption: any) => {
    return dataOption.map((item: any, index: number) => (
      <Option value={item?.id} key={index}>
        {item?.name}
      </Option>
    ));
  };

  return (
    <Select
      notFoundContent={
        loading ? (
          <Loading />
        ) : (
          <Empty description="データがありません。" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onChange={onChange}
      value={value}
      mode={multiple ? 'multiple' : undefined}
      className="select-multiple"
      placeholder={placeholder}
      showArrow={true}
      allowClear
      {...rest}
    >
      {allOptions && (
        <Option value="" key="all">
          All
        </Option>
      )}
      {attributes?.data?.[name]?.length > 0 && renderOption(attributes?.data?.[name])}
    </Select>
  );
};

export default SelectAttribute;
