import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Col,
  ConfigProvider,
  Empty,
  Form,
  Image,
  Input,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import CustomButton from 'components/custom-button';
import { INITIAL_FILTER } from 'constants/nft-custom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'types/redux';

import styles from './style.module.scss';
import PreviewImageDefault from 'assets/images/preview-image-default.jpeg';

import SelectAttribute from 'components/select-attributes';
import PageTitle from '../../components/page-title';
import Mint1stModal from './components/mint-1st-modal';
import Update1stModal from './components/update-1st-modal';

import { ADMIN_ROLE, LIMIT, MODAL_TYPE, NFT_ATTRIBUTES, NFT_STATUS } from 'constants/common';

import { getAttributes } from 'redux/attributes/actions';
import nftService from 'service/nft-service';

import { shortenName } from 'utils/short-name';
import { useSelector } from 'react-redux';
import { getUserDetail } from 'redux/user/selector';
import { getOpenseaLinkOfNft } from 'utils/contract';

const { Option } = Select;

const Create1stNft = () => {
  const [paging, setPaging] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: LIMIT,
  });
  const [totalItem, setTotalItem] = useState<number>(0);
  const [nftDataTable, setNftDataTable] = useState<any>([]);

  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [updatePopup, setUpdatePopup] = useState<boolean>(false);

  const [mintPopup, setMintPopup] = useState<boolean>(false);
  const [burnPopup, setBurnPopup] = useState<boolean>(false);
  const [sellPopup, setSellPopup] = useState<boolean>(false);
  const [cancelSellPopup, setCancelSellPopup] = useState<boolean>(false);

  const [mintId, setMintId] = useState<any>();
  const [updateId, setUpdateId] = useState<any>();
  const [loadingList, setLoadingList] = useState<boolean>(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUserDetail);
  const isMegaHouse = user?.role === ADMIN_ROLE.MEGAHOUSE_ADMIN;
  const isSuper = user?.role === ADMIN_ROLE.MASTER_ADMIN;


  const viewObj = {
    text: '表示',
    action: (record: any) => {
      setUpdateId(record?.id);
      setViewPopup(true);
    },
  };

  const updateObj = {
    text: '変更',
    action: (record: any) => {
      setUpdateId(record?.id);
      setUpdatePopup(true);
    },
  };

  const sellObj = {
    text: '販売',
    action: (record: any) => {
      setMintId(record?.id);
      setSellPopup(true);
      setUpdatePopup(false);
      setCreatePopup(false);
    },
  };

  const burnObj = {
    text: 'バーン',
    action: (record: any) => {
      setMintId(record?.id);
      setBurnPopup(true);
      setUpdatePopup(false);
      setCreatePopup(false);
    },
  };

  const cancelSellObj = {
    text: '販売取消',
    action: (record: any) => {
      setMintId(record?.id);
      setCancelSellPopup(true);
    },
  };

  const mintObj = {
    text: 'ミント',
    action: (record: any) => handleMintPopUp(record?.id, record?.status, true),
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      align: 'center' as const,
    },
    {
      title: '画像',
      dataIndex: 'previewImg',
      key: 'previewImg',
      align: 'center' as const,
      width: 100,
      render: (image: any) => (
        <>
          <Image src={image || PreviewImageDefault} alt="image" />
        </>
      ),
    },
    {
      title: 'タイトル',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name: string) => (
        <>
          <Tooltip title={name}>{shortenName(name || '', 10) || ''}</Tooltip>
        </>
      ),
    },
    {
      title: '説明文',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      align: 'center' as const,
      render: (description: string) => (
        <>
          <Tooltip title={description}>{shortenName(description || '', 20) || ''}</Tooltip>
        </>
      ),
    },
    {
      title: 'アドレス',

      key: 'address',
      width: 200,
      align: 'center' as const,
      render: (record: any) => {
        const openSeaUrl = getOpenseaLinkOfNft(record?.nftId);
        if ([NFT_STATUS.MINTED, NFT_STATUS.SELLING].includes(record?.status)) {
          return (
            <>
              <Tooltip title={openSeaUrl}>
                <a href={openSeaUrl} target="_blank" rel="noreferrer">
                  {shortenName(openSeaUrl || '', 20) || ''}
                </a>
              </Tooltip>
            </>
          );
        }
        return <></>;
      },
    },
    {
      title: '状態',
      dataIndex: 'displayStatus',
      key: 'displayStatus',
      width: 100,
      align: 'center' as const,
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      align: 'center' as const,
    },
    {
      title: 'アクション',
      align: 'center' as const,
      key: 'action',
      width: 120,
      render: (record: {
        canMint: boolean;
        canSell: boolean;
        canBurn: boolean;
        status: string;
      }) => (
        <Select
          value="アクション"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          {[
            viewObj,
            ...(record?.canMint ? [mintObj] : []),
            ...(record?.canBurn ? [burnObj] : []),
            ...(record?.canSell ? [sellObj] : []),
            ...(record?.status === NFT_STATUS.SELLING ? [cancelSellObj] : []),
            ...([updateObj]),
          ]?.map((item: any, index: number) => {
            return (
              <Option value={`action${index}`} key={index}>
                <div
                  onClick={() => {
                    item?.action && item?.action(record);
                  }}
                  style={{ textAlign: 'center' }}
                >
                  {item?.text}
                </div>
              </Option>
            );
          })}
        </Select>
      ),
    },
  ];

  const handleMintPopUp = (id: string | number, status: boolean, isMint?: boolean) => {
    setMintId(id);
    setUpdatePopup(false);
    setCreatePopup(false);
    setBurnPopup(false);
    status && isMint && setMintPopup(true);
  };

  const handelCloseMintPoup = () => {
    setMintPopup(false);
    setBurnPopup(false);
    setSellPopup(false);
    setCancelSellPopup(false);
  };

  const handelCloseUpdatePoup = () => {
    setUpdatePopup(false);
    setCreatePopup(false);
    setViewPopup(false);
    setUpdateId(null);
  };

  const handleGetList = async (params: any) => {
    const result: any = await nftService.getList1st2dNft(params);
    if (result) {
      setNftDataTable(result?.items);
      setTotalItem(result?.totalCount);
    }
    setLoadingList(false);
  };

  const onSearch = (value: any) => {
    setLoadingList(true);
    const params: any = {};
    for (const key in value) {
      if (Array.isArray(value[key])) {
        params[key] = value[key].toString();
      } else {
        params[key] = value[key];
      }
    }
    params.page = paging.page;
    params.limit = LIMIT;
    params.desc = true;
    params.sortField = 'updated_at';
    handleGetList(params);
  };

  const handleChangePage = (page: number) => {
    setPaging({ ...paging, page });
  };

  const clearFilter = () => {
    form.resetFields();
    setPaging({
      page: 1,
      limit: LIMIT,
    });
  };

  const modalType = () => {
    switch (true) {
      case mintPopup:
        return MODAL_TYPE.MINT;
      case burnPopup:
        return MODAL_TYPE.BURN;
      case sellPopup:
        return MODAL_TYPE.SELL;
      case cancelSellPopup:
        return MODAL_TYPE.CANCEL_SELL;
      default:
        return MODAL_TYPE.MINT;
    }
  };

  useEffect(() => {
    if (isMegaHouse || isSuper) {
      form
        .validateFields()
        .then((value) => {
          onSearch(value);
        })
        .catch((error) => console.log(error));
    }
  }, [paging, isMegaHouse, isSuper]);

  useEffect(() => {
    if (isMegaHouse || isSuper) {
      for (const key in NFT_ATTRIBUTES) {
        dispatch(getAttributes(NFT_ATTRIBUTES[key]));
      }
    }
  }, [isMegaHouse, isSuper]);

  return (
    <div>
      <PageTitle title="一次NFT管理（イラスト）" />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24} md={24}>
          <Form
            initialValues={INITIAL_FILTER}
            onFinish={() => {
              setPaging({ ...paging, page: 1 });
            }}
            form={form}
          >
            <Row gutter={30} className="custom-form">
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item label="タイトル" name="name">
                  <Input placeholder="NFT名で検索" style={{ height: 40 }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item label="状態" name="status">
                  <SelectAttribute
                    name={NFT_ATTRIBUTES.STATUS}
                    limit={LIMIT}
                    multiple={true}
                    placeholder="ステータスを選択"
                  />
                </Form.Item>
              </Col>

              <Col span={24} className={styles.buttonBox}>
                <CustomButton
                  onClick={() => setCreatePopup(true)}
                  text="新規登録"
                  bgColor="primary-green"
                  icon={<PlusOutlined />}
                />
                <div>
                  <CustomButton
                    htmlType="submit"
                    text="検索"
                    bgColor="secondary-green"
                    style={{ marginRight: 10 }}
                    icon={<SearchOutlined />}
                  />
                  <CustomButton
                    onClick={clearFilter}
                    text="クリア"
                    bgColor="primary-grey"
                    color="black"
                    icon={<CloseOutlined />}
                  />
                </div>
              </Col>
            </Row>
          </Form>

          <div className="mt-10 custom-form">
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description="対象データが見つかりません。"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={columns}
                dataSource={nftDataTable}
                pagination={false}
                className="ant-border-space"
                // scroll={{ x: 1200, y: 500 }}
                loading={loadingList}
              />
            </ConfigProvider>
          </div>
          {totalItem > LIMIT && (
            <Pagination
              className={styles['nft-pagination']}
              showSizeChanger={false}
              current={paging.page}
              onChange={handleChangePage}
              showLessItems
              total={totalItem}
            />
          )}
        </Col>
      </Row>

      {createPopup && (
        <Update1stModal
          clearFilter={clearFilter}
          handleMintPopUp={handleMintPopUp}
          handelCloseUpdatePoup={handelCloseUpdatePoup}
          title={'一次NFT作成（イラスト）'}
        />
      )}

      {updatePopup && (
        <Update1stModal
          clearFilter={clearFilter}
          id={updateId}
          handleMintPopUp={handleMintPopUp}
          edit={true}
          handelCloseUpdatePoup={handelCloseUpdatePoup}
          updateNft={true}
          title={'一次NFT更新（イラスト）'}
        />
      )}

      {viewPopup && (
        <Update1stModal
          clearFilter={clearFilter}
          id={updateId}
          handleMintPopUp={handleMintPopUp}
          view={true}
          edit={true}
          handelCloseUpdatePoup={handelCloseUpdatePoup}
          title={'一次NFT表示（イラスト）'}
        />
      )}

      {(burnPopup || mintPopup || sellPopup || cancelSellPopup) && (
        <Mint1stModal
          clearFilter={clearFilter}
          id={mintId}
          handelCloseMintPoup={handelCloseMintPoup}
          type={modalType()}
        />
      )}
    </div>
  );
};

export default Create1stNft;
