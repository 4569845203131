import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { NETWORK_URLS, WALLETCONNECT_BRIDGE_URL } from 'constants/common';
import { SC_NETWORK } from 'constants/envs';
import { SC_NETWORKS } from 'constants/sc.enums';
import { getContractAddress, getContractABI } from './contract';

export const walletConnectConnector = new WalletConnectConnector({
  supportedChainIds: SC_NETWORK === SC_NETWORKS.mainnet ? [137] : [80001],
  rpc: NETWORK_URLS,
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
});


export const getWagmiContractConfig = () => {
  return {
    address: getContractAddress(),
    abi: getContractABI(),
  }
}
